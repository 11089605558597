import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { interval, Subscription } from 'rxjs';
import { Constants, CodeVerificationErrors } from 'src/app/_enums/constant-types.enum';
import { VerificationPopupInput } from 'src/app/_models/register-response';
import { User, UserLoginResult, UserParams } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { LoginComponent } from '../login/login.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.css']
})
export class AccountVerificationComponent implements OnInit, OnDestroy {
  public userData: VerificationPopupInput;
  title = "Verification";
  config: any = '';
  SubmitVerificationForm: FormGroup;
  isActivated: boolean = false;
  isResendActivation: boolean = false;
  isAlreadyActivated: boolean = false;
  alreadyVerified: boolean = false;
  notVerified: boolean = false;
  isDeactivated: boolean = false;
  deactivatedDate?: Date;
  submitted = false;
  userParams: UserParams = {};
  userModel: User;
  inValidCode: boolean = false;
  codeExpired: boolean = false;
  codeAlreadyVerified: boolean = false;
  referrer: string | undefined = undefined;

  private subscription: Subscription | null = null;
  public datenow = new Date();
  milliSecondsInASecond = 1000;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  public timeDifference: any;
  public secondsToDday: any;
  public minutesToDday: any;
  isDisabled: boolean = false;

  constructor(private authService: AuthenticationService,
    private utilityService:UtilityService,
    private alertifyService:AlertifyService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    public options: ModalOptions) { }

  private getTimeDifference() {
    let countDownDate = new Date().setMilliseconds(-120000);
    this.timeDifference = this.datenow.getTime() - countDownDate;
    if (this.timeDifference <= 0) {
      this.isDisabled = false;
    }
    else {
      this.allocateTimeUnits(this.timeDifference);
    }
  }
  private allocateTimeUnits(timeDifference: any) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
  }

  ngOnInit(): void {
    this.userData = this.options.initialState as any as VerificationPopupInput;
    this.isDeactivated = this.userData.RegisteredDeactivated;
    this.deactivatedDate = this.userData.DeactivatedDate ?? undefined;
    this.alreadyVerified = this.userData.RegisteredAlreadyVerified;
    this.notVerified = this.userData.RegisteredNotVerified;
    this.referrer = this.userData.Referrer;
    this.userParams.EMAIL_ID = this.userData.EmailID ?? "";
    this.userParams.PK_USER_ID = this.userData.PK_User_ID;
    this.title = (this.userData.RegisteredNotVerified || this.userData.RegisteredDeactivated) ? "Error" : "Verification"
    this.setFormGroup();
    //this.getUserAccount();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  get form() {
    return this.SubmitVerificationForm.controls;
  }
  
  get enableVerify()
  {
    let isValid = true;
    if (this.SubmitVerificationForm.controls["Code"].value == "") {
      isValid = false;
    }
    return isValid;
  }

  get enableVerifyForm(): boolean
  {
    return !this.alreadyVerified && !this.isDeactivated && !this.isActivated && !this.notVerified;
  }

  get showTimer() {
    return this.isDisabled;
  }

  get enableLink() {
    return !this.isDisabled;
  }

  setFormGroup() {
    this.SubmitVerificationForm = new FormGroup({
      Code: new FormControl("", [Validators.required, Validators.pattern('[0-9]*')]),
    });
  }

  getUserAccount() {
    this.authService.getUserAccount(this.userParams).subscribe(next => {

      if (next) {
        if (next.IS_ACTIVATED) {
          this.isAlreadyActivated = true;
        }
        else {
          this.isAlreadyActivated = false;
          this.userModel = next;
          this.userParams.USER_ID = next.USER_ID;
        }
      }
      else {
        this.isActivated = false;
      }     
    },
      error => {
        console.error(error);
      });
  }

  verify()
  {
    this.cleanForm();
    this.submitted = true;
    let isValid = true;
    this.inValidCode = false;
    this.codeExpired = false;
    this.codeAlreadyVerified = false;
    let errrs: any = [];
    if (!this.SubmitVerificationForm.controls["Code"].valid) {
      errrs.push("Please enter Code");
      isValid = false;
    }
    if (!isValid) {
      let errMessage = '';
      for (const element of errrs) {
        errMessage += element + ' </br>';
      }
    }
    else
    {
      this.userParams.VERIFICATION_CODE = this.SubmitVerificationForm.controls["Code"].value;
      this.authService.accountActivation(this.userParams)
      .subscribe(next => {
        if (next.Success) {
          if(this.referrer != undefined && this.referrer == Constants.ForgotPassword)
          {
            this.openResetPassword();
          }
          else
          {
            this.isActivated = true;  
          }
        }
        else
        {
          if (next.Errors.length > 0) {
            next.Errors.forEach(error => {
              let errors:any=[];
              switch (error) {
                case CodeVerificationErrors.AlreadyVerified:
                  //this.codeAlreadyVerified = true;
                  errors.push("Your account has already been activated.");
                  this.alertifyService.errorBaner(errors);
                  this.close();
                  break;
                case CodeVerificationErrors.InValidCode:
                  this.inValidCode = true;
                  break;
                case CodeVerificationErrors.CodeExpired:
                  errors.push("Your verification code has expired. Please click on Resend Code.");
                  this.alertifyService.errorBaner(errors);  
                  this.SubmitVerificationForm.controls["Code"].setValue("");
                  //this.codeExpired = true;
                  break;
                case CodeVerificationErrors.EmailExists:
                  //this.codeAlreadyVerified = true;
                  this.notVerified = true;
                  errors.push("Email Address you have entered is already linked to another account. Please use different Email Address.");
                  this.alertifyService.errorBaner(errors);
                  this.close();
                  break;
              }
            });
          }
        }
      },
      error => {
        console.error(error);
      });
    }
  }

  resendCode() {
    this.isDisabled = true;
    this.datenow = new Date();
    this.subscription = interval(1000).subscribe(((x: any) => { this.getTimeDifference(); }));
    if(this.referrer != undefined && this.referrer == Constants.ForgotPassword)
    {
      this.authService.resendResetPasswordLink(this.userParams).subscribe(next => {
        this.isResendActivation = next;
      },
      error => {
        console.error(error);
      });
    }
    else
    {
      this.authService.resendActivation(this.userParams).subscribe(next => {
        this.isResendActivation = next;
      },
      error => {
        console.error(error);
      });
    }
  }

  activateVerifyPopup()
  {
    // if(this.referrer != undefined && this.referrer == Constants.ForgotPassword)
    // {
    //   this.authService.resendResetPasswordLink(this.userParams).subscribe(next => {
    //     this.title = "Verification";
    //     this.isDeactivated = false;
    //     this.isActivated = false;
    //     this.notVerified = false;
    //     this.alreadyVerified = false;
    //   },
    //   error => {
    //     console.error(error);
    //   });
    // }
    // else
    // {
      this.authService.resendActivation(this.userParams).subscribe(next => {
        this.title = "Verification";
        this.isDeactivated = false;
        this.isActivated = false;
        this.notVerified = false;
        this.alreadyVerified = false;
      },
      error => {
        console.error(error);
      });
    //}
  }

  openLoginForm()
  {
    this.close();
    const initialState: ModalOptions = {
      backdrop : 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(LoginComponent,initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onLogin.subscribe((result : UserLoginResult) => {
      if(result != null)
      {
        this.authService.loginStatus.next(true);
        this.authService.UserName.next(result.UserFullName ?? "");
        this.authService.hcpDetails.next(this.utilityService.getAppUserInfoObject(result));
      }
    });
  }

  openForgotPassword()
  {
    this.close();
    const initialState: ModalOptions = {
      backdrop : 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
   this.modalRef = this.modalService.show(ForgotPasswordComponent,initialState);
   this.modalRef.content.closeBtnName = 'Close';
  }

  openResetPassword()
  {
    this.close();  
    const config: ModalOptions = {
      initialState : <any>this.userParams,
      backdrop : 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
   this.modalRef = this.modalService.show(ResetPasswordComponent,config);
   this.modalRef.content.closeBtnName = 'Close';
  }

  public cleanForm() {
    let count = 0;
    if (this.SubmitVerificationForm != null && this.SubmitVerificationForm != undefined) {
      Object.keys(this.SubmitVerificationForm.controls).forEach(key => {

        let value: any = this.SubmitVerificationForm.controls[key].value;
        if (this.SubmitVerificationForm.controls[key].value != null && typeof (this.SubmitVerificationForm.controls[key].value) === "string"
          && this.SubmitVerificationForm.controls[key].value !== "") {
          value = this.SubmitVerificationForm.controls[key].value.trim();
        }
        else if (this.SubmitVerificationForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        value = this.utilityService.replaceEmojis(value);

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.SubmitVerificationForm.controls[key].setValue(value);
        }

      });
    }
  }

  close()
  {
    this.modalRef.hide();
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SubmitVerificationForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }

    return true;
  }

}
