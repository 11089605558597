import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PasswordPolicyComponent } from 'src/app/components/password-policy/password-policy.component';
import { RegistrationErrors } from 'src/app/_enums/constant-types.enum';
import { PasswordValidator } from 'src/app/_helpers/custom-password-validators';
import { ComparePassword } from 'src/app/_helpers/customvalidators';
import { PASSWORD_POLICY } from 'src/app/_models/password-policy';
import { ChangePasswordRequest, UserParams } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  emailID: string | undefined = undefined;
  userParams: UserParams = {};
  ChangePasswordForm: FormGroup;
  passwordPolicy: PASSWORD_POLICY = {};
  changePasswordRequest: ChangePasswordRequest = {};
  submitted = false;
  pattern = this.utilityService.getPasswordPolicyPattern();
  
  constructor(private authService: AuthenticationService,
    private alertifyService:AlertifyService,
    private utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private router: Router,
    public modalRef: BsModalRef,
    public passwordPolicyModal: BsModalRef,
    private modalService: BsModalService,
    public options: ModalOptions) { }

  ngOnInit(): void {
    this.userParams = this.options.initialState as any as UserParams;    
    this.emailID = this.userParams.EMAIL_ID;
    this.setFormGroup();
    this.getPasswordPolicy();
  }

  get form() {
    return this.ChangePasswordForm.controls;
  }

  get enableChangePassword()
  {
    let isValid = true;
    if (this.ChangePasswordForm.controls["REGISTERED_EMAIL_ID"].value == "") {
      isValid = false;
    }
    if (this.ChangePasswordForm.controls["PASSWORD"].value == "") {
      isValid = false;
    }
    if (this.ChangePasswordForm.controls["NEW_PASSWORD"].value == "") {
      isValid = false;
    }
    if (this.ChangePasswordForm.controls["CONFIRM_PASSWORD"].value == "") {
      isValid = false;
    }
    return isValid;
  }

  validForm()
  {
    let isValid = true;
    if (this.ChangePasswordForm.controls["REGISTERED_EMAIL_ID"] == null ||
    this.ChangePasswordForm.controls["REGISTERED_EMAIL_ID"].value == "") {
      isValid = false;
    }
    else if (!this.utilityService.validateEmail(this.ChangePasswordForm.controls["REGISTERED_EMAIL_ID"].value)) {
      isValid = false;
    }
    if (!this.ChangePasswordForm.controls["PASSWORD"].valid) {
      isValid = false;
    }
    if (!this.ChangePasswordForm.controls["NEW_PASSWORD"].valid) {
      isValid = false;
    }
    if (this.ChangePasswordForm.controls["CONFIRM_PASSWORD"].valid) {
      if (this.ChangePasswordForm.controls["NEW_PASSWORD"].valid && (this.ChangePasswordForm.controls["NEW_PASSWORD"].value != this.ChangePasswordForm.controls["CONFIRM_PASSWORD"].value)) {
        isValid = false;
      }
    }
    else {
      isValid = false;
    }
    return isValid;
  }

  getPasswordPolicy() {
    this.authService.getPasswordPolicy().subscribe(next => {
      this.passwordPolicy = next;
    },
    error => {
      console.error(error);
    });
  }

  setFormGroup() {
    this.ChangePasswordForm = this.formBuilder.group({
      REGISTERED_EMAIL_ID: new FormControl({value:this.emailID, disabled: true}, [Validators.required, Validators.email]),
      PASSWORD: new FormControl("", [Validators.required]),
      NEW_PASSWORD: new FormControl("", [Validators.required,
        Validators.minLength(8),
        PasswordValidator.number,
        PasswordValidator.upper,
        PasswordValidator.lower,
        PasswordValidator.special]),
      CONFIRM_PASSWORD: new FormControl("", [Validators.required]),
    },
    {
      // Used custom form validator name
      validator: ComparePassword("NEW_PASSWORD", "CONFIRM_PASSWORD")
    });
  }

  changePassword()
  {
    this.cleanForm();
    this.submitted = true;
    if(this.validForm())
    {
      this.changePasswordRequest.EmailId = this.ChangePasswordForm.controls["REGISTERED_EMAIL_ID"].value;
      this.changePasswordRequest.Password = this.ChangePasswordForm.controls["PASSWORD"].value;
      this.changePasswordRequest.NewPassword = this.ChangePasswordForm.controls["NEW_PASSWORD"].value;
      this.changePasswordRequest.ConfirmPassword = this.ChangePasswordForm.controls["CONFIRM_PASSWORD"].value;
      this.changePasswordRequest.PkUserId = this.userParams.PK_USER_ID;
      this.authService.changePassword(this.changePasswordRequest)
        .subscribe(next => {
          if (next.Errors?.length != undefined && next.Errors?.length  > 0) {
            var errors:any=[];
            next.Errors.forEach(error => {
              switch (error) {
                case RegistrationErrors.NoAccountFound:
                  errors.push("No account exists with this Email Address. Please enter a valid registered Email Address or click Register to continue login.");
                  break;
                case RegistrationErrors.InvalidCurrentPassword:
                  errors.push("Current password is invalid.");
                  break;
                case RegistrationErrors.ExistsInLast3Passwords:
                  errors.push("New password cannot be from last 3 Passwords. Please use a different password.");
                  break;
              }              
            });
            this.alertifyService.errorBaner(errors);
          }
          else
          {
            this.close();
            this.authService.logout();
            this.authService.loginStatus.next(false);
            this.authService.UserName.next("");
            this.alertifyService.changePasswordSuccessBanner();
            this.router.navigate(['/']);            
          }
        },
        error => {
          console.error(error);
        });
      }
  }

  openPasswordPolicy()
  {
    const config: ModalOptions = {
      initialState : <any>this.passwordPolicy,
      backdrop : 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.passwordPolicyModal = this.modalService.show(PasswordPolicyComponent, config);
    this.passwordPolicyModal.content.closeBtnName = 'Close';
  }

  public cleanForm() {
    let count = 0;
    if (this.ChangePasswordForm != null && this.ChangePasswordForm != undefined) {
      Object.keys(this.ChangePasswordForm.controls).forEach(key => {

        let value: any = this.ChangePasswordForm.controls[key].value;
        if (this.ChangePasswordForm.controls[key].value != null && typeof (this.ChangePasswordForm.controls[key].value) === "string"
          && this.ChangePasswordForm.controls[key].value !== "") {
          value = this.ChangePasswordForm.controls[key].value.trim();
        }
        else if (this.ChangePasswordForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.ChangePasswordForm.controls[key].setValue(value);
        }

      });
    }
  }

  close()
  {
    this.modalRef.hide();
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.ChangePasswordForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }

    return true;
  }

}
