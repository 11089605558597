import { Component, OnInit } from '@angular/core';
import { Message } from '../../_models/message';
import { AlertifyService } from '../../_services/alertify.service';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.css']
})
export class ToastMessageComponent implements OnInit {

  messages: Array<Message> = [];
  constructor(public toast: AlertifyService) { }

  ngOnInit() {
    this.messages = this.toast.getToastMessages();
  }

  dismissMessage(itemKey: string) {
    this.toast.dismissToastMessage(itemKey);
  }

}
