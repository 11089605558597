<form [formGroup]="loginForm" (ngSubmit)="login();">
  <div class="login-body">
    <div class="log-row"><span></span></div>
    <div class="log-row">
      <div>User</div>
      <div>
        <input type="text" formControlName="username" autocomplete="off" class="txtusername" (blur)="handleInput($event.target,'username');"
               [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Username is required</div>
        </div>
        <span class="fieldIcon"><i class="fas fa-user"></i></span>
        <!--<label class="fieldlabel mandatory form-control-placeholder" for="username">User Name</label>-->
      </div>
    </div>
    <div class="log-row">
      <div>Password</div>
      <div>
        <input type="password" formControlName="password" autocomplete="off" class="txtpassword" (blur)="handleInput($event.target,'password');"
               [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div>
        <span class="fieldIcon"><i class="fas fa-key"></i></span>
        <!--<label class="fieldlabel mandatory form-control-placeholder" for="password">Password</label>-->
      </div>
    </div>
    <div class="log-row"><span><input class="cwonebtn" style="float:unset;" type="submit" value="  Login  "></span></div>
  </div>
</form>
