<div class="pageContent bodyBg py-3">
  <div class="row">
    <div class="col-md-8 col-lg-9">
      <div class="card py-2 mb-3">
        <div class="defaultTabs">
          <div class="container-fluid">

            <div class="row">
              <div class="col-md-12">
                <div class="title-lg text-primary"><i class="fas fa-user fs-22"></i>&nbsp;My Profile
                </div>
                <p class="fs-12 pt-2 pb-3">You can view/edit your profile details here.</p>
              </div>
            </div>
            <div class="viewProfile">
              <div class="row border-bottom pb-2 mb-3">
                <div class="col-lg-5 col-md-12">
                  <div class="title-md title-color-2">{{fullName}}</div>
                </div>
                <div class="col-lg-7 col-md-12">
                  <div class="text-right mbl-100" ngPreserveWhitespaces>
                    <button type="button" class="btn btn-primary" (click)="deactivateAccount()">Deactivate Account</button>
                    <button type="button" class="btn btn-primary" (click)="changePassword()">Change Password</button>
                    <a href="javascript:void(0);" class="btn btn-primary" (click)="editProfile()">Edit Profile</a>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row border-bottom pb-2">
                    <div class="col-md-12">
                      <div class="title-md">User Details</div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">User ID:</div>
                        <div class="viewdtls pl-1">{{hcpDetails.UserID}}</div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">Registered Email Address:</div>
                        <div class="viewdtls pl-1">{{hcpDetails.EmailID}}
                          <a href="javascript:void(0);" (click)="changeEmail()" class="text-danger"><i class="fas fa-pen pl-1"></i></a>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">First Name:</div>
                        <div class="viewdtls pl-1 text-break">{{hcpDetails.FIRST_NAME}}</div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">Last Name:</div>
                        <div class="viewdtls pl-1 text-break">{{hcpDetails.LAST_NAME}}</div>
                      </div>
                    </div>

                    <!-- <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">Date of Birth:</div>
                        <div class="viewdtls pl-1">{{hcpDetails.DATE_OF_BIRTH | date:'dd-MMM-YYYY'}}</div>
                      </div>
                    </div> -->
                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">Country:</div>
                        <div class="viewdtls pl-1">{{userCountry}}</div>
                      </div>
                    </div>

                    <!-- <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">Zip Code:</div>
                        <div class="viewdtls pl-1">{{hcpDetails.ZIP_CODE}}</div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">City:</div>
                        <div class="viewdtls pl-1">{{hcpDetails.CITY}}</div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">State/Province:</div>
                        <div class="viewdtls pl-1">{{hcpDetails.STATE_PROVINCE}} </div>
                      </div>
                    </div> -->

                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">Practice Address:</div>
                        <div class="viewdtls pl-1 text-break">{{hcpDetails.ADDRESS}}</div>
                      </div>
                    </div>

                  </div>
                  <div class="row border-bottom pb-2">
                    <div class="col-md-12">
                      <div class="title-md py-2">Contact Preference</div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-2">
                        <div class="viewhead">Preferred Contact Method:</div>
                        <div class="viewdtls pl-1">{{preferredContactMethod}}</div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-2">
                        <div class="viewhead">Email Address:</div>
                        <div class="viewdtls pl-1 text-break">{{hcpDetails.PREFERED_EMAIL}}</div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="row">
                        <div class="col-md-8 col-sm-8">
                          <div class="d-flex pt-3">
                            <div class="viewhead">Phone No:</div>
                            <div class="viewdtls pl-1 text-break">{{hcpDetails.PREFERED_PHONE}}</div>
                          </div>
                        </div>

                        <div class="col-md-4 col-sm-4">
                          <div class="d-flex pt-3">
                            <div class="viewhead">Ext:</div>
                            <div class="viewdtls pl-1 text-break">{{hcpDetails.PREFERED_PHONE_EXT}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="row">
                        <div class="col-md-8 col-sm-8">
                          <div class="d-flex pt-3">
                            <div class="viewhead">Other Phone No:</div>
                            <div class="viewdtls pl-1 text-break">{{hcpDetails.PHONE}}</div>
                          </div>
                        </div>

                        <div class="col-md-4 col-sm-4">
                          <div class="d-flex pt-3">
                            <div class="viewhead">Ext:</div>
                            <div class="viewdtls pl-1 text-break">{{hcpDetails.PHONE_EXT}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row pt-2 pb-2">
                    <div class="col-md-12">
                      <div class="title-md">Practice Details</div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">Profession:</div>
                        <div class="viewdtls pl-1">{{userProfession}}</div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">Specialty:</div>
                        <div class="viewdtls pl-1">{{userSpeciality}}</div>
                      </div>
                    </div>

                    <!-- <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">License Number:</div>
                        <div class="viewdtls pl-1">{{hcpDetails.LICENCE_NUMBER}}</div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-6">
                      <div class="d-flex pt-3">
                        <div class="viewhead">Primary Practice:</div>
                        <div class="viewdtls pl-1">{{hcpDetails.PRIMARY_PRACTICE}}</div>
                      </div>
                    </div> -->


                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-md-4 col-lg-3">
      <app-promotional-cards></app-promotional-cards>
    </div>
  </div>

</div>