import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../../shared/AppConstants';
import { APIResponse } from '../_models/apiResponse';
import { BookmarksAddEditDTO, BookmarksVM, SearchBookmarkDTO } from '../_models/bookmarks';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class BookmarksService {

  constructor(private http: HttpClient, private utility: UtilityService) { }

  getBookmarks(): Observable<any> {
    const criteria = new SearchBookmarkDTO();
    criteria.EnvironmentID = AppConstants.EnvironmentId;
    criteria.PortalID = this.utility.getPortalID();
    criteria.UserID = this.utility.getAppUserInfo().PK_UserId;    
    return this.http.post<BookmarksVM>(AppConstants.PortalApiBaseUrl + '/api/Bookmarks/GetUserBookmarks', criteria);
  }

  AddBookmark(criteria: BookmarksAddEditDTO): Observable<any> {
    criteria.PortalID = this.utility.getPortalID();
    criteria.PortalCode = AppConstants.PortalCode;
    criteria.EnvironmentID = AppConstants.EnvironmentId;
    criteria.UserID = this.utility.getAppUserInfo().PK_UserId;
    criteria.ProductMappingType = this.utility.getProductsMappingType();
    return this.http.post<APIResponse>(AppConstants.PortalApiBaseUrl + '/api/Bookmarks/AddBookmark', criteria);
  }

  UpdateBookmark(criteria: BookmarksAddEditDTO): Observable<any> {
    criteria.PortalID = this.utility.getPortalID();
    criteria.PortalCode = AppConstants.PortalCode;
    criteria.EnvironmentID = AppConstants.EnvironmentId;
    criteria.UserID = this.utility.getAppUserInfo().PK_UserId;
    criteria.ProductMappingType = this.utility.getProductsMappingType();
    return this.http.post<APIResponse>(AppConstants.PortalApiBaseUrl + '/api/Bookmarks/UpdateBookmark', criteria);
  }

  UpdateMyBookmarks(criteria: BookmarksAddEditDTO): Observable<any> {
    criteria.PortalID = this.utility.getPortalID();
    criteria.PortalCode = AppConstants.PortalCode;
    criteria.EnvironmentID = AppConstants.EnvironmentId;
    criteria.UserID = this.utility.getAppUserInfo().PK_UserId;
    criteria.ProductMappingType = this.utility.getProductsMappingType();
    return this.http.post<APIResponse>(AppConstants.PortalApiBaseUrl + '/api/Bookmarks/UpdateMyBookmarks', criteria);
  }

}
