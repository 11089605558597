import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CountryAutoComplete } from '../_models/countries';
import { UtilityService } from './utility.service';
@Injectable({
  providedIn: 'root'
})
export class CountryControlService {

  constructor(public utilityService: UtilityService) { }

  searchCountries(countries: CountryAutoComplete[], searchKey: string): Observable<CountryAutoComplete[]> {
    searchKey = this.utilityService.replaceEmojis(searchKey);
    if (!searchKey || searchKey.length == 0) {
      return of(countries);
    }
    else if (searchKey.length < 3) {
      let country: CountryAutoComplete[] = [];
      country.push(
        {
            PKCountryId: undefined,
            CountryDisplayName: 'Please enter at least 3 characters to perform search',
            IsInValid: true
        });

      return of(country);
    }
    else {
      let filteredCountries = countries.filter(p => p.CountryDisplayName.toLowerCase().indexOf((searchKey.toLowerCase())) > -1);
      if (filteredCountries && filteredCountries.length > 0) {
        return of(filteredCountries);
      }
      else {
        let country: CountryAutoComplete[] = [];
        country.push(
          {
            PKCountryId: undefined,
            CountryDisplayName: "No Results for '" + searchKey + "'",
            IsInValid: true
          });

        return of(country);
      }
    }
  }

}
