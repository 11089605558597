import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { RegistrationErrors } from 'src/app/_enums/constant-types.enum';
import { VerificationPopupInput } from 'src/app/_models/register-response';
import { User, UserParams } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { UpdateEmailVerificationComponent } from '../update-email-verification/update-email-verification.component';

@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.css']
})
export class UpdateEmailComponent implements OnInit {
  emailID: string | undefined = undefined;
  pk_user_id: string | undefined = undefined;
  userParams: UserParams = {};
  UpdateEmailForm: FormGroup;
  submitted = false;
  invalidNewEmail = false;
  sameNewEmail = false;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private alertifyService:AlertifyService,
    private utilityService: UtilityService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    public options: ModalOptions) { }

  ngOnInit(): void {
    this.userParams = this.options.initialState as any as UserParams;    
    this.emailID = this.userParams.EMAIL_ID;
    this.pk_user_id = this.userParams.PK_USER_ID;
    this.UpdateEmailForm = this.formBuilder.group({
      REGISTERED_EMAIL_ID: new FormControl({value:this.emailID, disabled: true}, [Validators.required, Validators.email]),
      NEW_EMAIL_ID: new FormControl("", [Validators.required, Validators.email])
    });
  }

  get form() {
    return this.UpdateEmailForm.controls;
  }

  get enableSubmit()
  {
    let isValid = true;
    if (this.UpdateEmailForm.controls["REGISTERED_EMAIL_ID"].value == "") {
      isValid = false;
    }
    if((this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value != null
    && this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value.trim() == "")
    || (!this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value))
    {
      isValid = false;
    }
    return isValid;
  }

  submit()
  {
    this.cleanForm();
    this.submitted = true;
    this.invalidNewEmail = false;
    this.sameNewEmail = false;
    var PrevEmailID = this.UpdateEmailForm.controls["REGISTERED_EMAIL_ID"].value;
    if (this.validateForm()) {
      this.userParams.PK_USER_ID = this.pk_user_id;
      this.userParams.EMAIL_ID = this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value;
      this.authService.updateRegisteredEmail(this.userParams).subscribe(next => {
        if (next.Errors && next.Errors.length > 0) {
          next.Errors.forEach(error => {
            switch (error) {
              case RegistrationErrors.EmailIdExists:
              case RegistrationErrors.RegisteredNotVerified:
              case RegistrationErrors.RegisteredDeactivated:
                //let title = "Edit Email";
                let message = "Email Address you have entered is already linked to another account. Please use different Email Address.";
                this.userParams.EMAIL_ID = PrevEmailID;
                var errors:any=[];
                errors.push(message);
                this.alertifyService.errorBaner(errors);  
                //this.alertifyService.ExternalLinkConfirmationBanner(title, message, "", this.overwriteAccountCallBack, "Confirm"); 
                break;
              case RegistrationErrors.NoAccountFound:
                var errors:any=[];
                errors.push("No account exists with this Email Address. Please enter a valid registered Email Address or click Register to continue login.");
                this.alertifyService.errorBaner(errors);  
                break;
            }
          });
        }
        else
        {
          this.close();
          this.openEmailVerificationPopup(next);
        }
      });

    }
  }

  openEmailVerificationPopup(user: User | null)
  {
    if(user != null)
    {
      var OldEmailID = this.UpdateEmailForm.controls["REGISTERED_EMAIL_ID"].value;
      let verificationInput = <VerificationPopupInput>{
        EmailID :  this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value,
        PK_User_ID: user.PK_USER_ID,
        RegisteredAlreadyVerified: false,
        RegisteredNotVerified : false,
        RegisteredDeactivated : false
      }
      const config: ModalOptions = {
        initialState : <any>verificationInput,
        backdrop : 'static',
        class: 'modal-dialog-centered',
        keyboard: false
      };
      this.modalRef = this.modalService.show(UpdateEmailVerificationComponent,config);
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.content.OnSubmit.subscribe((result: boolean) => {
        if (!result) {
          this.userParams.EMAIL_ID = OldEmailID;
        }
      });
    }
  }

  newMailValidation($event: any) {
      this.invalidNewEmail = !this.utilityService.validateEmail(this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value) ? true : false;
      this.sameNewEmail = this.UpdateEmailForm.controls["REGISTERED_EMAIL_ID"].value == this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value ? true : false;
  }

  validateForm() {
    let IsValid = true;
    if((this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value != null
    && this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value.trim() == "")
    || (!this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value))
    {
      IsValid = false;
    }
    else if (!this.utilityService.validateEmail(this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value)) {
      this.invalidNewEmail = true;
      IsValid = false;
    }
    else if(this.UpdateEmailForm.controls["REGISTERED_EMAIL_ID"].value == this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value)
    {
      this.sameNewEmail = true;
      IsValid = false;
    }

    if (this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value && this.UpdateEmailForm.controls["NEW_EMAIL_ID"].errors?.email) {
      this.invalidNewEmail = true;
      IsValid = false;
    }

    return IsValid;
  }

  overwriteAccountCallBack= (result: any) => {
    if (result) {
      this.userParams.PK_USER_ID = this.pk_user_id;
      this.userParams.EMAIL_ID = this.UpdateEmailForm.controls["NEW_EMAIL_ID"].value;
      this.authService.overwriteRegisteredEmail(this.userParams).subscribe(next => {
        if (next.Errors && next.Errors.length > 0) {
          next.Errors.forEach(error => {
            switch (error) {
            }
          });
        }
        else
        {
          this.close();
          this.openEmailVerificationPopup(next);
        }
      });
    }
  }

  public cleanForm() {
    let count = 0;
    if (this.UpdateEmailForm != null && this.UpdateEmailForm != undefined) {
      Object.keys(this.UpdateEmailForm.controls).forEach(key => {

        let value: any = this.UpdateEmailForm.controls[key].value;
        if (this.UpdateEmailForm.controls[key].value != null && typeof (this.UpdateEmailForm.controls[key].value) === "string"
          && this.UpdateEmailForm.controls[key].value !== "") {
          value = this.UpdateEmailForm.controls[key].value.trim();
        }
        else if (this.UpdateEmailForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.UpdateEmailForm.controls[key].setValue(value);
        }

      });
    }
  }

  close()
  {
    this.modalRef.hide();
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.UpdateEmailForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }
    return true;
  }

}
