import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NameValidator } from 'src/app/directives/no-white-spaces.directive';
import { Occupation } from 'src/app/_models/msl';
import { Portal } from 'src/app/_models/portals';
import { ProductsAutoComplete } from 'src/app/_models/products';
import { MedicalInfoService } from 'src/app/_services/medical-info.service';
import { AppConstants } from '../../../shared/AppConstants';
import { AuthenticationService } from '../../_services/authentication.service';
import { HomeService } from '../../_services/home.service';
import { OurProductsService } from '../../_services/our-products.service';
import { SystemConfigService } from '../../_services/system-config.service';
import { UtilityService } from '../../_services/utility.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;

  isProductInvalid: boolean = false;
  typeaheadNoResults: boolean = false;
  productTypeaheadLoading: boolean;
  selectedProduct: string | '' = '';
  typeaheadHideResultsOnBlur: boolean = true;

  allProducts: ProductsAutoComplete[] = [];
  productDataSource$: Observable<ProductsAutoComplete[]>;
  SearchMIForm: FormGroup;

  validationmsg: string;
  keywordvalidationmsg: string;
  productvalidationmsg: string;
  profesionvalidationmsg: string;
  Ismiq: boolean = false;
  prodmaptype: string = "";
  isLoggedInUser: boolean = false;
  occupationList: Occupation[] = [];
  IndicationsList: any = [];
  user_id?: string = "";
  portal_id?: string = "";
 
  portal: Portal = {
    PortalComponents: []
  };
  portalStausCodes: Array<any>;
  subscribeGetPortal: Subscription | null = null;

  constructor(
    private ourProductsService: OurProductsService,
    private medInfoService: MedicalInfoService,
    public utilityService: UtilityService,
    private homeService: HomeService,
    private authService: AuthenticationService,
    public fb: FormBuilder,
    private route: Router,
    public activaterouter: ActivatedRoute,
    private configService: SystemConfigService,
    private titleService: Title,
  ) {
    this.titleService.setTitle("SpringWorks");

    this.SearchMIForm = this.fb.group({
      ProductApprovalId: ["", [Validators.required, Validators.maxLength(150)]],
      SearchTags: ["", [Validators.required, Validators.maxLength(100)]],
      Indication: ["", [Validators.required, Validators.maxLength(100)]],
      // profession: [null, [Validators.required]],
    });

    this.authService.hcpDetails.subscribe(next=>{
      if (next && next.FK_PROFESSION_ID != undefined) {
        this.user_id = next.PK_UserId?.toString();
        let professionid = next.FK_PROFESSION_ID?.toString();
        this.SearchMIForm.get('profession')?.setValue(professionid);
      }      
    });
  }

  ngOnInit(): void {
    this.isLoggedInUser = this.authService.isUserLoggedIn();
    this.portal_id = this.utilityService.getPortalID();
    if (this.isLoggedInUser) {
      let userDetails = this.authService.getLoginHCPDetails();
      this.user_id = userDetails.PK_UserId?.toString();
      let profesionid = userDetails.FK_PROFESSION_ID?.toString();
      this.SearchMIForm.get('profession')?.setValue(profesionid);
    }

    this.prodmaptype = this.utilityService.getProductsMappingType();
    if (this.prodmaptype) {
      if (this.prodmaptype == "HYBRID_MIQ" || this.prodmaptype == "CP_MIQ") {
        this.Ismiq = true;
      }
      else {
        this.Ismiq = false;
      }
    }

    this.getPortal();
    // this.getProfessionsList();

    this.getProducts();
    this.getIndications();

    if (this.authService.isCookie()) {
      this.authService.cookieTracking().subscribe();
    }
  }

  get form() {
    return this.SearchMIForm.controls;
  }

  getPortal() {
    this.subscribeGetPortal = this.homeService.getPortal().subscribe(next => {
      this.portal = next;
      this.authService.portal = next;
      AppConstants.EnvironmentId = next.FK_EnvironmentId;
      AppConstants.ReporterTypeId = next.ReporterTypeId;
      this.utilityService.portalTitle = this.portal.PortalTitle;
      this.utilityService.reporterTypeName = next.ReporterTypeName ?? "US Healthcare Professional";
      this.utilityService.nonHcpReporterTypeName = next.NonHcpReporterTypeName ?? "Patient / Caregiver";
      this.utilityService.nonUSReporterTypeName = next.NonUsHcpReporterTypeName ?? "Non US Healthcare Professional";

      if (!this.utilityService.isProfileSelected()) {
        this.utilityService.isProfileSelection = true;
      }
      this.utilityService.setEnvironmentStorage(next);
      this.blockUI?.stop();
    },
      error => {
        this.blockUI?.stop();
        console.error(error);
      });
  }

/**
 * RMI related code
 */

  // getProfessionsList() {
  //   this.configService.getOccupations().subscribe(next => {
  //       this.occupationList = next;
  //   },
  //     error => {
  //       console.error(error);
  //     });
  // }

  // get enableProceed() {
  //   let isValid = true;
  //   if (!this.SearchMIForm.controls["profession"].value) {
  //     isValid = false;
  //   }
  //   return isValid;
  // }

  // validateproceed(): boolean {
  //   this.profesionvalidationmsg = "";
  //   let hasValid = true;

  //   if (!this.SearchMIForm.get('profession')?.value) {
  //     this.profesionvalidationmsg = "Please select profession.";
  //     hasValid = false;
  //   }
  //   return hasValid;
  // }

  // ProceedToRMI() {
  //   if (this.validateproceed()) {
  //     window.scrollTo(0, 0);
  //     this.route.navigate(['/request-mi'], { queryParams: { ProfessionID: this.SearchMIForm.get('profession')?.value }, skipLocationChange: true });
  //   }
  // }

  onProductSelect($event: TypeaheadMatch): void {
    
  }

  productTypeaheadOnBlur($event: any): void {
    this.isProductInvalid = false; 
    if ($event.item && typeof $event.item === 'object' && $event.item !== null && $event.item?.PK_PRODUCT_MAPPING_ID != '') {
      //this.AgreementSearchParams.ProductID = $event.item.PK_PRODUCT_MAPPING_ID;
      this.selectedProduct = $event.item.PRODUCT_DISPLAY_NAME;
      this.typeaheadNoResults = false;
    } else {
      //this.AgreementSearchParams.ProductID = null;
      this.typeaheadNoResults = true;
      this.selectedProduct = '';
      if ($event.srcElement) $event.srcElement.value = '';
    }
  }

  productTypeaheadNoResults($event: any) {
    if ($event && typeof $event.item === 'object' && $event.item !== null && $event.item?.PK_PRODUCT_MAPPING_ID != '') {
      //this.AgreementSearchParams.ProductID = null;
      this.isProductInvalid = true;
      this.typeaheadNoResults = true;
    }
    else {
      if (this.selectedProduct == '') {
        this.isProductInvalid = false;
        this.typeaheadNoResults = false;
        //this.AgreementSearchParams.ProductID = null;
      }
    }
  }

  changeProductTypeaheadLoading(e: any): void {
    this.productTypeaheadLoading = e;
  }

  /**
   * SMI related code
  */

  get enableSearch() {
    let isValid = true;
    if (!this.SearchMIForm.controls["ProductApprovalId"].value || !this.SearchMIForm.controls["SearchTags"].value) {
      isValid = false;
    }
    return isValid;
  }

  getProducts() {
    this.allProducts = [];
    this.ourProductsService.getProductsTypeHeadData().subscribe(next => {
      this.allProducts = next;
    },
      error => {
        console.error(error);
      });
    this.productDataSource$ = Observable.create((observer: any) => {  // Runs on every search
      observer.next(this.selectedProduct);
    }).pipe(
      switchMap((searchKey: string) => this.ourProductsService.searchProducts(this.allProducts, searchKey)));
  }

  getIndications()
  {
    this.IndicationsList = [];
    this.medInfoService.getIndications().subscribe(next => {
      if(next.Status)
      {
        this.IndicationsList = next.Indications;
      }
    },
    error => {
      console.error(error);
    });
  }

  OnSearchClick() {
    this.cleanForm();
    if (this.validateForm()) {
      let product = this.SearchMIForm.get('ProductApprovalId')?.value;
      let indication = this.SearchMIForm.get('Indication')?.value;
      let keyword = this.SearchMIForm.get('SearchTags')?.value;

      this.route.navigate(['/find-medical-info'], { queryParams: { ProductApprovalId: product ? product : null, Indication: indication, SearchTags: keyword }});
    }
  }

  setProduct($event: any) {
    console.log($event);
  }

  public cleanForm() {
    if (this.SearchMIForm != null && this.SearchMIForm != undefined) {
      Object.keys(this.SearchMIForm.controls).forEach(key => {

        let value: any = this.SearchMIForm.controls[key].value;
        if (this.SearchMIForm.controls[key].value != null && typeof (this.SearchMIForm.controls[key].value) === "string"
          && this.SearchMIForm.controls[key].value !== "") {
          value = this.SearchMIForm.controls[key].value.trim();
        }
        else if (this.SearchMIForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        value = this.utilityService.replaceEmojis(value);

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE" && key != "ProductApprovalId"
        && key != "Indication") {
          this.SearchMIForm.controls[key].setValue(value);
        }
      });
    }
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SearchMIForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }
    return true;
  }

  validateForm(): boolean {
    this.validationmsg = "";
    this.keywordvalidationmsg = "";
    this.productvalidationmsg = "";
    let hasValid = true;

    if (!this.SearchMIForm.get('ProductApprovalId')?.value) {
      this.productvalidationmsg = "Please select Product.";
      hasValid = false;
    } else if (!this.SearchMIForm.get('SearchTags')?.value) {
      this.productvalidationmsg = "Please provide keyword(s).";
      hasValid = false;
    } else if (this.SearchMIForm.get('SearchTags')?.value && this.SearchMIForm.get('SearchTags')?.value.length < 2) {
      this.keywordvalidationmsg = "Please enter at least 2 characters to perform search.";
      hasValid = false;
    } else if (this.SearchMIForm.get('SearchTags')?.value && this.SearchMIForm.get('SearchTags')?.errors?.maxlength) {
      this.keywordvalidationmsg = "Keyword(s) exceeded Max length of 100.";
      hasValid = false;
    } else if (this.SearchMIForm.get('SearchTags')?.value) {
      let IsSpecailCharExists = new RegExp(/^[ a-z0-9 ]+$/i);
      let searchtag = this.SearchMIForm.get('SearchTags')?.value;
      if (!IsSpecailCharExists.test(searchtag)) {
        this.keywordvalidationmsg = "Specail characters not allowed in Keyword(s).";
        hasValid = false;
      }
    }
 
    return hasValid;    
  }

}
