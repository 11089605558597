import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ZipCode } from 'src/app/_models/zipcode';
import { UtilityService } from '../../../_services/utility.service';

@Component({
  selector: 'app-zipcode-selection',
  templateUrl: './zipcode-selection.component.html',
  styleUrls: ['./zipcode-selection.component.css']
})
export class ZipcodeSelectionComponent implements OnInit {
  zipCodes: ZipCode[] = [];
  zipCodeForm: FormGroup;
  submitted = false;

  public event: EventEmitter<ZipCode> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    public options: ModalOptions,
    public utilityService: UtilityService  ) { }

  ngOnInit(): void {
    this.zipCodes = this.options.initialState as any as ZipCode[];
    this.zipCodeForm = this.formBuilder.group({
      ZIP_CODE: ['', Validators.required]
    });
  }

  get form() {
    return this.zipCodeForm.controls;
  }

  submitZipcode() {
    this.submitted = true;
    if(this.zipCodeForm.valid)
    {
      this.triggerEvent();
      this.modalRef.hide();
    }
  }

  triggerEvent() {
    let zipcode = this.zipCodes.find(z => z.ID == this.zipCodeForm.controls["ZIP_CODE"].value);
    this.event.emit(zipcode);
  }

  close() {
    this.modalRef.hide();
  }

  // handleInput($event: any, controlName: string): boolean {
  //   const control = this.zipCodeForm.controls[controlName];
  //   $event.value = this.utilityService.replaceEmojis($event.value);
  //   control.setValue($event.value);
  //   if ($event && $event.value != "") {
  //     $event.value = $event.value.trim();
  //     control.setValue($event.value);
  //     return false;
  //   }
  //   return true;
  // }

}
