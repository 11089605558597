import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../../shared/AppConstants';
import { NewsAnnouncements, NewsAnnouncementsData, NewsAnnouncementsSubscribe, SearchNA, TherapeuticAreaNameList } from '../_models/newsAnnouncements';

@Injectable({
  providedIn: 'root'
})
export class NewsAnnouncementsService {

  constructor(private http: HttpClient) { }

  getNewsAndAnnouncements(PkUserId: string): Observable<NewsAnnouncementsData> {
    const userParams: any = {
      PortalCode: AppConstants.PortalCode,
      FK_EnvironmentId: AppConstants.EnvironmentId,
      PK_UserID: PkUserId
    };
    return this.http.post<NewsAnnouncementsData>(AppConstants.PortalApiBaseUrl + '/api/NewsAnnouncements/GetNewsAnnouncements', userParams);
  }

  subscribeNewsAnnnouncements(subscribe: NewsAnnouncementsSubscribe): Observable<NewsAnnouncementsData> {
    subscribe.PortalCode = AppConstants.PortalCode;
    subscribe.FK_EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<NewsAnnouncementsData>(AppConstants.PortalApiBaseUrl + '/api/NewsAnnouncements/Subscribe', subscribe);
  }

  unsubscribe(sid: string, eid: string): Observable<NewsAnnouncementsData> {
    const unsubscribeParams: any = {
      EmailID: eid,
      FK_EnvironmentId: AppConstants.EnvironmentId,
      PK_NEWS_SUBSCRIBER_ID: sid
    };
    return this.http.post<NewsAnnouncementsData>(AppConstants.PortalApiBaseUrl + '/api/NewsAnnouncements/Unsubscribe/', unsubscribeParams);
  }

  GetTherapeuticArea(): Observable<Array<TherapeuticAreaNameList>> {
    const data: SearchNA = {
      PortalCode : AppConstants.PortalCode,
      FK_EnvironmentId : AppConstants.EnvironmentId,
    };
    
    return this.http.post<Array<TherapeuticAreaNameList>>(AppConstants.PortalApiBaseUrl + '/api/NewsAnnouncements/GetTherapeuticAreas', data);
  }


  SearchNewsAndAnnouncements(data: SearchNA): Observable<NewsAnnouncementsData> {
    data.PortalCode = AppConstants.PortalCode;
    data.FK_EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<NewsAnnouncementsData>(AppConstants.PortalApiBaseUrl + '/api/NewsAnnouncements/SearchNewsAndAnnouncements', data);
  }



}
