<div class="section-headingMain m0">
  <div class="close-icon">
    <a><img src="./assets/images/arrow.png" class="pointer" (click)="navigateToPreviousRoute();" alt="Back" data-toggle="tooltip" title="Back to Home"></a>
  </div>
  <h1 class="section-heading" [ngClass]="{'': (componentType == 'SUBMIT_REQUEST'), 'fndMslicon': (componentType == 'MSL_REQUEST'), 'serchinfoicon': (componentType == 'CONTENT_INFORMATION'), 'therapeutichead': (componentType == 'OUR_PRODUCTS')}">{{pageTitle}} </h1>
  <div class="aditionalmenuMain">
    <span class="menuIcon" id="menu-toggle">
      <img src="./assets/images/menu.png" (click)="onMenu();" class="pointer" alt="Menu">
    </span> 
    <div class="canvas-overlay" [ngClass]="{'show':menuToggle}"></div>
    <nav class="sidebar" [ngClass]="{'show':menuToggle}">
      <ul>
        <li>
          <a>
            <small id="closeSidenav" class="pull-left"><img src="./assets/images/arrow2.png" (click)="onMenuClose();" class="pointer" alt=""></small>&nbsp;
            <small class="homepageLink"><img src="./assets/images/home.png" (click)="onNavigate('home');" id="mi-home" class="pointer" alt=""></small>
          </a>
        </li>
        <ng-container *ngFor="let component of portal.PortalComponents;">
          <li *ngIf="component.Status && component.Code == 'SUBMIT_REQUEST'" [ngClass]="{'active':componentType == 'SUBMIT_REQUEST'}">
            <a class="pointer" id="menu-item1" (click)="onNavigate('submit-request');">{{component.Name}}</a>
          </li>
          <li *ngIf="component.Status && component.Code == 'MSL_REQUEST'" [ngClass]="{'active':componentType == 'MSL_REQUEST'}">
            <a class="pointer" id="menu-item2" (click)="onNavigate('msl');">{{component.Name}}</a>
          </li>
          <li *ngIf="component.Status && component.Code == 'CONTENT_INFORMATION'" [ngClass]="{'active':componentType == 'CONTENT_INFORMATION'}">
            <a class="pointer" id="menu-item3" (click)="onNavigate('find-medical');">{{component.Name}}</a>
          </li>
          <li *ngIf="component.Status && component.Code == 'OUR_PRODUCTS'" [ngClass]="{'active':componentType == 'OUR_PRODUCTS'}">
            <a class="pointer" id="menu-item4" (click)="onNavigate('our-products');">{{component.Name}}</a>
          </li>
        </ng-container>
      </ul>
    </nav>
  </div>
</div>
