
  <div class="pageContent bodyBg py-3">
    <div class="row">
      <div class="col-md-8 col-lg-9">
        <div class="card p-0 mb-2">
          <div class="row">
            <div class="col-lg-12">
              <figure class="home-banner mb-0">
                <img src="./assets/images/banner.jpg" alt="" class="img-fluid">
              </figure>
            </div>
          </div>
        </div>

        <form [formGroup]="SearchMIForm">
          <div class="card px-3 py-2 mb-3">
            <div class="row">

              <div class="col-md-12">
                <div class="title-lg text-primary"><i class="fas fa-search fs-20 pr-1"></i>Search Medical
                  Information</div>
                <p class="fs-12 pt-2 pb-3">
                  Medical information obtained from the search is intended to provide balanced, scientific, and
                  evidence-based answers to unsolicited medical questions. This information is not intended as medical
                  advice. Responsibility for patient care resides with the healthcare professional based on his or her
                  professional license, experience, and knowledge of the individual patient.
                  This resource may include information that has not been approved by the U.S. Food and Drug
                  Administration.
                </p>
              </div>
              <div class="col-md-12 text-right">
                <label class="fieldLabel mandatory fs-12 text-secondary">Required Fields</label>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="fieldLabel mandatory requiredfild">Product</label>
                  <app-product-control [inputAllProducts]="allProducts" [inputSelectedProduct]="selectedProduct"
                    formControlName="ProductApprovalId" (SearchParams)="setProduct($event)"></app-product-control>
                  
                    <div *ngIf="(form.ProductApprovalId.dirty || form.ProductApprovalId.touched || productvalidationmsg) && form.ProductApprovalId.invalid" 
                          class="invalid-form-control text-danger fs-12">
                      Provide valid Product.
                    </div>

                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="form-group">
                  <label for="indication" class="fieldLabel requiredfild">Disease State</label>
                  <select class="form-control" formControlName="Indication">
                    <option value="">Select</option>
                    <option *ngFor="let ind of IndicationsList" value="{{ind.ID}}">{{ind.Name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="form-group">
                  <label for="search-tags" class="fieldLabel mandatory requiredfild">Keyword(s)</label>
                  <input id="search-tags" type="text" class="form-control" autocomplete="off"
                    (blur)="handleInput($event.target,'SearchTags');" maxlength="100"
                    placeholder="Please enter keyword(s)" formControlName="SearchTags" />
                  <div *ngIf="keywordvalidationmsg" class="invalid-form-control text-danger fs-12">
                    {{keywordvalidationmsg}}
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6">
                <label class="fieldLabel">&nbsp;</label>
                <span class="d-block tooltip-wrapper"
                  tooltip="{{this.enableSearch ? '': 'Select a product and keyword(s) to search medical information'}}">
                  <button type="button" class="btn btn-block btn-primary" [disabled]="!this.enableSearch"
                    (click)="OnSearchClick();">
                    Search
                  </button>
                </span>
              </div>

              <div *ngIf="validationmsg" class="invalid-form-control text-danger fs-12 px-3">
                {{validationmsg}}
              </div>
              
              <div class="col-md-12 pt-3">
                <p class="fs-12">The medical information search will retrieve any scientific resource from our medical
                  information database where the search terms match the product and keyword(s) for the document. Please
                  enter unique keyword(s) related to your topic.</p>
              </div>

            </div>
          </div>
        </form>

      <!-- <form [formGroup]="SearchMIForm">
        <div class="card px-3 py-2 mb-3">
          <div class="row">
            <div class="col-md-12">
              <div class="title-lg text-primary">
                <i class="fas fa-file-medical fs-20 opacity-2"></i>
                &nbsp; Request for Medical Information
              </div>
              <p class="fs-12 pt-2 pb-3">
                This form is intended for US healthcare professionals only. If you have a medical inquiry, please complete this form and submit to receive a response from SpringWorks Therapeutics. 
              </p>
            </div>
            <div class="col-md-12">
              <div class="title-sm mandatory title-color-2 pb-2">Select one profession which best describes you?</div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 pt-2" *ngFor="let prof of occupationList;let index i">
              <div class="custom-control custom-radio ml-1 mt-2">
                <input type="radio" class="custom-control-input" id="{{prof.Id}}" value="{{prof.Id}}" formControlName="profession">
                <label class="custom-control-label" for="{{prof.Id}}">{{prof.Name}}</label>
              </div>
            </div>
            <div *ngIf="profesionvalidationmsg" class="invalid-form-control text-danger fs-12">
              {{profesionvalidationmsg}}
            </div>
            <div class="col-md-12 py-3">
              <button type="button" class="btn btn-primary px-3" [disabled]="!this.enableProceed" (click)="ProceedToRMI();">Proceed</button>
            </div>
            <div class="col-md-12">
              <h4 class="font-weight-bold fs-14 mandatory">Information required.</h4>
              <p class="fs-12 pb-2">The information we are requesting is the minimum necessary for {{utilityService.getPortalName()}} to process your medical information request.</p>
            </div>
          </div>
        </div>
      </form> -->

      </div>
      <div class="col-md-4 col-lg-3">
          <app-promotional-cards></app-promotional-cards>
      </div>
    </div>
  </div>
