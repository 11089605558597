<div class="pageContent bodyBg py-3">
    <div class="row">
      <div class="col-md-9">
        <div class="card px-3 py-2 mb-3">
         <div class="defaultTabs">
          <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                     <div class="title-lg text-primary"><i class="fas fa-search fs-20 opacity-2"></i>&nbsp;Site Map
                    </div>
                    <p class="fs-12 pt-2 pb-3"> A list of all pages for {{utilityService.getPortalName()}} Medical Information portal are displayed below. Clicking on the links will redirect you to the selected page.</p>
                </div>
            </div>
            <div class="row">
                <div *ngIf="isSMI" class="col-md-12 pb-3">
                     <a routerLink="/" class="fs-16">&gt; Home</a>
                </div>
                <div *ngIf="isSMI" class="col-md-12 pb-3">
                  <a routerLink="/find-medical-info" class="fs-16">&gt; Search Medical Information</a>
                </div>
                <div *ngIf="isRMI" class="col-md-12 pb-3">
                  <a routerLink="/request-mi" class="fs-16">&gt; Request for Medical Information</a>
                </div>
                <div *ngIf="isCongress" class="col-md-12 pb-3">
                  <a routerLink="/congress-presentation" class="fs-16">&gt; Congress Presentation</a>
                </div>
                <!-- <div class="col-md-12 pb-3">
                  <a routerLink="/find-msl" class="fs-16">&gt; Find My MSL</a>
                </div>
                <div class="col-md-12 pb-3">
                  <a  routerLink="/report-ae" class="fs-16">&gt; Report an Adverse Event</a>
                </div>
                <div class="col-md-12 pb-3">
                  <a routerLink="/report-pc" class="fs-16">&gt; Report a Product Complaint</a>
                </div>
                <div class="col-md-12 pb-3">
                    <a routerLink="/events-conferences" class="fs-16">&gt; Events and Conferences</a>
                </div>
                <div class="col-md-12 pb-3">
                  <a routerLink="/our-products" class="fs-16">&gt; Our Products</a>
                </div>
                <div class="col-md-12 pb-3">
                  <a routerLink="/resources" class="fs-16">&gt; Resources</a>
                </div> -->
            </div>
           </div>
         </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-3">
          <app-promotional-cards></app-promotional-cards>
      </div>
    </div>
    
  </div>