import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { interval, Subscription } from 'rxjs';
import { PasswordPolicyComponent } from 'src/app/components/password-policy/password-policy.component';
import { CodeVerificationErrors, RegistrationErrors } from 'src/app/_enums/constant-types.enum';
import { PasswordValidator } from 'src/app/_helpers/custom-password-validators';
import { ComparePassword } from 'src/app/_helpers/customvalidators';
import { PASSWORD_POLICY } from 'src/app/_models/password-policy';
import { ResetPasswordRequest, UserParams } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-account-resetpassword',
  templateUrl: './account-resetpassword.component.html',
  styleUrls: ['./account-resetpassword.component.css']
})
export class AccountResetpasswordComponent implements OnInit {
  
  userParams: UserParams = {};
  isActivated: boolean = false;
  codeVerified: boolean = false;
  inValidLink: boolean = false;
  inValidCode: boolean = false;
  codeExpired: boolean = false;
  codeAlreadyVerified: boolean = false;
  verifySubmitted: boolean = false;
  resetSubmitted: boolean = false;
  isResetSuccess: boolean = false;

  private subscription: Subscription | null = null;
  public datenow = new Date();
  milliSecondsInASecond = 1000;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  public timeDifference: any;
  public secondsToDday: any;
  public minutesToDday: any;
  isDisabled: boolean = false;
  showVerify: boolean = false;

  pkuserId: string | undefined = undefined;
  activationCode: string | undefined = undefined;
  emailID: string | undefined = undefined;
  pattern = this.utilityService.getPasswordPolicyPattern();
  SubmitVerificationForm: FormGroup;
  ResetPasswordForm: FormGroup;
  passwordPolicy: PASSWORD_POLICY = {};
  resetPasswordRequest: ResetPasswordRequest = {};

  constructor(private authService: AuthenticationService,
    private alertifyService:AlertifyService,
    private utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public modalRef: BsModalRef,
    public passwordPolicyModal: BsModalRef,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.pkuserId = this.route.snapshot.queryParams['userid'];
    this.activationCode = this.route.snapshot.queryParams['code'];
    this.emailID = this.route.snapshot.queryParams['emailid'];

    this.setFormGroup();

    if(this.pkuserId == undefined || this.activationCode == undefined 
      || this.emailID == undefined )
    {
      this.inValidLink = true;
    }
    else
    {
      // remove token from url to prevent http referer leakage
     this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

     this.userParams.PK_USER_ID = this.pkuserId;
     this.userParams.ACTIVATION_CODE = this.activationCode;
     this.userParams.EMAIL_ID = this.emailID;

     this.authService.accountActivationUsingLink(this.userParams)
      .subscribe(next => {
        if (next.Success) {
          this.codeVerified = true;
        }
        else
        {
          if (next.Errors.length > 0) {
            next.Errors.forEach(error => {
              switch (error) {
                case CodeVerificationErrors.InValidLink:
                  this.inValidLink = true;
                  break;
                case CodeVerificationErrors.CodeExpired:
                  this.codeExpired = true;
                  break;
                case CodeVerificationErrors.AlreadyVerified:
                  this.codeAlreadyVerified = true;
                  break;
              }
            });
          }
        }
      },
      error => {
        console.error(error);
      });
    }
  }

  get verifyForm() {
    return this.SubmitVerificationForm.controls;
  }
  
  get enableVerify()
  {
    let isValid = true;
    if (this.SubmitVerificationForm.controls["Code"].value == "") {
      isValid = false;
    }
    return isValid;
  }

  get form() {
    return this.ResetPasswordForm.controls;
  }

  get enableResetPassword()
  {
    let isValid = true;
    if (this.ResetPasswordForm.controls["REGISTERED_EMAIL_ID"].value == "") {
      isValid = false;
    }
    if (this.ResetPasswordForm.controls["PASSWORD"].value == "") {
      isValid = false;
    }
    if (this.ResetPasswordForm.controls["CONFIRM_PASSWORD"].value == "") {
      isValid = false;
    }
    return isValid;
  }

  setFormGroup() {
    this.SubmitVerificationForm = new FormGroup({
      Code: new FormControl("", [Validators.required, Validators.pattern('[0-9]*')]),
    });

    this.ResetPasswordForm = this.formBuilder.group({
      REGISTERED_EMAIL_ID: new FormControl({value:this.emailID, disabled: true}, [Validators.required, Validators.email]),
      PASSWORD: new FormControl("", [Validators.required,
        Validators.minLength(8),
        PasswordValidator.number,
        PasswordValidator.upper,
        PasswordValidator.lower,
        PasswordValidator.special]),
      CONFIRM_PASSWORD: new FormControl("", [Validators.required]),
    },
    {
      // Used custom form validator name
      validator: ComparePassword("PASSWORD", "CONFIRM_PASSWORD")
    });
  }

  get showTimer() {
    return this.isDisabled;
  }

  get enableLink() {
    return !this.isDisabled;
  }
  
  private getTimeDifference() {
    let countDownDate = new Date().setMilliseconds(-120000);
    this.timeDifference = this.datenow.getTime() - countDownDate;
    if (this.timeDifference <= 0) {
      this.isDisabled = false;
    }
    else {
      this.allocateTimeUnits(this.timeDifference);
    }
  }
  private allocateTimeUnits(timeDifference: any) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
  }

  resendLink()
  {
    this.clear();
    //this.isDisabled = true;
    // this.datenow = new Date();
    // this.subscription = interval(1000).subscribe(((x: any) => { this.getTimeDifference(); }));
    this.authService.resendResetPasswordLink(this.userParams).subscribe(next => {
      //this.isResendActivation = next;    
      this.SubmitVerificationForm.controls["Code"].setValue("");  
      this.showVerify = true;
    },
    error => {
      console.error(error);
    });
  }

  verify()
  {
    this.clear();
    this.verifySubmitted = true;
    let isValid = true;
    let errrs: any = [];
    if (!this.SubmitVerificationForm.controls["Code"].valid) {
      errrs.push("Please enter Code");
      isValid = false;
    }
    if (!isValid) {
      let errMessage = '';
      for (var i = 0; i < errrs.length; i++) {
        errMessage += errrs[i] + ' </br>';
      }
    }
    else
    {
      this.userParams.VERIFICATION_CODE = this.SubmitVerificationForm.controls["Code"].value;
      this.authService.accountActivation(this.userParams)
      .subscribe(next => {
        this.showVerify = true;
        if (next.Success) {
          this.codeVerified = true;
          this.showVerify = false;
        }
        else
        {
          if (next.Errors.length > 0) {
            next.Errors.forEach(error => {
              switch (error) {
                case CodeVerificationErrors.InValidCode:
                  this.inValidCode = true;
                  break;
                case CodeVerificationErrors.CodeExpired:
                  this.codeExpired = true;
                  break;
              }
            });
          }
        }
      },
      error => {
        console.error(error);
      });
    }
  }

  validForm()
  {
    let isValid = true;
    if (this.ResetPasswordForm.controls["REGISTERED_EMAIL_ID"] == null ||
    this.ResetPasswordForm.controls["REGISTERED_EMAIL_ID"].value == "") {
      isValid = false;
    }
    else if (!this.utilityService.validateEmail(this.ResetPasswordForm.controls["REGISTERED_EMAIL_ID"].value)) {
      isValid = false;
    }
    if (!this.ResetPasswordForm.controls["PASSWORD"].valid) {
      isValid = false;
    }
    if (this.ResetPasswordForm.controls["CONFIRM_PASSWORD"].valid) {
      if (this.ResetPasswordForm.controls["PASSWORD"].valid && (this.ResetPasswordForm.controls["PASSWORD"].value != this.ResetPasswordForm.controls["CONFIRM_PASSWORD"].value)) {
        isValid = false;
      }
    }
    else {
      isValid = false;
    }
    return isValid;
  }

  resetPassword()
  {
    this.resetSubmitted = true;
    if(this.validForm())
    {
      this.resetPasswordRequest.PkUserId = this.pkuserId;
      this.resetPasswordRequest.EmailId = this.ResetPasswordForm.controls["REGISTERED_EMAIL_ID"].value;
      this.resetPasswordRequest.Password = this.ResetPasswordForm.controls["PASSWORD"].value;
      this.resetPasswordRequest.ConfirmPassword = this.ResetPasswordForm.controls["CONFIRM_PASSWORD"].value;
      this.authService.resetPassword(this.resetPasswordRequest)
      .subscribe(next => {
        if (next.Errors?.length != undefined && next.Errors?.length  > 0) {
          var errors:any=[];
            next.Errors.forEach(error => {
              switch (error) {
                case RegistrationErrors.NoAccountFound:
                  errors.push("No account exists with this Email Address. Please enter a valid registered Email Address or click Register to continue login.");
                  break;
                case RegistrationErrors.ExistsInLast3Passwords:
                  errors.push("New password cannot be from last 3 Passwords. Please use a different password.");
                  break;
              }
            });
            this.alertifyService.errorBaner(errors);
        }
        else
        {
          this.clear();
          this.isResetSuccess = true;
        }
      },
      error => {
        console.error(error);
      });
    }
  }

  openPasswordPolicy()
  {
    const config: ModalOptions = {
      initialState : <any>this.passwordPolicy,
      backdrop : 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.passwordPolicyModal = this.modalService.show(PasswordPolicyComponent, config);
    this.passwordPolicyModal.content.closeBtnName = 'Close';
  }

  close()
  {
    this.modalRef.hide();
  }

  clear()
  {
    this.inValidCode = false;
    this.codeExpired = false;
    this.showVerify = false;
    this.codeVerified = false;
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SubmitVerificationForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }

    return true;
  }

}
