<footer>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 text-center pb-3">
        <a *ngIf="!(LoginStatus$ | async) as LoginStatus" href="javascript:void(0)"
          class="text-white d-inline-block fs-13 text-underline pb-2" (click)="utilityService.IsNotHcpAction=true;"><i
            class="fas fa-sign-out-alt fs-16"></i>
          <span *ngIf="isUS_HCP">Not a US Healthcare Professional? Click here </span>
          <span *ngIf="!isUS_HCP">Not a Non-US Healthcare Professional? Click here </span>
        </a>
        <p class="fs-12 text-white pt-3"> This site is intended as an educational resource for healthcare professionals
          practicing in the US. It may include information about products or uses that have not been approved by the
          U.S. Food and Drug Administration.</p>
      </div>
    </div>
    <div class="row border-bottom pb-2">
      <div class="col-md-12">
        <div class="footerLinks text-center">
          <!-- <a routerLink="resources">Resources</a> -->
          <a href="https://springworkstx.com/privacy-policy/" target="_blank">Privacy Policy</a>
          <a href="javascript:void(0)" (click)="openContactModal(template)">Contact Us</a>
          <a href="https://springworkstx.com/terms-of-use/" target="_blank">Terms of Use</a>
          <a routerLink="sitemap" *ngIf="isUS_HCP">Site Map</a>
        </div>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col-lg-3 col-md-4 col-12">
        <p class="text-white fs-9 footer-logo">Powered by<a href="https://www.scimaxglobal.com/" target="_blank"><img
              src="./assets/images/scimax.png" alt=""></a></p>
      </div>

      <div class="col-lg-6 col-md-5">
        <ul class="quicklinks">
          <p class="text-white d-block fs-13 pt-2 text-center">© {{copyrightCurrentYear}}
            SpringWorks Therapeutics. All rights reserved.</p>
          <p class="text-white d-block fs-13 pt-2 text-center">M_NON_US_0014</p>
          <!-- {{utilityService.getPortalName()}} -->
        </ul>
      </div>

      <div class="col-lg-3 col-md-3 col-12">
        <a href="https://springworkstx.com" target="_blank"  class="footerspringworks-logo">
          <img src="./assets/images/mbl-logo.png" alt="">
        </a>
      </div>
    </div>
  </div>
</footer>

<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block" >Contact Us 
      <span class="mt-1 float-right fs-13 contact-us-span" data-dismiss="modal" (click)="modalRef?.hide()">
        <i class="fas fa-times"></i></span>
    </h5>
  </div>
  <div class="modal-body px-2 py-4">
       <div class="container-fluid">
           <div class="row">
                <div class="col-md-12 col-md-12">
                  <ul class="contactUs">
                    <li>
                      <div class="row">
                        <div class="col-md-12">
                          <a href="mailto:medinfo@springworkstx.com?subject=Medical%20Information%20Request" class="d-inline-block"><span><i class="fas fa-envelope"></i></span> medinfo@springworkstx.com</a>
                        </div>
                      </div>
                      </li>
              
                  </ul>
                </div>
           </div>
       </div>
  </div>
</ng-template>