<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title w-100 d-block">Verification
        <a (click)="this.close()" class="fs-12 text-underline float-right pt-1 text-white"><i class="fas fa-times"></i></a>
      </h5>
    </div>
    <div class="modal-body">
        <form [formGroup]="SubmitVerificationForm">
          <div class="container-fluid">
              <div class="row">
                  <div class="col-md-12">
                    <p class="fs-14 text-center pb-3">A verification code has been shared to your registered email address. Please enter the code to verify.</p>
                  </div>
                  <div class="col-md-12">
                      <label class="fieldLabel mandatory">Code</label>
                      <input numbersOnly type="text" class="form-control" formControlName="Code" 
                      placeholder="Enter Code" maxlength="4" (blur)="handleInput($event.target,'Code');"
                      autocomplete="off" [ngClass]="{ 'is-invalid': (form.Code.dirty || form.Code.touched || submitted) && form.Code.errors }">
                      <div *ngIf="(form.Code.dirty || form.Code.touched || submitted) && form.Code.invalid" class="invalid-form-control text-danger fs-12">
                        <div *ngIf="form.Code.errors?.['required']">
                          Code is required.
                        </div>
                      </div>
                      <div *ngIf="inValidCode" class="invalid-form-control text-danger fs-12">
                        Invalid verification code, please re-enter valid verification code or click on Resend Code.
                      </div>
                      <div *ngIf="codeExpired" class="invalid-form-control text-danger fs-12">
                        Your verification code has expired. Please click on Resend Code.
                      </div>
                  </div>
                  <div class="col-md-12 pt-3">
                      <div class="row">
                        <div class="col-md-6 col-sm-6">
                          <button type="button" class="btn btn-primary"  [disabled]="!this.enableVerify"
                          (click)="verify()">Verify</button>
                        </div>
                        <div class="col-md-6 col-sm-6">
                          <a class="fs-13 float-right pt-2" *ngIf="enableLink" 
                          href="javascript: void(0);" (click)="resendCode()">Resend Code</a> 
                          <a class="fs-13 float-right pt-2 disabled" *ngIf="!enableLink">Resend Code</a>
                          <span class="float-right clearfix fs-12" *ngIf="this.showTimer">Resend Code will Enable in {{("0"+minutesToDday).slice(-2)}} : {{("0"+secondsToDday).slice(-2)}} </span> 
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </form>
    </div>
</div>
