import { FormControl } from "@angular/forms";

export interface ValidationResult {
  [key: string]: boolean;
}

export class PasswordValidator {

  public static number(control: FormControl): ValidationResult | null {
    let hasNumber = /\d/.test(control.value);
    if (!hasNumber) {
      return { number: true };
    }
    return null;
  }

  public static upper(control: FormControl): ValidationResult | null {
    let hasUpper = /[A-Z]/.test(control.value);
    if (!hasUpper) {
      return { upper: true };
    }
    return null;
  }

  public static lower(control: FormControl): ValidationResult | null {
    let hasLower = /[a-z]/.test(control.value);
    if (!hasLower) {
      return { lower: true };
    }
    return null;
  }

  public static special(control: FormControl): ValidationResult | null {
    let hasSpecial = /[!#$%&()*+-/@[\]^_{}~]/.test(control.value);
    if (!hasSpecial) {
      return { special: true };
    }
    return null;
  }
}
