<block-ui [delayStart]="100" [delayStop]="200">
  <section class="main-body">

    <app-header *ngIf="showHeader"></app-header>

    <div class="modal fade" id="sessiontimeout" [ngClass]="{'show' : IsSessionExpired, 'hide':!(IsSessionExpired)}"
      [ngStyle]="{'display': IsSessionExpired ? 'block' : 'none'}" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title w-100 d-block">
              Session Timeout !
              <a (click)="closeTimeoutPopup();" class="fs-12 text-underline float-right pt-1 text-white"
                data-dismiss="modal"><i class="fas fa-times"></i></a>
            </h5>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <!-- <div class="col-md-12">
                  <p class="fs-14 text-center">
                    Your session has expired due to inactivity. Please click here to <a href="javascript:void(0)"
                      (click)="openLoginForm()">Login</a>
                </div> -->
                <div class="col-md-12">
                  <p class="fs-14 text-center">
                    Your session has expired due to inactivity. Please refresh the page.
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <section class="pageContentMain">
      <router-outlet></router-outlet>
      <app-footer *ngIf="showFooter"></app-footer>

      <!-- Auto Modal Popover -->
      <div *ngIf="showHeader" class="modal self-hcp-backdrop fade"
        [ngClass]="{'show' : !authService.isUserLoggedIn() && (!utilityService.isProfileSelected() || utilityService.isProfileSelection) }"
        id="autoModal" data-backdrop="true" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        [ngStyle]="{'display': !authService.isUserLoggedIn() && !utilityService.isNonHCP && (!utilityService.isProfileSelected() || utilityService.isProfileSelection) ? 'block' : 'none'}">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"> Welcome to SpringWorks Therapeutics Medical </h5>
            </div>
            <div class="modal-body">
              <p class="fs-13 text-center">
                This online medical resource is intended to provide balanced, scientific, evidence-based information on SpringWorks Therapeutics products and disease areas of focus to address unsolicited medical questions. This content is for informational purposes only and is not intended as medical advice. 
                It may include information about products or uses that have not been approved by the U.S. Food and Drug Administration.
              </p>
              <button type="button" class="btn btn-block btn-primary mt-3 fs-14" data-dismiss="modal"
                (click)="actionOnIamHCP(true);">I am a {{utilityService.reporterTypeName}}</button>
              <button type="button" class="btn btn-block btn-primary mt-3 fs-14" data-dismiss="modal"
                (click)="actionOnIamHCP(false);">I am a {{utilityService.nonUSReporterTypeName}}</button>
              <button type="button" class="btn btn-block btn-primary mt-3 fs-14" data-target="#nonhcpredirection"
                data-toggle="modal" data-dismiss="modal"
                (click)="actionOnIamNonHCP();">I am a {{utilityService.nonHcpReporterTypeName}}</button>
              <p class="fs-13 pt-3">Feel free to contact us directly at 
                <a href="mailto:medinfo@springworkstx.com?subject=Medical%20Information%20Request">medinfo@springworkstx.com</a> to submit your question.
              </p>
              <p class="fs-13 pt-3">
                Please review our <a href="https://springworkstx.com/terms-of-use/" target="_blank">Terms of Use</a> and 
                <a href="https://springworkstx.com/privacy-policy/" target="_blank">Privacy Policy</a> before continuing.
              </p>
            </div>

          </div>
        </div>
      </div>

      <!-- Non HCP redirection popup -->
      <div *ngIf="showHeader" class="modal self-hcp-backdrop fade" [ngClass]="{'show': utilityService.isNonHCP}"
        id="nonhcpredirection" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title w-100 d-block">
                Welcome to Patient/Caregiver Page
              </h5>
            </div>
            <div class="modal-body pt-0">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12 text-center mt-3 px-0">
                    <p class="fs-13 text-center pt-3">
                      You are about to leave SpringWorks Therapeutics Medical Information Portal.
                    </p>
                    <a href="https://springworkstx.com/patients/" class="btn btn-primary btn-block text-center mt-3 font-weight-bold">Click here to get to SpringWorks Corporate website</a>
                  </div>
                  <a href="#" class="btn btn-primary btn-block text-center mt-2 font-weight-bold"
                   data-target="#autoModal" data-toggle="modal"data-dismiss="modal">Back</a>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div *ngIf="utilityService.IsNotHcpAction" class="modal self-hcp-backdrop fade show z-index-3"
        id="nonhcpredirection2" [ngStyle]="{'display': utilityService.IsNotHcpAction ? 'block' : 'none'}"
        data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
          <!-- <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title w-100 d-block">
                Welcome to Patient/Caregiver Page
              </h5>
            </div>
            <div class="modal-body pt-0">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12 text-center mt-3 px-0">
                    <p class="fs-13 text-center pt-3">
                      You are about to leave SpringWorks Therapeutics Medical Information Portal
                    </p>
                    <a href="https://springworkstx.com" class="btn btn-primary btn-block text-center mt-3 font-weight-bold">Click here to get to SpringWorks Corporate website</a>
                  </div>
                  <button type="button" class="btn btn-primary btn-block text-center mt-3 fs-14" data-dismiss="modal"
                    (click)="onNonHCPClose();">Close</button>
                </div>
              </div>

            </div>

          </div> -->

          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"> Welcome to SpringWorks Therapeutics Medical </h5>
            </div>
            <div class="modal-body">
              <p class="fs-13 text-center">
                This online medical resource is intended to provide balanced, scientific, evidence-based information on SpringWorks Therapeutics products and disease areas of focus to address unsolicited medical questions. This content is for informational purposes only and is not intended as medical advice. 
                It may include information about products or uses that have not been approved by the U.S. Food and Drug Administration.
              </p>
              <button type="button" class="btn btn-block btn-primary mt-3 fs-14" data-dismiss="modal"
                (click)= "actionOnIamHCP(true);">I am a {{utilityService.reporterTypeName}}</button>
              <button type="button" class="btn btn-block btn-primary mt-3 fs-14" data-dismiss="modal"
                (click)="actionOnIamHCP(false);">I am a {{utilityService.nonUSReporterTypeName}}</button>
              <button type="button" class="btn btn-block btn-primary mt-3 fs-14" data-target="#nonhcpredirection"
                data-toggle="modal" data-dismiss="modal"
                (click)="this.utilityService.IsNotHcpAction = false; actionOnIamNonHCP();">I am a {{utilityService.nonHcpReporterTypeName}}</button>
              <p class="fs-13 pt-3">Feel free to contact us directly at 
                <a href="mailto:medinfo@springworkstx.com?subject=Medical%20Information%20Request">medinfo@springworkstx.com</a> to submit your question.
              </p>
              <p class="fs-13 pt-3">
                Please review our <a href="https://springworkstx.com/terms-of-use/" target="_blank">Terms of Use</a> and 
                <a href="https://springworkstx.com/privacy-policy/"  target="_blank">Privacy Policy</a> before continuing.
              </p>
            </div>

          </div>
        </div>
      </div>

      <!-- <div *ngIf="showHeader" class="privacyPrompt pt-4" 
        [ngStyle]="{'display':cookieToogle ? 'block' : 'none'}">
        <div class="row">
          <div class="col-md-7 col-sm-7">
            <p>
              This website uses cookies. By using our website without changing your cookie settings you agree to our use
              of cookies as described in our <a href="https://www.scimaxglobal.com/privacy-policy/"
                target="_blank">Privacy Policy.</a>
            </p>
          </div>
          <div class="col-md-5 col-sm-5">
            <div class="text-right mbl-100 cookieSettings">
              <a href="javascript:void(0);" (click)="allowCookies()">Accept</a>
              <a href="javascript:void(0)" (click)="closeCookies()">Reject</a>
            </div>
          </div>
        </div>

      </div> -->

      <div *ngIf="showHeader" class="privacyPrompt pt-4" [ngStyle]="{'display':cookieToogle ? 'block' : 'none'}"> 
        <div class="row">
            <div class="col-md-7 col-sm-7">
               <p>This website uses cookies. By using our website without changing your cookie settings you agree to our use of cookies as described in our <a href="https://springworkstx.com/privacy-policy/" target="_blank">Privacy Policy.</a>
                </p>
            </div>
            <div class="col-md-5 col-sm-5">
                  <div class="text-right mbl-100 cookieSettings">
                    <a href="javascript:void(0)" (click)="allowCookies()">Accept</a>
                    <a href="javascript:void(0)" (click)="closeCookies()">Reject</a>
                </div>
            </div>
        </div>
      </div>

    </section>
    <app-toast-message></app-toast-message>
  </section>
</block-ui>