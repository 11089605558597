import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../../shared/AppConstants';
import { CP_TEMP_FILE_STORE, DownloadExcel } from '../_models/file';

//https://stackblitz.com/edit/upload-multiple-files?file=src%2Fapp%2Fapp.component.html


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }

  uploadFile(file: File, fileId?: string): Observable<CP_TEMP_FILE_STORE> {

    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(AppConstants.PortalApiBaseUrl + '/api/File/Upload/' + (fileId || ''), formData);
  }

  downloadFile(fileDetail: CP_TEMP_FILE_STORE): Observable<Blob> {
    return this.http.post(AppConstants.PortalApiBaseUrl + '/api/File/Download',
      fileDetail,
      {
        responseType: 'blob', // This is the response type expected from backend service/API Controller.
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      });
  }

  exportExcel(data: DownloadExcel): Observable<HttpEvent<Blob>> {
    return this.http.post(AppConstants.PortalApiBaseUrl + '/api/File/DownloadExcel', data, { observe: 'response', responseType: 'blob' });
  }  

}
