<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title w-100 d-block" id="exampleModalLabel">{{this.title}}
        <a (click)="this.close()" class="fs-12 text-underline float-right pt-1 text-white" data-dismiss="modal"><i class="fas fa-times"></i></a>
      </h5>
    </div>
    <div class="modal-body">
      <div *ngIf = "alreadyVerified">
        <div class="col-md-12">
          <span class="fs-14 text-center pb-3">Email address is already registered please <a href="javascript:void(0)"
          (click)="openLoginForm()">login</a> into your account or click <a href="javascript:void(0)"
          (click)="openForgotPassword()">Forgot Password</a> to reset your password.</span></div>
      </div>
      <div *ngIf = "isDeactivated">
        <div class="col-md-12">
          <span class="fs-14 text-center pb-3">You have requested for deactivation of your account on {{this.deactivatedDate | date:'dd-MMM-YYYY'}}. Please click on <a href="javascript:void(0)"
          (click)="activateVerifyPopup()">Verify</a> to reactivate your account for login.</span></div>
      </div>
      <div *ngIf = "notVerified">
        <div class="col-md-12">
          <span class="fs-14 text-center pb-3">You have not completed your account activation process. Please click on <a href="javascript:void(0)"
          (click)="activateVerifyPopup()">Verify</a> to complete your activation process to continue login.</span></div>
      </div>
      <div *ngIf = "isActivated">
        <div class="col-md-12">
          <span class="fs-14 text-center pb-3">Your account is activated. Please click here to <a href="javascript:void(0)"
          (click)="openLoginForm()">Login.</a></span></div>
      </div>
      <div *ngIf = "enableVerifyForm">
        <form [formGroup]="SubmitVerificationForm">
          <div class="container-fluid">
              <div class="row">
                  <div class="col-md-12">
                    <p class="fs-14 text-center pb-3">A verification code has been shared to your registered email address. Please enter the code to verify.</p>
                  </div>
                  <div class="col-md-12">
                      <label class="fieldLabel mandatory">Code</label>
                      <input numbersOnly type="text" class="form-control" formControlName="Code" autocomplete="off" 
                      placeholder="Enter Code" maxlength="4" (blur)="handleInput($event.target,'Code');"
                      [ngClass]="{ 'is-invalid': (form.Code.dirty || form.Code.touched || submitted) && form.Code.errors }">
                      <div *ngIf="(form.Code.dirty || form.Code.touched || submitted) && form.Code.invalid" class="invalid-form-control text-danger fs-12">
                        <div *ngIf="form.Code.errors?.['required']">
                          Code is required.
                        </div>
                      </div>
                      <div *ngIf="inValidCode" class="invalid-form-control text-danger fs-12">
                        Invalid verification code, please re-enter valid verification code or click on Resend Code.
                      </div>
                      <div *ngIf="codeExpired" class="invalid-form-control text-danger fs-12">
                        Your verification code has expired. Please click on Resend Code.
                      </div>
                      <!-- <div *ngIf="codeAlreadyVerified" class="invalid-form-control text-danger fs-12">
                        Your account has already been activated.
                      </div> -->
                  </div>
                  <div class="col-md-12 pt-3">
                      <div class="row">
                        <div class="col-md-4 col-sm-4">
                          <button type="button" class="btn btn-primary"  [disabled]="!this.enableVerify"
                          (click)="verify()">Verify</button>
                        </div>
                        <div class="col-md-8 col-sm-8">
                          <a class="fs-13 float-right pt-2" *ngIf="enableLink" 
                          href="javascript: void(0);" (click)="resendCode()">Resend Code</a> 
                          <a class="fs-13 float-right pt-2 disabled" *ngIf="!enableLink">Resend Code</a>
                          <span class="float-right clearfix fs-12" *ngIf="this.showTimer">Resend Code will Enable in {{("0"+minutesToDday).slice(-2)}} : {{("0"+secondsToDday).slice(-2)}} </span> 
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </form>
      </div>
    </div>
</div>
