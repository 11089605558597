import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CP_VW_LOOKUPS } from 'src/app/_models/cpvw-lookups';
import { RequestModel } from 'src/app/_models/request-model';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { RequestService } from 'src/app/_services/request.service';
import { SystemConfigService } from 'src/app/_services/system-config.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { CalendarModule } from 'primeng/calendar';
@Component({
  selector: 'app-view-edit-call-request',
  templateUrl: './view-edit-call-request.component.html',
  styleUrls: ['./view-edit-call-request.component.css']
})
export class ViewEditCallRequestComponent implements OnInit {
  MSLRequestForm: FormGroup;
  Data: any;
  RequestData: RequestModel;
  IsView: boolean = false;
  bsConfig: any;
  submitted: boolean = false;
  preferedContacts: CP_VW_LOOKUPS[] = [];
  invalidPreferredEmail = false;
  minDate: Date = new Date();

  public OnSubmit: EventEmitter<boolean> = new EventEmitter();
  
  constructor(private configService: SystemConfigService,
    public alertfyService: AlertifyService,
    private _requestService: RequestService,
    private utilityService: UtilityService,
    private authService: AuthenticationService,
    public modalRef: BsModalRef,
    public options: ModalOptions) {
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  ngOnInit(): void {
    this.Data = this.options.initialState as any;
    this.RequestData = this.Data.Request as RequestModel;
    this.IsView = this.Data.IsView;

    this.bsConfig = Object.assign({}, { 
      containerClass: "theme-blue", 
      dateInputFormat: 'DD-MMM-YYYY',
      showWeekNumbers : false,
      minDate: this.minDate
    });
    
    this.MSLRequestForm = new FormGroup({
      TYPE: new FormControl(this.RequestData.REPORT_REQUEST_TYPE??""),
      PRODUCTNAME: new FormControl(""),
      ZIPCODE: new FormControl(this.RequestData.POSTAL_CODE??""),
      MSLID: new FormControl(this.RequestData.MSL_ID??""),
      FIRST_NAME: new FormControl({value:this.RequestData.FIRST_NAME??"", disabled: this.IsView}, Validators.required),
      LAST_NAME: new FormControl({value:this.RequestData.LAST_NAME??"", disabled: this.IsView}, Validators.required),
      PHONE: new FormControl({value:this.RequestData.PHONE??"", disabled: this.IsView}),
      PHONE_EXT: new FormControl({value:this.RequestData.PHONE_EXT??"", disabled: this.IsView}),
      MSL_SCHEDULED_DATE: new FormControl({value:this.RequestData.MSL_SCHEDULED_DATE ? new Date(this.RequestData.MSL_SCHEDULED_DATE) : "", disabled: this.IsView}, Validators.required),
      MSL_SCHEDULED_TIME: new FormControl({value:this.RequestData.MSL_SCHEDULED_TIME??"", disabled: this.IsView}, Validators.required),
      MSL_SCHEDULED_TIME_ZONE: new FormControl({value:this.RequestData.MSL_SCHEDULED_TIME_ZONE??"", disabled: this.IsView}, Validators.required)
    });
  }

  get form() {
    return this.MSLRequestForm.controls;
  }

  //get minDate()
  //{
  //  let date: Date = new Date();
  //  date.setDate( date.getDate() + 1 );
  //  return date;
  //}

  formControlValid(controlName: string): boolean
  {
    let isValid = true;
    if((this.MSLRequestForm.controls[controlName].value != null
    && this.MSLRequestForm.controls[controlName].value.trim() == "")
    || (!this.MSLRequestForm.controls[controlName].value))
    {
      isValid = false;
    }
    return isValid;
  }

  get enableSaveDraft() 
  {
    let isValid = true;
    if (this.MSLRequestForm.controls["FIRST_NAME"].value 
    || this.MSLRequestForm.controls["LAST_NAME"].value 
    || this.MSLRequestForm.controls["PHONE"].value 
    || this.MSLRequestForm.controls["PHONE_EXT"].value 
    ||this.MSLRequestForm.controls["MSL_SCHEDULED_DATE"].value
    ||this.MSLRequestForm.controls["MSL_SCHEDULED_TIME"].value 
    || this.MSLRequestForm.controls["MSL_SCHEDULED_TIME_ZONE"].value ) {
      isValid = false;
    }

    return isValid;
  }

  get enableSubmit()
  {
    let isValid = true;
    if(!this.formControlValid("FIRST_NAME"))
    {
      isValid = false;
    }
    if(!this.formControlValid("LAST_NAME"))
    {
      isValid = false;
    }
    if (!this.MSLRequestForm.controls["PHONE"].value) {
      isValid = false;
    }
        
    if (!this.MSLRequestForm.controls["MSL_SCHEDULED_DATE"].value) {
      isValid = false;
    }
    if (!this.MSLRequestForm.controls["MSL_SCHEDULED_TIME"].value) {
      isValid = false;
    }
    if (!this.MSLRequestForm.controls["MSL_SCHEDULED_TIME_ZONE"].value) {
      isValid = false;
    }
    return isValid;
  }

  validateForm(): boolean {
    this.MSLRequestForm.markAsTouched();
    let hasValid = true;
    if (!this.MSLRequestForm.valid) {
      var errrs: any = [];
      if (!this.MSLRequestForm.controls["FIRST_NAME"].valid) {
        errrs.push("Please enter First Name.");
      }
      if (!this.MSLRequestForm.controls["LAST_NAME"].valid) {
        errrs.push("Please enter Last Name.");
      }
      if (!this.MSLRequestForm.controls["PHONE"].valid) {
        errrs.push("Please enter Phone.");
      }
      if (!this.MSLRequestForm.controls["MSL_SCHEDULED_DATE"].valid) {
        errrs.push("Please enter Date.");
      }
      if (!this.MSLRequestForm.controls["MSL_SCHEDULED_TIME"].valid) {
        errrs.push("Please select Time.");
      }
      if (!this.MSLRequestForm.controls["MSL_SCHEDULED_TIME_ZONE"].valid) {
        errrs.push("Please select Timzone.");
      }

      //this.alertfyService.validationBaner(errrs);
      hasValid = false;
    }
    return hasValid;
  }

  SendRequest(requestStatus: string) {
    this.cleanForm();
    if(requestStatus == "SUBMITTED")
      this.submitted = true;
    if ((requestStatus == "SUBMITTED" && this.validateForm())
    || requestStatus == "DRAFT") {
      let typeid =  1;
      this.MSLRequestForm.controls["TYPE"].setValue(typeid);
      this.MSLRequestForm.controls["PRODUCTNAME"].setValue(this.RequestData.PRODUCT_NAME);
      this.MSLRequestForm.controls["ZIPCODE"].setValue(this.RequestData.POSTAL_CODE);
      this.MSLRequestForm.controls["MSLID"].setValue(this.RequestData.MSL_ID);
      var formData = this.MSLRequestForm.value;

      let request: RequestModel = <RequestModel>
      {
        PK_REQUEST_ID: this.RequestData.PK_REQUEST_ID,
        FK_USER_ID: this.RequestData.FK_USER_ID,
        USER_ID: this.RequestData.USER_ID,
        REPORT_REQUEST_TYPE: "MSL_CALL",   
        FK_REPORT_REQUEST_TYPE: "MSL_CALL",        
        PK_PRODUCT_MAPPING_ID: this.RequestData.PK_PRODUCT_MAPPING_ID,
        REQUEST_TYPE: "MI",
        STATUS: requestStatus,
        FIRST_NAME: this.MSLRequestForm.controls["FIRST_NAME"].value,
        LAST_NAME: this.MSLRequestForm.controls["LAST_NAME"].value,
        PHONE: this.MSLRequestForm.controls["PHONE"].value,
        PHONE_EXT: this.MSLRequestForm.controls["PHONE_EXT"].value,
        MSL_ID: this.RequestData.MSL_ID,
        MSL_NAME: this.RequestData.MSL_NAME,
        MSL_FIRST_NAME: this.RequestData.MSL_FIRST_NAME,
        MSL_LAST_NAME: this.RequestData.MSL_LAST_NAME,
        MSL_PHONE: this.RequestData.MSL_PHONE,
        MSL_EMAIL_ID: this.RequestData.MSL_EMAIL_ID,
        MSL_ZIPCODE:this.RequestData.MSL_ZIPCODE,
        MSL_SCHEDULED_DATE: this.MSLRequestForm.controls["MSL_SCHEDULED_DATE"].value,
        MSL_SCHEDULED_TIME: this.MSLRequestForm.controls["MSL_SCHEDULED_TIME"].value,
        MSL_SCHEDULED_TIME_ZONE:this.MSLRequestForm.controls["MSL_SCHEDULED_TIME_ZONE"].value
      }

      this._requestService.saveRequest(request)
      .subscribe(res => {
        if (res !== null && res !== undefined) {
          if (res.Status) {
            if(requestStatus == "DRAFT")
            {
              this.alertfyService.successBaner("Your request " + res.ResponseID + " is saved successfully.");
              this.OnSubmit.emit(true);
            }
            else if(requestStatus == "SUBMITTED")
            {
              this.alertfyService.successBaner("Your request " + res.ResponseID + " is submitted successfully.");
              this.OnSubmit.emit(true);
            }
            this.close();
          }    
          else {
            this.alertfyService.errorBaner(res.ErrorMessage??"");
          }      
        }
        else {
          this.alertfyService.errorBaner('Unknown Error..!');
        }  
      },
      err => {
        this.alertfyService.error(err);
      });
    }
  }

  public cleanForm() {
    let count = 0;
    if (this.MSLRequestForm != null && this.MSLRequestForm != undefined) {
      Object.keys(this.MSLRequestForm.controls).forEach(key => {

        let value: any = this.MSLRequestForm.controls[key].value;
        if (this.MSLRequestForm.controls[key].value != null && typeof (this.MSLRequestForm.controls[key].value) === "string"
          && this.MSLRequestForm.controls[key].value !== "") {
          value = this.MSLRequestForm.controls[key].value.trim();
        }
        else if (this.MSLRequestForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE" && key != "MSL_SCHEDULED_TIME" && key != "MSL_SCHEDULED_TIME_ZONE") {
          this.MSLRequestForm.controls[key].setValue(value);
        }

      });
    }
  }

  close()
  {
    this.modalRef.hide();
  }

  cancel() {
    this.alertfyService.ConfirmationBanner("Do you want to Abort the Request ?", this.cancelCallback, "Confirm");
  }

  cancelCallback = (result: any) => {
    if(result) {
      this.close()
    }
  }
  
  handleInput($event: any, controlName: string): boolean {
    if (controlName != "DESCRIPTION") {
      $event.value = this.utilityService.replaceEmojis($event.value);
    }
    if ($event && $event.value != "") {
      const control = this.MSLRequestForm.controls[controlName];
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }

    return true;
  }

  Ondatechange(value: any, fcn: string) {
    if (value) {
      this.utilityService.resetCalendar(value, fcn, this.MSLRequestForm);
    }
  }

  keyPress(event: KeyboardEvent, fcn: string, controlid: string, mmtype: string, dateval?: Date | undefined) {
    this.utilityService.OnkeyupCalendar(event, fcn, this.MSLRequestForm, controlid, mmtype, dateval);
  }
  
  MinMaxDateValidation(event: any, fcn: string, MinMaxFlag?: string, MinMaxDateVal?: Date) {
    this.utilityService.ValidateDate(event.target.value, fcn, MinMaxFlag, MinMaxDateVal, this.MSLRequestForm);
  }
}
