<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block" id="exampleModalLabel">Login
      <a class="fs-12 text-underline float-right pt-1 text-white" href="javascript:void(0)" (click)="close()" data-dismiss="modal"><i class="fas fa-times"></i></a>
      <a class="fs-12 text-underline float-right pt-1 pr-3" href="javascript:void(0)" (click)="openForgotPassword()">Forgot your Password?</a>
    </h5>
  </div>
  <div class="modal-body">
    <form [formGroup]="SubmitLoginForm">
      <p class="text-right text-secondary fs-12 mandatory d-inline-block float-right">Required Fields.</p>
      <div class="form-group">
        <label class="fieldLabel mandatory" for="pwdName">User ID/Email Address</label>
        <input type="text" id="pwdName" class="form-control" formControlName="USER_ID" autocomplete="off" 
        placeholder="Please enter User ID or Registered Email Address" maxlength="100"
        (blur)="handleInput($event.target,'USER_ID');" 
        [ngClass]="{ 'is-invalid': (form.USER_ID.dirty || form.USER_ID.touched || submitted) && form.USER_ID.errors }">
        <div *ngIf="(form.USER_ID.dirty || form.USER_ID.touched || submitted) && form.USER_ID.invalid" class="invalid-form-control text-danger fs-12">
          <div *ngIf="form.USER_ID.errors?.['required']">
            User ID or Email Address is required.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="fieldLabel mandatory" for="pwdl">Password</label>
        <input type="password" id="pwdl" class="form-control" formControlName="PASSWORD" autocomplete="off" 
        placeholder="Please enter Password" maxlength="100"
        [ngClass]="{ 'is-invalid': (form.PASSWORD.dirty || form.PASSWORD.touched || submitted) && form.PASSWORD.errors }">
        <div *ngIf="(form.PASSWORD.dirty || form.PASSWORD.touched || submitted) && form.PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
          <div *ngIf="form.PASSWORD.errors?.['required']">
            Password is required.
          </div>
        </div>
      </div>
      <div *ngIf="invalidCredentials && (form.USER_ID.errors||form.PASSWORD.errors)" class="invalid-form-control text-danger fs-12">
        Invalid User ID/Email Address or Password.
      </div>
      <!-- <div *ngIf="submitted && noAccountFound" class="invalid-form-control text-danger fs-12">
        No account is found with the credentials you entered, create a new account.
      </div> -->
      <div *ngIf="submitted && passwordExpired" class="invalid-form-control text-danger fs-12">
        Your password has expired. Please click Forgot Password to Reset Password.
      </div>
      <div *ngIf = "submitted && userDeactivated">
        You have requested for deactivation of your account on {{this.deactivatedDate | date:'dd-MMM-YYYY'}}. Please click on <a href="javascript:void(0)"
          (click)="openVerificationPopup(userLoginResult)">Verify</a> to reactivate your account for login.
      </div>
      <div class="text-center pt-2">
        <!-- <span tooltip="{{this.enableLogin ? '': 'Please provide UserID/ EmailID and Password to Login'}}"> -->
          <button type="button" class="btn btn-primary btn-block text-center px-3 fs-14"
          [disabled]="!this.enableLogin" (click)="login()" >LOGIN</button>
        <!-- </span> -->
      </div>
      <!-- <div class="">
        <a class="fs-12 text-underline float-right pt-1" href="javascript:void(0)" (click)="this.openForgotPassword()">Forgot your Password?</a>
      </div> -->
      <div class="fs-13 pt-3 text-center clearfix">Don't have an account?
        <a (click)="this.openRegisterForm()" href="javascript:void(0)">Click here to Register</a>
      </div>
    </form>
  </div>
</div>
