import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUserInfo } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { NewsAnnouncementsService } from 'src/app/_services/news-announcements.service';

@Component({
  selector: 'app-news-unsubscribe',
  templateUrl: './news-unsubscribe.component.html',
  styleUrls: ['./news-unsubscribe.component.css']
})
export class NewsUnsubscribeComponent implements OnInit {

  inValidLink: boolean = false;
  unsubscribeSuccess: boolean | null = null;
  alreadyUnsubscribed: boolean | null = null;
  isLoggedInUser: boolean;

  constructor(private newsAnnouncementsService: NewsAnnouncementsService,
    public authServices: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {    
    const email_id = this.route.snapshot.queryParams['eid'];
    const pk_event_subscriber_id = this.route.snapshot.queryParams['sid'];
    if(email_id == undefined || pk_event_subscriber_id == undefined)
    {
      this.inValidLink = true;
    }
    else
    {
     // remove eid and sid from url to prevent http referer leakage
     this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

     this.isLoggedInUser = this.authServices.isUserLoggedIn();

     this.newsAnnouncementsService.unsubscribe(pk_event_subscriber_id, email_id).subscribe(res => {
      if (res.Status) {
        this.unsubscribeSuccess = true;
        this.inValidLink = false;
        if (this.isLoggedInUser) {
          let objUserInfo = localStorage.getItem('cpappuserinfo');
          if (objUserInfo)
          {
            let appUserInfo: AppUserInfo = JSON.parse(objUserInfo);
            appUserInfo.IsSubscribedToNews = false;
            localStorage.removeItem('cpappuserinfo');
            localStorage.setItem('cpappuserinfo', JSON.stringify(appUserInfo));
          }
        }
      }
      else
      {
        this.unsubscribeSuccess = false;
        this.inValidLink = true;
      }
     });
    }
  }

}
