export interface BookmarksAddEditDTO {
    BookmarkID: string;
    EnvironmentID?: string;        
    PortalID: string;
    PortalCode: string;
    IntegrationType: string;    
    DocVersionID: string;       
    BookmarkVersionID: string;  
    DocumentID: string;
    Name: string;
    FileName: string;          
    FileExt: string;           
    Md5checksum: string;       
    DocumentstoreID: string;
    //Filebytes: Byte[];
    Version?: string;
    MajorVersion?: number;
    MinorVersion?: number;
    ProductMappingType: string;
    UserID?: string;
  }
  
  export interface BookmarksEditDTO {
    EnvironmentID?: string;
    PortalID: string;
    BookmarkID: string;
    DocumentID: string;
  }
  
  export class SearchBookmarkDTO {
    EnvironmentID?: string;
    PortalID?: string;
    ProductID?: string;
    UserID?: string;
  }
  
  export interface BookmarksVM {
    BookmarkID: string;
    DocumentID: string;
    DocStoreID: string;
    LatestVersion: string;
    BookmarkedVersion: string;
    IsArchived: boolean;
    Name: string;
    Filename: string;
    Extn: string;
    LastModifiedDate: Date;
    Md5checksum: string;
    FileType: string;
  }
  