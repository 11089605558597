export enum FileStoreType {
  TEMP = 'TEMP',
  DOC = 'DOC',
  FILE = 'FILE'
}

export enum ComponentType {
  SUBMIT_REQUEST = 'SUBMIT_REQUEST',
  MSL_REQUEST = 'MSL_REQUEST',
  CONTENT_INFORMATION = 'CONTENT_INFORMATION',
  OUR_PRODUCTS = 'OUR_PRODUCTS',
  OUR_PRODUCTS_PRODUCTS = 'OUR_PRODUCTS_PRODUCTS'
}

export enum ContentInformationResponseType {
  Document = 0,
  FAQ
}

export enum FileIconType {
  IMAGE = "IMAGE",
  PDF = "PDF",
  WORD = "WORD",
  HTML = "HTML",
  PPT = "PPT",
  EXEL = "EXEL",
  VIDEO = "VIDEO",
  OTHER = "OTHER"
}

export enum MAPPING_TYPE {
  HYBRID_MIQ = "HYBRID_MIQ",
  HYBRID_MIQ_VAULT = "HYBRID_MIQ_VAULT",
  MIQ_ONLY = "MIQ_ONLY"
}


export class RegistrationErrors {
  public static EmailIdExists = "Email Id already exists";
  public static UserIdExists = "User Id already exists";
  public static LicenseNumberExists = "License number already exists";
  public static RegisteredAlreadyVerified = "Registered User Already Verified";
  public static RegisteredNotVerified = "Registered User Not Verified";
  public static RegisteredDeactivated = "Registered User Deactivated";
  public static InvalidCredentials = "Invalid User Name or Password";
  public static PasswordExpired = "Password Expired";
  public static NoAccountFound = "No Account Found";  
  public static ExistsInLast3Passwords = "Exists In Last 3 Passwords";
  public static InvalidCurrentPassword = "Invalid Password";
}

export class CodeVerificationErrors {
  public static InValidCode = "InValid Code";
  public static InValidLink = "InValid Link";
  public static CodeExpired = "Code Expired";
  public static AlreadyVerified = "User Already Verified";
  public static EmailExists = "Registered User Not Verified";
}

export class Constants {
  public static ForgotPassword = "ForgotPassword";
}

export class RequestTypes {
  public static Call = "Call";
  public static Meeting = "Meeting";
  public static AdverseEvent = "Adverse Event";
  public static ProductComplaint = "Product Complaint";
  public static MedicalInformation = "Medical Information";
}

