import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppUserInfo } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { OurProductsService } from '../../_services/our-products.service';
import { RequestService } from '../../_services/request.service';
import { AlertifyService } from '../../_services/alertify.service';
import { PaginationService, Page } from '../../_services/pagination.service'
import { ComponentType, FileIconType, FileStoreType, RequestTypes } from '../../_enums/constant-types.enum';
import { RequestModel } from 'src/app/_models/request-model';
import { ViewEditMeetingRequestComponent } from './view-edit-meeting-request/view-edit-meeting-request.component';
import { ViewEditCallRequestComponent } from './view-edit-call-request/view-edit-call-request.component';
import { ViewEditAeRequestComponent } from './view-edit-ae-request/view-edit-ae-request.component';
import { ViewEditPcRequestComponent } from './view-edit-pc-request/view-edit-pc-request.component';
import { ViewEditMiRequestComponent } from './view-edit-mi-request/view-edit-mi-request.component';
import { SafeUrl, Title } from '@angular/platform-browser';
import { BookmarksService } from '../../_services/bookmark.service';
import { MedicalInfoService } from '../../_services/medical-info.service';
import { UtilityService } from '../../_services/utility.service';
import { ViewAckComponent } from './view-ack/view-ack.component';
import { Helper } from 'src/app/_models/helper';
import * as moment from 'moment';
import { MyRequestSearch, MyRequest, MyRequestTypes, MyProductTypes } from '../../_models/request-model';
import { BookmarksAddEditDTO, BookmarksVM } from '../../_models/bookmarks'
import { CalendarModule } from 'primeng/calendar';
@Component({
  selector: 'app-user-requests',
  templateUrl: './user-requests.component.html',
  styleUrls: ['./user-requests.component.css']
})
export class UserRequestsComponent implements OnInit {
  pageName: string = ComponentType.CONTENT_INFORMATION;
  MyRequestForm: FormGroup;
  isLoggedIn: boolean = false;
  hcpDetails: AppUserInfo;
  fullName: string;
  bsConfig: any;
  SearchCriteria: MyRequestSearch;
  mysubmittedrequestsCount: number = 0;
  mydraftsrequestsCount: number = 0;
  mysubmittedrequests: MyRequest[] = [];
  mydraftsrequests: MyRequest[] = [];
  private subscribeDisplayProducts: Subscription | null = null;
  private subscribeDisplayRequestTypes: Subscription | null = null;
  private subscribeSearchInformation: Subscription | null = null;
  page: Page;
  tab: string = "S";
  deleteRequestId: string;
  bookmarkscount: number = 0;
  submittedrequestTypes: MyRequestTypes[] = [];
  draftrequestTypes: MyRequestTypes[] = [];
  submittedProducts: MyProductTypes[] = [];
  draftsProducts: MyProductTypes[] = [];

  userbookmarks: BookmarksVM[] = [];
  addeditbookmarks: BookmarksAddEditDTO;
  requestdoc: any;
  Ismiq: boolean = false;
  prodmaptype: string;
  datevalidationmsg: string = "";
  doc: FileStoreType;
  pdfDownloadURL: SafeUrl | null = null;

  isAsc = true;
  headerKey: string;
  columnDefs: any;
  rowData: any = [];

  maxDate: Date = new Date();
  minDate: Date = new Date();
  constructor(private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private ourProductsService: OurProductsService,
    private requestService: RequestService,
    private alertify: AlertifyService,
    private router: Router,
    public pagination: PaginationService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private titleService: Title,
    private bookmarksService: BookmarksService,
    private medInfoService: MedicalInfoService,
    public utilityService: UtilityService) {
    titleService.setTitle('My Requests');
    this.page = this.pagination.setPagedRecords([], null);
    this.pagination.pageSize = 10;
    this.page.pager.itemsFrom = 0;
    this.page.pager.itemsTo = 0;
    this.page.pager.totalItems = 0;
    this.doc = FileStoreType.DOC;
  }

  ngOnInit(): void {
    this.setFormGroup();
    this.isLoggedIn = this.authService.isUserLoggedIn();
    if (!this.isLoggedIn) {
      this.router.navigate(['/']);
      return;
    }

    this.getProducts();
    this.getRequestTypes();
    this.hcpDetails = this.authService.getLoginHCPDetails();
    this.prodmaptype = this.utilityService.getProductsMappingType();
    if (this.prodmaptype) {
      if (this.prodmaptype == "HYBRID_MIQ" || this.prodmaptype == "CP_MIQ") {
        this.Ismiq = true;
      }
      else {
        this.Ismiq = false;
      }
    }

    this.getRequests();

    if (this.authService.isCookie()) {
      this.authService.cookieTracking().subscribe();
    }
  }

  //get minDate() {
  //  let date: Date = new Date();
  //  return date;
  //}

  //get maxDate() {
  //  let date: Date = new Date();
  //  return date;
  //}

  Ondatechange(value: Date, fcn: string) {
    this.utilityService.resetCalendar(value, fcn, this.MyRequestForm)
  }

  getLocalDate(date: any) {
    return Helper.ISODateReviver(date);
  }

  getRequests() {
    if (this.tab == "S") {
      this.mysubmittedrequestsCount = 0;
      this.mysubmittedrequests = [];
      this.SearchCriteria = {
        RequestID: this.MyRequestForm.get('SFCN_RequestID')?.value == null ? null : this.MyRequestForm.get('SFCN_RequestID')?.value,
        ProductID: this.MyRequestForm.get('SFCN_Product')?.value,
        ReportRequestTypeID: this.MyRequestForm.get('SFCN_RequestType')?.value,
        StartDate: this.MyRequestForm.get('SFCN_StartDate')?.value == null ? null : this.MyRequestForm.get('SFCN_StartDate')?.value,
        EndDate: this.MyRequestForm.get('SFCN_EndDate')?.value == null ? null : this.MyRequestForm.get('SFCN_EndDate')?.value,
      };
    }
    else if (this.tab == "D") {
      this.mydraftsrequestsCount = 0;
      this.mydraftsrequests = [];
      this.SearchCriteria = {
        RequestID: this.MyRequestForm.get('DFCN_RequestID')?.value == null ? null : this.MyRequestForm.get('DFCN_RequestID')?.value,
        ProductID: this.MyRequestForm.get('DFCN_Product')?.value,
        ReportRequestTypeID: this.MyRequestForm.get('DFCN_RequestType')?.value,
        StartDate: this.MyRequestForm.get('DFCN_StartDate')?.value == null ? null : this.MyRequestForm.get('DFCN_StartDate')?.value,
        EndDate: this.MyRequestForm.get('DFCN_EndDate')?.value == null ? null : this.MyRequestForm.get('DFCN_EndDate')?.value,
      };
    }
    this.subscribeSearchInformation = this.requestService.GetMyRequests(this.SearchCriteria).subscribe(next => {
      if (next.length > 0) {
        this.mysubmittedrequests = next.filter(n => n.Status != "DRAFT");
        this.mydraftsrequests = next.filter(n => n.Status == "DRAFT");
        this.mysubmittedrequestsCount = this.mysubmittedrequests.length;
        this.mydraftsrequestsCount = this.mydraftsrequests.length;
        this.setPagination();
        this.sort('LastUpdatedOn', false);
      }
      else {
        this.mysubmittedrequests = [];
        this.mydraftsrequests = [];
        this.mysubmittedrequestsCount = 0;
        this.mydraftsrequestsCount = 0;
        this.PaginationSet();
      }
    },
      err => {
        this.alertify.errorBaner(err);
      }
    );
  }

  get enableSubmitClearandSearch() {
    let isValid = true;
    if (!this.MyRequestForm.controls["SFCN_RequestID"].value && this.MyRequestForm.controls["SFCN_Product"].value == ""
      && this.MyRequestForm.controls["SFCN_RequestType"].value == "" && !this.MyRequestForm.controls["SFCN_StartDate"].value
      && !this.MyRequestForm.controls["SFCN_EndDate"].value) {
      isValid = false;
    }
    return isValid;
  }

  get enableDraftClearandSearch() {
    let isValid = true;
    if (!this.MyRequestForm.controls["DFCN_RequestID"].value && this.MyRequestForm.controls["DFCN_Product"].value == ""
      && this.MyRequestForm.controls["DFCN_RequestType"].value == "" && !this.MyRequestForm.controls["DFCN_StartDate"].value
      && !this.MyRequestForm.controls["DFCN_EndDate"].value) {
      isValid = false;
    }
    return isValid;
  }

  setFormGroup() {
    this.bsConfig = Object.assign({}, {
      containerClass: "theme-blue",
      dateInputFormat: 'DD-MMM-YYYY',
      showWeekNumbers: false,
      maxDate: this.minDate
    });
    this.MyRequestForm = this.formBuilder.group({
      SFCN_RequestID: [null, null],
      SFCN_Product: ["", null],
      SFCN_RequestType: ["", null],
      SFCN_StartDate: [null, null],
      SFCN_EndDate: [null, null],

      DFCN_RequestID: [null, null],
      DFCN_Product: ["", null],
      DFCN_RequestType: ["", null],
      DFCN_StartDate: [null, null],
      DFCN_EndDate: [null, null],
    });
  }

  getProducts() {
    var temparry: (string | undefined)[] = [];
    var resultarray: MyProductTypes[] = [];
    this.submittedProducts = [];
    this.draftsProducts = [];
    this.subscribeDisplayProducts = this.requestService.GetMyProductTypes().subscribe(next => {
      if (next.length > 0) {
        this.submittedProducts = next.filter(n => n.Status != "DRAFT").filter(i => i.ProductTypeName);
        for (var i = 0; i <= this.submittedProducts.length-1; i++) {
          if (!temparry.includes(this.submittedProducts[i]["ProductID"])) {
            temparry.push(this.submittedProducts[i]["ProductID"]);
            resultarray.push(this.submittedProducts[i]);
          }
        }
        if (resultarray.length > 0) {
          this.submittedProducts = resultarray;
        }
        this.draftsProducts = next.filter(n => n.Status == "DRAFT");
      }
      else {
        this.submittedProducts = [];
        this.draftsProducts = [];
      }
    },
      error => {
        console.error(error);
      });
  }

  getRequestTypes() {
    let temparry: (number | undefined)[] = [];
    let resultarray: MyRequestTypes[] = [];
    this.submittedrequestTypes = [];
    this.draftrequestTypes = [];
    this.subscribeDisplayRequestTypes = this.requestService.GetMyRequestTypes().subscribe(next => {
      if (next.length > 0) {
        this.submittedrequestTypes = next.filter(n => n.Status != "DRAFT");
        for (let i: number = 0; i <= this.submittedrequestTypes.length-1; i++) {
          if (!temparry.includes(this.submittedrequestTypes[i]["ReporteRequestTypeID"])) {
            temparry.push(this.submittedrequestTypes[i]["ReporteRequestTypeID"]);
            resultarray.push(this.submittedrequestTypes[i]);
          }
        }
        if (resultarray.length > 0) {
          this.submittedrequestTypes = resultarray;
        }
          this.draftrequestTypes = next.filter(n => n.Status == "DRAFT");
      }
      else {
        this.submittedrequestTypes = [];
        this.draftrequestTypes = [];
      }
    },
      error => {
        console.error(error);
      });
  }

  SubmittedTabClick() {
    this.tab = "S";
    this.datevalidationmsg = "";
    this.PaginationSet();
    this.sort('LastUpdatedOn', false);
    this.page = this.pagination.setPagedRecords(this.mysubmittedrequests, null);
  }

  DraftstabClick() {
    this.tab = "D";
    this.datevalidationmsg = "";
    this.PaginationSet();
    this.sort('LastUpdatedOn', false);
    this.page = this.pagination.setPagedRecords(this.mydraftsrequests, null);
  }

  PaginationSet() {
    this.page.pagedRecords = [];
    this.pagination.pageSize = 10;
    this.page.pager.itemsFrom = 0;
    this.page.pager.itemsTo = 0;
    this.page.pager.totalItems = 0;
  }

  SearchClick() {
    this.cleanForm();
    if (this.FormValidation()) {
      if (this.tab == "S") {
        this.SearchCriteria = {
          RequestID: this.MyRequestForm.get('SFCN_RequestID')?.value == null ? null : this.MyRequestForm.get('SFCN_RequestID')?.value,
          ProductID: this.MyRequestForm.get('SFCN_Product')?.value,
          ReportRequestTypeID: this.MyRequestForm.get('SFCN_RequestType')?.value,
          StartDate: !this.MyRequestForm.get('SFCN_StartDate')?.value ? null : this.MyRequestForm.controls['SFCN_StartDate'].value,
          EndDate: !this.MyRequestForm.get('SFCN_EndDate')?.value ? null : this.MyRequestForm.controls['SFCN_EndDate'].value,
        };
        this.subscribeSearchInformation = this.requestService.GetMyRequests(this.SearchCriteria).subscribe(next => {
          if (next.length > 0) {
            this.mysubmittedrequests = next.filter(n => n.Status != "DRAFT");
            //this.mysubmittedrequestsCount = this.mysubmittedrequests.length;
            this.setPagination();
            this.sort('LastUpdatedOn', false);
          }
          else {
            this.mysubmittedrequests = [];
            this.setPagination();
            //this.mysubmittedrequestsCount = this.mysubmittedrequests.length;
            this.page.pager.itemsFrom = 0;
            this.page.pager.itemsTo = 0;
            this.page.pager.totalItems = 0;
            //this.alertify.errorBaner(next.ErrorMessage ?? 'Unknown Error..!');
          }
        },
          err => {
            this.mydraftsrequestsCount = this.mydraftsrequests.length;
            this.alertify.errorBaner(err);
          }
        );
      }
      else if (this.tab == "D") {
        this.SearchCriteria = {
          RequestID: this.MyRequestForm.get('DFCN_RequestID')?.value == null ? null : this.MyRequestForm.get('DFCN_RequestID')?.value,
          ProductID: this.MyRequestForm.get('DFCN_Product')?.value,
          ReportRequestTypeID: this.MyRequestForm.get('DFCN_RequestType')?.value,
          StartDate: this.MyRequestForm.get('DFCN_StartDate')?.value == null ? null : this.MyRequestForm.controls['DFCN_StartDate'].value,
          EndDate: this.MyRequestForm.get('DFCN_EndDate')?.value == null ? null : this.MyRequestForm.controls['DFCN_EndDate'].value,
        };

        this.subscribeSearchInformation = this.requestService.GetMyRequests(this.SearchCriteria).subscribe(next => {
          if (next.length > 0) {
            this.mydraftsrequests = next.filter(n => n.Status == "DRAFT");
            //this.mydraftsrequestsCount = this.mydraftsrequests.length;
            this.setPagination();
            this.sort('LastUpdatedOn', false);
          }
          else {
            this.mydraftsrequests = [];
            this.setPagination();
            //this.mydraftsrequestsCount = this.mydraftsrequests.length;
            this.page.pager.itemsFrom = 0;
            this.page.pager.itemsTo = 0;
            this.page.pager.totalItems = 0;
            //this.alertify.errorBaner(next.ErrorMessage ?? 'Unknown Error..!');
          }
        },
          err => {
            this.mydraftsrequestsCount = this.mydraftsrequests.length;
            this.alertify.errorBaner(err);
          }
        );
      }
    }
  }

  setPagination() {
    if (this.tab == "S") {
      this.page = this.pagination.setPagedRecords(this.mysubmittedrequests, null);
    }
    else if (this.tab == "D") {
      this.page = this.pagination.setPagedRecords(this.mydraftsrequests, null);
    }
    this.sort('LastUpdatedOn', false);
  }

  sort(key: string, isAsc: boolean = true) {
    this.headerKey = key;
    this.pagination.sort(this.page.filteredRecords, key, isAsc);
    this.page = this.pagination.setPagedRecords(this.page.filteredRecords, null);
    this.isAsc = !isAsc;

    if (this.tab == "S") {
      this.headerKey = key;
      if (key == "RequestID") {
        this.page.filteredRecords = this.pagination.sort(this.page.filteredRecords, "ReferenceNumber", isAsc);
      }
      else {
        this.pagination.sort(this.page.filteredRecords, key, isAsc);
      }
      this.page = this.pagination.setPagedRecords(this.mysubmittedrequests, null);
      this.isAsc = !isAsc;
      //this.page = this.pagination.setPagedRecords(this.mysubmittedrequests, null);
    }
    else if (this.tab == "D") {
      this.headerKey = key;
      if (key == "RequestID") {
        this.page.filteredRecords = this.pagination.sort(this.page.filteredRecords, "ReferenceNumber", isAsc);
      }
      else {
        this.pagination.sort(this.page.filteredRecords, key, isAsc);
      }
      this.page = this.pagination.setPagedRecords(this.mydraftsrequests, null);
      this.isAsc = !isAsc;
      //this.page = this.pagination.setPagedRecords(this.mydraftsrequests, null);
    }


  }

  ClearClick() {
    this.MyRequestForm.get('SFCN_RequestID')?.setValue(null);
    this.MyRequestForm.get('SFCN_Product')?.setValue("");
    this.MyRequestForm.get('SFCN_RequestType')?.setValue("");
    this.MyRequestForm.get('SFCN_StartDate')?.setValue(null);
    this.MyRequestForm.get('SFCN_EndDate')?.setValue(null);
    this.MyRequestForm.get('DFCN_RequestID')?.setValue(null);
    this.MyRequestForm.get('DFCN_Product')?.setValue("");
    this.MyRequestForm.get('DFCN_RequestType')?.setValue("");
    this.MyRequestForm.get('DFCN_StartDate')?.setValue(null);
    this.MyRequestForm.get('DFCN_EndDate')?.setValue(null);
    this.datevalidationmsg = "";
    this.page.pagedRecords = [];
    this.pagination.pageSize = 10;
    this.page.pager.itemsFrom = 0;
    this.page.pager.itemsTo = 0;
    this.page.pager.totalItems = 0;

    this.getRequests();
  }

  FormValidation(): boolean {
    let hasValid = true
    this.datevalidationmsg = "";
    if (this.tab == "S") {
      if (this.MyRequestForm.controls['SFCN_StartDate'].value != null && this.MyRequestForm.controls['SFCN_EndDate'].value != null) {
        let startdate_sfcn = new Date(moment(this.MyRequestForm.controls['SFCN_StartDate'].value).format("DD-MMM-YYYY"));
        let enddate_sfcn = new Date(moment(this.MyRequestForm.controls['SFCN_EndDate'].value).format("DD-MMM-YYYY"));
        if (startdate_sfcn > enddate_sfcn) {
          this.datevalidationmsg = "Start date should not be greater than end date.";
          hasValid = false;
        }
      }
    }
    else if (this.tab = "D") {
      if (this.MyRequestForm.controls['DFCN_StartDate'].value != null && this.MyRequestForm.controls['DFCN_EndDate'].value != null) {
        let startdate_dfcn = new Date(moment(this.MyRequestForm.controls['DFCN_StartDate'].value).format("DD-MMM-YYYY"));
        let enddate_dfcn = new Date(moment(this.MyRequestForm.controls['DFCN_EndDate'].value).format("DD-MMM-YYYY"));
        if (startdate_dfcn > enddate_dfcn) {
          this.datevalidationmsg = "Start date should not be greater than end date.";
          hasValid = false;
        }
      }
    }
    return hasValid;
  }

  viewRequest(request: MyRequest, isView: boolean = true) {
    this.requestService.GetRequest(request.PkRequestID).subscribe(next => {
      if (next != undefined && next != null) {
        this.openViewRequestPopup(next, isView);
      }
      else {
        this.alertify.errorBaner('Unknown Error..!');
      }
    },
      err => {
        this.alertify.errorBaner(err);
      }
    );
  }

  deleteRequest(request: MyRequest) {
    this.deleteRequestId = request.PkRequestID;
    let message = "Are you sure you want to delete " + request.ReportRequestTypeName + " request/report " + request.RequestID + " ?"
    this.alertify.ConfirmationBanner(message, this.deleteRequestCallBack, "Confirm");
  }

  deleteRequestCallBack = (result: any) => {
    if (result) {
      this.requestService.deleteRequest(this.deleteRequestId)
        .subscribe(res => {
          this.deleteRequestId = "";
          if (res !== null && res !== undefined) {
            if (res.Status) {
              this.alertify.successBaner("Request deleted");
              this.getProducts();
              this.getRequestTypes();
              this.getRequests();
            }
            else {
              this.alertify.errorBaner(res.ErrorMessage ?? "");
            }
          }
          else {
            this.alertify.errorBaner('Unknown Error..!');
          }
        },
          err => {
            this.alertify.error(err);
          });
    }
  }

  viewAck(request: MyRequest) {
    let options: any = {
      Request: request,
    };
    const initialState: ModalOptions = {
      initialState: <any>options,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false,
    };
    this.modalRef = this.modalService.show(ViewAckComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
  }

  openViewRequestPopup(request: RequestModel, isView: boolean = true) {
    let options: any = {
      Request: request,
      IsView: isView
    };
    const initialState: ModalOptions = {
      initialState: <any>options,
      backdrop: 'static',
      class: 'modal-xl modal-dialog-centered',
      keyboard: false,
    };

    switch (request.REPORT_REQUEST_TYPE) {
      case RequestTypes.Meeting:
        this.modalRef = this.modalService.show(ViewEditMeetingRequestComponent, initialState);
        this.modalRef.content.closeBtnName = 'Close';
        break;
      case RequestTypes.Call:
        this.modalRef = this.modalService.show(ViewEditCallRequestComponent, initialState);
        this.modalRef.content.closeBtnName = 'Close';
        break;
      case RequestTypes.AdverseEvent:
        this.modalRef = this.modalService.show(ViewEditAeRequestComponent, initialState);
        this.modalRef.content.closeBtnName = 'Close';
        break;
      case RequestTypes.ProductComplaint:
        this.modalRef = this.modalService.show(ViewEditPcRequestComponent, initialState);
        this.modalRef.content.closeBtnName = 'Close';
        break;
      case RequestTypes.MedicalInformation:
        this.modalRef = this.modalService.show(ViewEditMiRequestComponent, initialState);
        this.modalRef.content.closeBtnName = 'Close';
        break;
    }
 
    this.modalRef.content.OnSubmit.subscribe((result: boolean) => {
      if (result) {
        this.getProducts();
        this.getRequestTypes();
        this.getRequests();
      }
    });
  }

  BookmarkTabClick() {
    this.GetUserBookmarks();
  }

  GetUserBookmarks() {
    this.userbookmarks = [];
    this.subscribeDisplayRequestTypes = this.bookmarksService.getBookmarks().subscribe(next => {
      if (next.length > 0) {
        this.userbookmarks = next;
        this.bookmarkscount = this.userbookmarks.length;
      }
      else {
        this.userbookmarks = [];
        // this.alertify.errorBaner();
      }
    },
      error => {
        console.error(error);
      });
  }

  UnBookmarkDoc(doc: any) {
    this.requestdoc = doc;
    this.alertify.ConfirmationBanner("This action results in removal of the document from Bookmarks?", this.ConfimationCalBack, "Confirm");
  }

  ConfimationCalBack = (result: any) => {
    if (result) {
      var doc = this.requestdoc;
      this.addeditbookmarks = {
        BookmarkID: doc.BookmarkID == null ? "" : doc.BookmarkID,
        BookmarkVersionID: doc.BookmarkedVersion == null ? "" : doc.BookmarkedVersion,
        DocumentID: doc.DocumentID == null ? "" : doc.DocumentID,
        DocVersionID: doc.LatestVersion == null ? "" : doc.LatestVersion,
        FileExt: doc.Extn == null ? "" : doc.Extn,
        Name: doc.Name == null ? "" : doc.Name,
        FileName: doc.Filename == null ? "" : doc.Filename,
        IntegrationType: this.Ismiq ? "MIQ" : "VEEVA_VAULT",
        Md5checksum: doc.Md5checksum == null ? "" : doc.Md5checksum,
        PortalID: "",
        PortalCode: "",
        EnvironmentID: "",
        Version: doc.Version == null ? "" : doc.Version,
        MajorVersion: doc.MajorVersion,
        MinorVersion: doc.MinorVersion,
        ProductMappingType: this.prodmaptype,
        UserID: "",
        DocumentstoreID: doc.DocStoreID,
      };
      this.bookmarksService.UpdateMyBookmarks(this.addeditbookmarks).subscribe(next => {
        if (next !== null && next !== undefined) {
          if (next.Status) {
            this.GetUserBookmarks();
            this.bookmarkscount = this.userbookmarks.length;
          }
          else {
            this.alertify.errorBaner(next.ErrorMessage);
          }
        }
        else {
          this.alertify.errorBaner(next.ErrorMessage ?? 'Unknown Error..!');
        }
      },
        err => {
          this.alertify.errorBaner(err);
        }
      );
    }
  }

  documentPreview(doc: any) {
    if (doc) {
      if (doc.FileType == FileIconType.HTML || doc.FileType == FileIconType.IMAGE || doc.FileType == FileIconType.PDF) {
        window.open(this.utilityService.getURL(doc.DocStoreID ?? '', FileStoreType.DOC), '_blank');
      }
      else {
        this.pdfDownloadURL = this.utilityService.downloadURL(doc.DocStoreID ?? '', FileStoreType.DOC)
      }
    }
    else {
      console.error('Unknown Error - Cannot find File data.');
    }

    /* window.open(this.utilityService.getURL(doc.DocStoreID ?? '', FileStoreType.DOC));*/
    return false;
    /*{ { this.utilityService.getURL(bm.DocStoreID ?? '', this.doc) } }*/
  }

  GetUtcTime(datetobechange: Date): Date {
    var date = new Date(datetobechange);
    var newdate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
    return newdate
  }

  public cleanForm() {
    let count = 0;
    if (this.MyRequestForm != null && this.MyRequestForm != undefined) {
      Object.keys(this.MyRequestForm.controls).forEach(key => {

        let value: any = this.MyRequestForm.controls[key].value;
        if (this.MyRequestForm.controls[key].value != null && typeof (this.MyRequestForm.controls[key].value) === "string"
          && this.MyRequestForm.controls[key].value !== "") {
          value = this.MyRequestForm.controls[key].value.trim();
        }
        else if (this.MyRequestForm.controls[key].value == "") {
          value = value != "" ? value : "";
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.MyRequestForm.controls[key].setValue(value);
        }

      });
    }
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.MyRequestForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }
    return true;
  }

  keyPress(event: KeyboardEvent, fcn: string, controlid: string, mmtype: string, dateval?: Date | undefined) {
    this.utilityService.OnkeyupCalendar(event, fcn, this.MyRequestForm, controlid, mmtype,dateval);
  }

}
