import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError (err => {

      if(err.status === 401) {
        this.authService.logout();
        location.reload(true);
      }

      if (!environment.production) {
        console.error(err);
      }

      const error = (err && err.error && err.error.message) || err.statusText;    
      return throwError(error);

    }));
  }
}
