import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AppConstants } from '../../shared/AppConstants';
import { COUNTRIES, CountryAutoComplete } from '../_models/countries';
import { CP_VW_LOOKUPS } from '../_models/cpvw-lookups';
import { EnvironmentSetupResponse } from '../_models/environmentSetupResponse';
import { FileStoreParams } from '../_models/file';
import { Occupation, PreferredContact } from '../_models/msl';
import { ZipCode } from '../_models/zipcode';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigService {

  constructor(private http: HttpClient) { }

  getAllCountries(): Observable<COUNTRIES[]> {
    return this.http.get<COUNTRIES[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetAllCountries');
  }

  getCountriesTypeHeadData(): Observable<CountryAutoComplete[]> {

    let countriesData: CountryAutoComplete[] = [];
    return this.getAllCountries().pipe(
      map(x => {
        x.forEach(c => {
          countriesData.push({
            PKCountryId: c.PK_COUNTRY_ID,
            CountryDisplayName: c.NAME ?? '',
            CountryCode: c.CODE,
            IsInValid: false
          });
        });
        return countriesData;
      }),
      shareReplay(1)
    );

  }

  getLookUps(code: string): Observable<CP_VW_LOOKUPS[]> {
    return this.http.get<CP_VW_LOOKUPS[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetLookUps/' + code);
  }

  createEnvironmentSetup(parms: FileStoreParams): Observable<EnvironmentSetupResponse> {
    parms.PortalCode = AppConstants.PortalCode;
    parms.FK_EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<EnvironmentSetupResponse>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig', parms);
  }

  getAllZipCodes(): Observable<ZipCode[]> {
    return this.http.get<ZipCode[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetAllZipCodes/');
  }

  getZipCodes(code: string): Observable<ZipCode[]> {
    return this.http.get<ZipCode[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetZipCodes/' + code);
  }

  getOccupations(): Observable<Occupation[]> {
    return this.http.get<Occupation[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetOccupations/' + AppConstants.PortalCode);
  }
  getPreferredContact(): Observable<PreferredContact[]> {
    return this.http.get<PreferredContact[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetPreferedContacts/' + AppConstants.PortalCode);
  }
}
