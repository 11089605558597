import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/shared/AppConstants';
import { COUNTRIES } from '../_models/countries';
import { CP_VW_LOOKUPS } from '../_models/cpvw-lookups';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SubmitRequestService {
  currentdate: Date = new Date();
constructor(private http: HttpClient,
  private authService: AuthenticationService,
  private datepipe: DatePipe
) { }

  saveRequest(RequestModel: any): Observable<any> {
    RequestModel["PORTAL_CODE"] = AppConstants.PortalCode;
    RequestModel["FK_USER_ID"] = this.authService.user.PK_UserId;
    return this.http.post<any>(AppConstants.PortalApiBaseUrl + '/api/Request/SaveRequest', RequestModel);
  }

  insertSubmitRequest(RequestModel: any): Observable<any> {
    RequestModel["PORTAL_CODE"] = AppConstants.PortalCode;
    RequestModel["FK_USER_ID"] = this.authService.user.PK_UserId;
    return this.http.post<any>(AppConstants.PortalApiBaseUrl + '/api/SubmitRequest/submitRequest', RequestModel);
  }

  syncMIQProducts(): Observable<any> {
    return this.http.get<REQProducts[]>(AppConstants.PortalApiBaseUrl + '/api/Products/GetDisplayProducts/' + AppConstants.PortalCode);
  }
  // getDisplayProducts(): Observable<Products[]> {
  //   return this.http.get<Products[]>(AppConstants.PortalApiBaseUrl + '/api/Products/GetDisplayProducts/' + AppConstants.PortalCode);
  // }

  getCountries(): Observable<COUNTRIES[]> {
    return this.http.get<COUNTRIES[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetAllCountries');
  }

  getLookUps(code: string): Observable<CP_VW_LOOKUPS[]> {
    return this.http.get<CP_VW_LOOKUPS[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetLookUps/' + code);
  }
}

export interface REQProducts {
  PK_ProductMappingId?: string;
  FK_EnvironmentId?: string;
  FK_PortalId?: string;
  FK_MiqProductId?: string;
  FK_MiqFamilyProductId?: string;
  FK_VV_ProductId?: string;
  MiqProductApprovalId?: string;
  MiqTradeName?: string;
  MiqCountryCode?: string;
  ProductDisplayName?: string;
  FormaulationStrenthUnits?: string;
  ProductHelpText?: string;
  ProductPageTitle?: string;
  ProductInitialDiscriptionText?: string;
  ProductSecondaryDiscriptionText?: string;
  ProductSerialNum?: number;
  VV_ProductName?: string;
}

