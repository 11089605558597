import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { PortalComponents } from 'src/app/_models/portals';
import { UserLoginResult } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppConstants } from 'src/shared/AppConstants';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  LoginStatus$: Observable<boolean>;
  UserName$: Observable<string>;
  isUS_HCP: boolean = true;
  enabledMenuItems: any;
  PortalComponents?: PortalComponents[];

  SMI_NAME: string;
  RMI_NAME: string;
  CONG_NAME: string;

  constructor(private authService: AuthenticationService,
    private utilityService: UtilityService,
    private router: Router,
    public modalRef: BsModalRef,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.enabledMenuItems = AppConstants.enabledMenuItems;
    this.LoginStatus$ = this.authService.isLoggedIn;

    if (this.authService.portal && this.authService.portal.PortalComponents) {
      for (const element of this.authService.portal.PortalComponents) {
        const component = element;
        switch (component.Code) {
          case 'CONTENT_INFORMATION':
            this.enabledMenuItems.isSMI = component.Status;
            this.SMI_NAME = component.Name??'';
            break;
          case 'REQUEST_MI':
            this.enabledMenuItems.isRMI = component.Status;
            this.RMI_NAME = component.Name ?? '';
            break;
          case 'REPORT_AE':
            this.enabledMenuItems.isAE = component.Status;
            break;
          case 'REPORT_PC':
            this.enabledMenuItems.isPC = component.Status;
            break;
          case 'MSL_REQUEST':
            this.enabledMenuItems.isMSL = component.Status;
            break;
          case 'EVENTS_AND_CONFERENCES':
            this.enabledMenuItems.isConferences = component.Status;
            break;
          case 'NEWS_AND_ANNOUNCEMENTS':
            this.enabledMenuItems.isNews = component.Status;
            this.CONG_NAME = component.Name??'';
            break;
          case 'OUR_PRODUCTS':
            this.enabledMenuItems.isProducts = component.Status;
            break;
          case 'RESOURCES':
            this.enabledMenuItems.isResources = component.Status;
            break;
        }
      }
    }

    this.UserName$ = this.authService.currentUserName;
    this.utilityService.isUS_HCP_Data.subscribe((res) => {
      this.isUS_HCP = res;
    });
  }

  navigateToUserProfile() {
    this.router.navigateByUrl('/my-profile');
  }
  navigateToBookmarkedContent() {
    this.router.navigateByUrl('/bookmarked-content');
  }

  logout() {
    this.authService.logout();
    this.authService.loginStatus.next(false);
    this.authService.UserName.next("");
    this.router.navigate(['/'])
      .then(() => {
        window.location.reload();
      });
  }

  navigateToUserProfileComponent() {
    this.router.navigate(['/my-profile']);
  }

  login() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(LoginComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onLogin.subscribe((result: UserLoginResult) => {
      if (result != null) {
        this.authService.loginStatus.next(true);
        this.authService.UserName.next(result.UserFullName ?? "");
        this.authService.hcpDetails.next(this.utilityService.getAppUserInfoObject(result));
      }
    });
  }

  register() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-xl modal-dialog-centered',
      keyboard: false,
    };
    this.modalRef = this.modalService.show(RegisterComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
  }

  openContactModal(template: TemplateRef<any>) {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(template, initialState);
  }

}
