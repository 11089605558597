import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PASSWORD_POLICY } from 'src/app/_models/password-policy';

@Component({
  selector: 'app-password-policy',
  templateUrl: './password-policy.component.html',
  styleUrls: ['./password-policy.component.css']
})
export class PasswordPolicyComponent implements OnInit {
  public passwordPolicy: PASSWORD_POLICY;
  config: any = '';
  special_chars: string = "! # $ % & (  ) * + - / @  [ ] ^ _ { } ~";

  constructor(private bsModalRef: BsModalRef,
    public options: ModalOptions) { 
      
    }

  ngOnInit(): void {
    this.passwordPolicy = this.options.initialState as PASSWORD_POLICY;
  }

  close()
  {
    this.bsModalRef.hide();
  }

}
