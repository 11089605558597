import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { RegistrationErrors } from 'src/app/_enums/constant-types.enum';
import { CountryAutoComplete } from 'src/app/_models/countries';
import { CP_VW_LOOKUPS } from 'src/app/_models/cpvw-lookups';
import { Occupation, PreferredContact } from 'src/app/_models/msl';
import { AppUserInfo, UserParams } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SystemConfigService } from 'src/app/_services/system-config.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { UpdateEmailComponent } from './update-email/update-email.component';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  @ViewChild('staticModal', { static: false }) bsModalChild!: ModalDirective;
  @ViewChild('pwdModal', { static: false }) pwdModalChild!: ModalDirective;

  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  isLoggedIn: boolean = false;
  hcpDetails: AppUserInfo;
  fullName: string;
  userParams: UserParams = {};
  allCountries: CountryAutoComplete[] = [];
  preferedContacts: PreferredContact[] = [];
  occupationList: Occupation[] = [];
  specialityList: CP_VW_LOOKUPS[] = [];
  private subscribeDisplayCountries: Subscription | null = null;
  userCountry: string | '' = '';
  preferredContactMethod: string | '' = '';
  userProfession: string | '' = '';
  userSpeciality: string | '' = '';

  constructor(private formBuilder: FormBuilder,
    private configService: SystemConfigService,
    private authService: AuthenticationService,
    public utilityService: UtilityService,
    private alertifyService: AlertifyService,
    public modalRef: BsModalRef,
    public passwordPolicyModal: BsModalRef,
    private modalService: BsModalService,
    public zipCodeModal: BsModalRef,
    private router: Router,
    private titleService: Title) {
    titleService.setTitle('My Profile');

    this.authService.hcpDetails.subscribe(next => {
      if (this.authService.isUserLoggedIn()) {
        this.hcpDetails = next;
      }
    });

  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isUserLoggedIn();
    if (!this.isLoggedIn) {
      this.router.navigate(['/']);
      return;
    }

    this.hcpDetails = this.authService.getLoginHCPDetails();
    this.userParams.PK_USER_ID = this.hcpDetails.PK_UserId ?? "";
    this.userParams.EMAIL_ID = this.hcpDetails.EmailID ?? "";
    this.fullName = (this.hcpDetails.FIRST_NAME + " " + this.hcpDetails.LAST_NAME).trim();

    this.loginForm = this.formBuilder.group({
      curPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(8), Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/)]],
      confPassword: ['', Validators.required]
    }, {
      validator: this.MustMatch('newPassword', 'confPassword')
    });


    this.getAllCountries();
    this.loadLookUps();

    if (this.authService.isCookie()) {
      this.authService.cookieTracking().subscribe();
    }
  }

  openModalPopUp() {
    this.bsModalChild.show();
  }

  passwordModalPopUp() {
    this.pwdModalChild.show();
  }

  get lf() { return this.loginForm.controls; }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
  }

  deactivateAccount() {
    let title = "Deactivate Account";
    let message = "Do you want to deactivate your account?";
    this.alertifyService.ExternalLinkConfirmationBanner(title, message, "", this.deactivateAccountCallBack, "Confirm");
  }

  deactivateAccountCallBack = (result: any) => {
    if (result) {
      let userParams: UserParams = {
        PK_USER_ID: this.hcpDetails.PK_UserId
      };
      this.authService.accountDeactivation(userParams).subscribe(next => {
        if (next.Errors && next.Errors.length > 0) {
          next.Errors.forEach(error => {
            switch (error) {
              case RegistrationErrors.NoAccountFound:
                var errors: any = [];
                errors.push("No account exists with this Email Address. Please enter a valid registered Email Address or click Register to continue login.");
                this.alertifyService.errorBaner(errors);
                break;
            }
          });
        }
        else if (next.Status) {
          this.alertifyService.successBaner("Your account deactivation request is initiated, you will receive an email to your registered Email Address upon process completion.");
          this.authService.logout();
          this.authService.loginStatus.next(false);
          this.authService.UserName.next("");
          this.router.navigate(['/']);
        }
      },
        error => {
          console.error(error);
        });
    }
  }

  changePassword() {
    this.close();
    this.userParams.Referrer = "Change Password";
    const config: ModalOptions = {
      initialState: <any>this.userParams,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(ChangePasswordComponent, config);
    this.modalRef.content.closeBtnName = 'Close';
  }

  editProfile() {
    const config: ModalOptions = {
      initialState: <any>this.hcpDetails,
      backdrop: 'static',
      class: 'modal-xl modal-dialog-centered',
      keyboard: false,
    };

    this.modalRef = this.modalService.show(EditProfileComponent, config);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onSuccess.subscribe((result: boolean) => {
      if (result) {
        this.fullName = (this.hcpDetails.FIRST_NAME + " " + this.hcpDetails.LAST_NAME).trim();

        let country = this.allCountries.find(c => c.PKCountryId == this.hcpDetails.FK_COUNTRY_ID);
        if (country != undefined) {
          this.userCountry = country.CountryDisplayName;
        }

        let preferredContact = this.preferedContacts.find(p => p.Id == this.hcpDetails.FK_PREFERRED_CONTACT_ID);
        if (preferredContact != undefined) {
          this.preferredContactMethod = preferredContact.ContactName ?? "";
        }

        let profession = this.occupationList.find(p => p.Id == this.hcpDetails.FK_PROFESSION_ID);
        if (profession != undefined) {
          this.userProfession = profession.Name ?? "";
        }

        if(this.hcpDetails.FK_SPECAILITY_ID == null) {
          this.userSpeciality = "";
        } else {
          let speciality = this.specialityList.find(p => p.PK_LOOKUP_ID == this.hcpDetails.FK_SPECAILITY_ID);
          if (speciality != undefined) {
            this.userSpeciality = speciality.NAME ?? "";
          }
        }
        
      }
    });
  }

  changeEmail() {
    const config: ModalOptions = {
      initialState: <any>this.userParams,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(UpdateEmailComponent, config);
    this.modalRef.content.closeBtnName = 'Close';
  }

  close() {
    this.modalRef.hide();
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  loadLookUps() {
    this.getPreferedContacts();
    this.getOccupations();
    this.getSpeciality();
  }

  getAllCountries() {
    this.subscribeDisplayCountries = this.configService.getCountriesTypeHeadData().subscribe(next => {
      this.allCountries = next;
      let country = this.allCountries.find(c => c.PKCountryId == this.hcpDetails.FK_COUNTRY_ID);
      if (country != undefined) {
        this.userCountry = country.CountryDisplayName;
      }
    },
      error => {
        console.error(error);
      });
  }

  getPreferedContacts() {
    this.configService.getPreferredContact().subscribe(next => {
      this.preferedContacts = next;
      let preferredContact = this.preferedContacts.find(p => p.Id == this.hcpDetails.FK_PREFERRED_CONTACT_ID);
      if (preferredContact != undefined) {
        this.preferredContactMethod = preferredContact.ContactName ?? "";
      }
    },
    error => {
      console.error(error);
    });
  }

  getOccupations() {
    this.configService.getOccupations().subscribe(next => {
      this.occupationList = next;
      let profession = this.occupationList.find(p => p.Id == this.hcpDetails.FK_PROFESSION_ID);
      if (profession != undefined) {
        this.userProfession = profession.Name ?? "";
      }
    },
      error => {
        console.error(error);
      });
  }

  getSpeciality() {
    this.configService.getLookUps('SPECIALITY').subscribe(next => {
      this.specialityList = next;
      let speciality = this.specialityList.find(p => p.PK_LOOKUP_ID == this.hcpDetails.FK_SPECAILITY_ID);
      if (speciality != undefined) {
        this.userSpeciality = speciality.NAME ?? "";
      }
    },
      error => {
        console.error(error);
      });
  }
}
