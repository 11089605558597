<div class="pageContent bodyBg py-3">
  <form [formGroup]="MyRequestForm">
    <div class="row">
      <div class="col-md-12">
        <div class="card px-3 py-2 mb-3">
          <div class="defaultTabs">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="title-lg text-primary">
                    <i class="fas fa-exclamation-triangle fs-20 opacity-2"></i>&nbsp;My Requests
                  </div>
                  <p class="fs-12 pt-2 pb-3">Manage all your requests and bookmarks in one place.</p>
                </div>
              </div>
            </div>
            <ul class="nav nav-tabs myrequestTabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="MYREQ-tab" data-toggle="tab" href="#MYREQ" role="tab" aria-controls="home" aria-selected="true">REQUESTS</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="Bookmark-tab" data-toggle="tab" href="#Bookmark" role="tab" (click)="BookmarkTabClick();" aria-controls="profile" aria-selected="false">BOOKMARKS</a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">

              <div class="tab-pane fade show active" id="MYREQ" role="tabpanel" aria-labelledby="MYREQ-tab">
                <div class="container-fluid px-0">
                  <div class="tabsContainer border p-2">
                    <p class="fs-13"> Find records using the search criteria below.</p>
                    <div class="row pt-3">
                      <div class="col-md-12">
                        <ul class="nav nav-tabs myrequestTabs" role="tablist">
                          <li class="nav-item">
                            <a class="nav-link active" (click)="SubmittedTabClick()" id="submitted-tab" data-toggle="tab" href="#submitted" role="tab" aria-controls="home" aria-selected="true">Submitted ({{this.mysubmittedrequestsCount}})</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" (click)="DraftstabClick()" id="drafts-tab" data-toggle="tab" href="#drafts" role="tab" aria-controls="profile" aria-selected="false">Drafts ({{this.mydraftsrequestsCount}})</a>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div class="tab-pane fade show active" id="submitted" role="tabpanel" aria-labelledby="submitted-tab">
                            <div class="container-fluid">
                              <div class="row pt-2">

                                <div class="col-lg-2 col-md-4 col-sm-6">
                                  <div class="form-group">
                                    <label class="fieldLabel">Request ID</label>
                                    <input type="text" class="form-control" (blur)="handleInput($event.target,'SFCN_RequestID');" formControlName="SFCN_RequestID" autocomplete="off">
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6">
                                  <div class="form-group">
                                    <label class="fieldLabel">Request Type</label>
                                    <select class="form-control" formControlName="SFCN_RequestType">
                                      <option value="">-Select-</option>
                                      <option *ngFor="let rt of submittedrequestTypes" value="{{rt.ReporteRequestTypeID}}">{{rt.ReportRequestTypeName}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6">
                                  <div class="form-group">
                                    <label class="fieldLabel">Product</label>
                                    <select class="form-control" formControlName="SFCN_Product">
                                      <option value="">-Select-</option>
                                      <option *ngFor="let prod of submittedProducts" value="{{prod.ProductID}}">{{prod.ProductTypeName}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6">
                                  <div class="form-group">
                                    <label class="fieldLabel">Start Date</label>
                                    <div>
                                      <!--<input type="text" id="submitted-sdt" class="form-control" required="" #datepickerSD="bsDatepicker" max="maxDate"
                                             formControlName="SFCN_StartDate" autocomplete="off" maxlength="50" [bsConfig]="bsConfig"
                                             placeholder="DD-MMM-YYYY" (bsValueChange)="Ondatechange($event,'SFCN_StartDate')"
                                             (keyup)="keyPress($event,'SFCN_StartDate','submitted-sdt','G',maxDate)"
                                             bsDatepicker />
                                      <span (click)="datepickerSD.toggle()"><i class="far fa-calendar-alt"></i></span>-->

                                      <p-calendar inputId="submitted-sdt"
                                                  [maxDate]="maxDate"
                                                  placeholder="DD-MMM-YYYY"
                                                  formControlName="SFCN_StartDate"
                                                  appendTo="body"
                                                  dateFormat="dd-M-yy"
                                                  [showIcon]="true"
                                                  icon="far fa-calendar-alt"
                                                  styleClass="">
                                      </p-calendar>

                                      <div *ngIf="datevalidationmsg" class="invalid-form-control text-danger fs-12">
                                        {{datevalidationmsg}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6">
                                  <div class="form-group">
                                    <label class="fieldLabel">End Date</label>
                                    <div>
                                      <!--<input type="text" id="submitted-edt" class="form-control" required="" #datepickerED="bsDatepicker"
                                             formControlName="SFCN_EndDate" autocomplete="off" maxlength="50" [bsConfig]="bsConfig"
                                             placeholder="DD-MMM-YYYY" (bsValueChange)="Ondatechange($event,'SFCN_EndDate')"
                                             (keyup)="keyPress($event,'SFCN_EndDate','submitted-edt','G',maxDate)"
                                             bsDatepicker />
                                      <span (click)="datepickerED.toggle()"><i class="far fa-calendar-alt"></i></span>-->

                                      <p-calendar inputId="submitted-edt"
                                                  [maxDate]="maxDate"
                                                  placeholder="DD-MMM-YYYY"
                                                  formControlName="SFCN_EndDate"
                                                  appendTo="body"
                                                  dateFormat="dd-M-yy"
                                                  [showIcon]="true"
                                                  icon="far fa-calendar-alt"
                                                  styleClass="">
                                      </p-calendar>

                                    </div>
                                  </div>
                                </div>


                                <div class="col-lg-2 col-md-4 col-sm-6 text-right pt-4" ngPreserveWhitespaces>
                                  <button type="button" class="btn btn-outline-primary" [disabled]="!enableSubmitClearandSearch" (click)="ClearClick();">Clear</button>
                                  <button type="button" class="btn btn-primary" [disabled]="!enableSubmitClearandSearch" (click)="SearchClick();">Search</button>
                                </div>

                                <div class="col-md-12 col-sm-12 px-0 pt-2">
                                  <div class="row">
                                    <div class="col pagination-lft">
                                      <div class="shwng-items pr-4">
                                        <span>Showing {{page.pager.itemsFrom}}-{{page.pager.itemsTo}} of {{page.pager.totalItems}} Items</span>
                                      </div>
                                    </div>
                                    <div class="col pagination-rht d-flex justify-content-end">
                                      <div class="pagination-btns">
                                        <button class="px-2 imgbutton" title="Previous" [disabled]="page.pager.isPrev" (click)="pagination.setPage(page, (page.pager.currentPage - 1))"><i class="fas fa-caret-left"></i></button>
                                        <button class="px-2 imgbutton" title="Next" [disabled]="page.pager.isNext" (click)="pagination.setPage(page, (page.pager.currentPage + 1))"><i class="fas fa-caret-right"></i></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 px-0">
                                  <div class="table grid-4">
                                    <div class="theader">
                                      <div class="table_header pointer" (click)="sort('RequestID',isAsc)">Request ID<i class="fas" *ngIf="headerKey=='RequestID'" [ngClass]="{ 'fa-sort-down':(isAsc),'fa-sort-up':(!isAsc) }"></i></div>
                                      <div class="table_header pointer" (click)="sort('ReportRequestTypeName',isAsc)">Request Type<i class="fas" *ngIf="headerKey=='ReportRequestTypeName'" [ngClass]="{ 'fa-sort-down':(isAsc),'fa-sort-up':(!isAsc) }"></i></div>
                                      <div class="table_header pointer" (click)="sort('ProductName',isAsc)">Product<i class="fas" *ngIf="headerKey=='ProductName'" [ngClass]="{ 'fa-sort-down':(isAsc),'fa-sort-up':(!isAsc) }"></i></div>
                                      <div class="table_header pointer" (click)="sort('LastUpdatedOn',isAsc)">Submitted On<i class="fas" *ngIf="headerKey=='LastUpdatedOn'" [ngClass]="{ 'fa-sort-down':(isAsc),'fa-sort-up':(!isAsc) }"></i></div>
                                      <div class="table_header">Acknowledgement</div>
                                    </div>
                                    <div *ngIf="!(mysubmittedrequests.length > 0)">
                                      <p class="fs-12 pt-2 pb-3">
                                        No Records found
                                      </p>
                                    </div>
                                    <div class="table-body-wrap" *ngFor="let req of page.pagedRecords">
                                      <div class="table-body tbodyHeight55 smoothSrcollbar">
                                        <div class="table_row">
                                          <div class="table_small">
                                            <div class="table_cell">Request ID</div>
                                            <div class="table_cell"><a href="javascript:void(0);" (click)="viewRequest(req, true);">{{req.RequestID}}</a></div>
                                          </div>
                                          <div class="table_small">
                                            <div class="table_cell">Request Type</div>
                                            <div class="table_cell">{{req.ReportRequestTypeName}}</div>
                                          </div>
                                          <div class="table_small">
                                            <div class="table_cell">Product</div>
                                            <div class="table_cell">{{req.ProductName}}</div>
                                          </div>
                                          <div class="table_small">
                                            <div class="table_cell">Submitted On</div>
                                            <div class="table_cell">{{getLocalDate(req.SubmittedOn)  | date : 'dd-MMM-yyyy HH:mm:ss'}}</div>
                                          </div>
                                          <div class="table_small">
                                            <div class="table_cell">Acknowledgement</div>
                                            <div class="table_cell"><a href="javascript:void(0);" (click)="viewAck(req);">View Acknowledgement</a></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="tab-pane fade" id="drafts" role="tabpanel" aria-labelledby="drafts-tab">
                            <div class="container-fluid">
                              <div class="row pt-2">

                                <div class="col-lg-2 col-md-4 col-sm-6">
                                  <div class="form-group">
                                    <label class="fieldLabel">Request ID</label>
                                    <input type="text" (blur)="handleInput($event.target,'DFCN_RequestID');" class="form-control" formControlName="DFCN_RequestID" autocomplete="off">
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6">
                                  <div class="form-group">
                                    <label class="fieldLabel">Request Type</label>
                                    <select class="form-control" formControlName="DFCN_RequestType">
                                      <option value="">-Select-</option>
                                      <option *ngFor="let rt of draftrequestTypes" value="{{rt.ReporteRequestTypeID}}">{{rt.ReportRequestTypeName}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6">
                                  <div class="form-group">
                                    <label class="fieldLabel">Product</label>
                                    <select class="form-control" formControlName="DFCN_Product">
                                      <option value="">-Select-</option>
                                      <option *ngFor="let prod of draftsProducts" value="{{prod.ProductID}}">{{prod.ProductTypeName}}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6">
                                  <div class="form-group">
                                    <label class="fieldLabel">Start Date</label>
                                    <div>
                                      <!--<input type="text" id="draft-sdt" class="form-control" required="" #datepickerSSD="bsDatepicker" max="maxDate"
                                             formControlName="DFCN_StartDate" autocomplete="off" maxlength="50" [bsConfig]="bsConfig"
                                             placeholder="DD-MMM-YYYY" (bsValueChange)="Ondatechange($event,'DFCN_StartDate')" (keyup)="keyPress($event,'DFCN_StartDate','draft-sdt','G',maxDate)"
                                             bsDatepicker />
                                      <span (click)="datepickerSSD.toggle()"><i class="far fa-calendar-alt"></i></span>-->

                                      <p-calendar inputId="draft-sdt"
                                                  [maxDate]="maxDate"
                                                  placeholder="DD-MMM-YYYY"
                                                  formControlName="DFCN_StartDate"
                                                  appendTo="body"
                                                  dateFormat="dd-M-yy"
                                                  [showIcon]="true"
                                                  icon="far fa-calendar-alt"
                                                  styleClass="">
                                      </p-calendar>

                                      <div *ngIf="datevalidationmsg" class="invalid-form-control text-danger fs-12">
                                        {{datevalidationmsg}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6">
                                  <div class="form-group">
                                    <label class="fieldLabel">End Date</label>
                                    <div>
                                      <!--<input type="text" id="draft-edt" class="form-control" required="" #datepickerDSD="bsDatepicker" max="maxDate"
                                             formControlName="DFCN_EndDate" autocomplete="off" maxlength="50" [bsConfig]="bsConfig"
                                             placeholder="DD-MMM-YYYY" (bsValueChange)="Ondatechange($event,'DFCN_EndDate')" (keyup)="keyPress($event,'DFCN_EndDate','draft-edt','G',maxDate)"
                                             bsDatepicker />
                                      <span (click)="datepickerDSD.toggle()"><i class="far fa-calendar-alt"></i></span>-->

                                      <p-calendar inputId="draft-edt"
                                                  [maxDate]="maxDate"
                                                  placeholder="DD-MMM-YYYY"
                                                  formControlName="DFCN_EndDate"
                                                  appendTo="body"
                                                  dateFormat="dd-M-yy"
                                                  [showIcon]="true"
                                                  icon="far fa-calendar-alt"
                                                  styleClass="">
                                      </p-calendar>

                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 text-right" ngPreserveWhitespaces>
                                  <label class="d-block m-0">&nbsp;</label>
                                  <button type="button" class="btn btn-outline-primary" [disabled]="!enableDraftClearandSearch" (click)="ClearClick();">Clear</button>
                                  <button type="button" class="btn btn-primary" [disabled]="!enableDraftClearandSearch" (click)="SearchClick();">Search</button>
                                </div>

                                <div class="col-md-12 col-sm-12 px-0 pt-2">
                                  <div class="row">
                                    <div class="col pagination-lft">
                                      <div class="shwng-items pr-4">
                                        <span>Showing {{page.pager.itemsFrom}}-{{page.pager.itemsTo}} of {{page.pager.totalItems}} Items</span>
                                      </div>
                                    </div>
                                    <div class="col pagination-rht d-flex justify-content-end">
                                      <div class="pagination-btns">
                                        <button class="px-2 imgbutton" title="Previous" [disabled]="page.pager.isPrev" (click)="pagination.setPage(page, (page.pager.currentPage - 1))"><i class="fas fa-caret-left"></i></button>
                                        <button class="px-2 imgbutton" title="Next" [disabled]="page.pager.isNext" (click)="pagination.setPage(page, (page.pager.currentPage + 1))"><i class="fas fa-caret-right"></i></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 px-0">
                                  <div class="table grid-4">
                                    <div class="theader">
                                      <div class="table_header pointer" (click)="sort('RequestID',isAsc)">Request ID<i class="fas" *ngIf="headerKey=='RequestID'" [ngClass]="{ 'fa-sort-down':(isAsc),'fa-sort-up':(!isAsc) }"></i></div>
                                      <div class="table_header pointer" (click)="sort('ReportRequestTypeName',isAsc)">Request Type<i class="fas" *ngIf="headerKey=='ReportRequestTypeName'" [ngClass]="{ 'fa-sort-down':(isAsc),'fa-sort-up':(!isAsc) }"></i></div>
                                      <div class="table_header pointer" (click)="sort('ProductName',isAsc)">Product<i class="fas" *ngIf="headerKey=='ProductName'" [ngClass]="{ 'fa-sort-down':(isAsc),'fa-sort-up':(!isAsc) }"></i></div>
                                      <div class="table_header pointer" (click)="sort('LastUpdatedOn',isAsc)">Last Updated On<i class="fas" *ngIf="headerKey=='LastUpdatedOn'" [ngClass]="{ 'fa-sort-down':(isAsc),'fa-sort-up':(!isAsc) }"></i></div>
                                      <div class="table_header">Action</div>
                                    </div>
                                    <div *ngIf="!(mydraftsrequests.length > 0)">
                                      <p class="fs-12 pt-2 pb-3">
                                        No Records found
                                      </p>
                                    </div>
                                    <div class="table-body-wrap" *ngFor="let dreq of page.pagedRecords">
                                      <div class="table-body tbodyHeight55 smoothSrcollbar">
                                        <div class="table_row">
                                          <div class="table_small">
                                            <div class="table_cell">Request ID</div>
                                            <div class="table_cell"><a href="javascript:void(0);" (click)="viewRequest(dreq, true);">{{dreq.RequestID}}</a></div>
                                          </div>
                                          <div class="table_small">
                                            <div class="table_cell">Request Type</div>
                                            <div class="table_cell">{{dreq.ReportRequestTypeName}}</div>
                                          </div>

                                          <div class="table_small">
                                            <div class="table_cell">Product</div>
                                            <div class="table_cell">{{dreq.ProductName}}</div>
                                          </div>

                                          <div class="table_small">
                                            <div class="table_cell">Last Updated On</div>
                                            <div class="table_cell">{{getLocalDate(dreq.LastUpdatedOn) | date : 'dd-MMM-yyyy HH:mm:ss'}}</div>
                                          </div>

                                          <div class="table_small">
                                            <div class="table_cell"></div>
                                            <ul class="edt-btns float-right">
                                              <li><a href="javascript:void(0);" title="Edit" (click)="viewRequest(dreq, false);"><i class="fas fa-pen title-color-2"></i></a></li>
                                            </ul>
                                          </div>
                                          <div class="table_small">
                                            <div class="table_cell"></div>
                                            <ul class="edt-btns float-right">
                                              <li><a href="javascript:void(0);" title="Delete" (click)="deleteRequest(dreq);"><i class="fas fa-trash-alt title-color-2"></i></a></li>
                                            </ul>
                                          </div>

                                        </div>


                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="Bookmark" role="tabpanel" aria-labelledby="Bookmark-tab">
                <div class="container-fluid px-0">
                  <div class="tabsContainer border p-2">
                    <p class="fs-13">The latest version of the bookmarked content is displayed below.</p>

                    <div class="title-sm py-2"> <span>{{bookmarkscount==null||0 ? 0:bookmarkscount}}</span> Record(s) Found</div>
                    <div class="table bookmarkcnt-tbl">
                      <div class="theader">
                        <div class="table_header"></div>
                        <div class="table_header">Document Name</div>
                        <div class="table_header">Latest Version</div>
                        <div class="table_header"></div>
                      </div>
                      <div class="table-body-wrap">
                        <div class="table-body tbodyHeight55 smoothSrcollbar" *ngFor="let bm of userbookmarks;let i=index">
                          <div class="table_row">
                            <div class="table_small">
                              <div class="table_cell"></div>
                              <div class="table_cell">
                                <i *ngIf="bm.FileType == 'PDF'" class="far fa-file-pdf"></i>
                                <i *ngIf="bm.FileType == 'IMAGE'" class="far fa-file-image"></i>
                                <i *ngIf="bm.FileType == 'WORD'" class="far fa-file-word"></i>
                                <i *ngIf="bm.FileType == 'PPT'" class="far fa-file-powerpoint"></i>
                                <i *ngIf="bm.FileType == 'HTML'" class="far fa-file-code"></i>
                                <i *ngIf="bm.FileType == 'XLS'" class="far fa-file-excel"></i>
                                <i *ngIf="bm.FileType == 'VIDEO'" class="fas fa-file-video"></i>
                                <i *ngIf="bm.FileType == 'OTHER'" class="far fa-file-alt"></i>
                              </div>
                            </div>
                            <div class="table_small">
                              <div class="table_cell">Document Name</div>
                              <div class="table_cell" *ngIf="!bm.IsArchived">
                                <a href="javascript:void(0)" target="_blank" title="{{bm.Name}}" (click)="documentPreview(bm)">
                                  {{bm.Name}}
                                </a>
                              </div>
                              <div class="table_cell" *ngIf="bm.IsArchived">
                                  {{bm.Name}}
                              </div>
                            </div>
                            <div class="table_small">
                              <div class="table_cell">Latest Version</div>
                              <div class="table_cell" *ngIf="!bm.IsArchived">{{bm.LatestVersion}}</div>
                              <div class="table_cell" *ngIf="bm.IsArchived">No Latest Version</div>
                            </div>
                            <div class="table_small">
                              <div class="table_cell"></div>
                              <div class="table_cell">
                                <a class="" data-toggle="tooltip">
                                  <span placement="left" tooltip=''>
                                    <i placement="left" tooltip='{{"Bookmarked Version:"}}{{bm.BookmarkedVersion}}' class="fas fa-info-circle"></i>
                                  </span>
                                </a>

                                <i class="fas fa-bookmark float-right mt-1 mr-4" (click)="UnBookmarkDoc(bm);" data-target=".confirmMsg" data-toggle="modal"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>


<div class="pdf-img text-center" *ngIf="pdfDownloadURL" [style.display]="'none'">
  <iframe width="0" height="0" [src]="pdfDownloadURL" name="DownloadDocument" title="Download Document"></iframe>
</div>
