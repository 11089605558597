<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title w-100 d-block">Password Policy 
        <a (click)="this.close()" class="fs-12 text-underline float-right pt-1 text-white" data-dismiss="modal"><i class="fas fa-times"></i></a>
      </h5>
    </div>
    <div class="modal-body">
      <p class="fs-13">Please note, your password will expire after 1 year.  Password should contain the following:</p>
      <ul class="changepasswordContent fs-13">
        <li>Contain a minimum of {{passwordPolicy.MIN_PASSWORD_LENGTH}} characters in length</li>
        <li>Contain at least {{passwordPolicy.MIN_UPPERCASE_CHARS}} uppercase letter (A-Z)</li>
        <li>Contain at least 1 lowercase letter (a-z)</li>
        <li>Contain at least 1 number (0-9)</li>
        <li>Contain at least {{passwordPolicy.MIN_SPECAIL_CHARS}} special character</li>
        <li>New password must not have been used in the last 3 passwords.</li>
      </ul>
    </div>
  </div>
