import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PaginationService {

    public pageSize: number = 25;
    constructor() { }

    setPagedRecords(data: Array<any>, params: any, currentPage: number = 1): Page {
        const page = {
            allRecords: data || [], filteredRecords: data || [], pagedRecords: [], pager: this.defaultPager(), filterParams: params
        };

        if (data && data.length > 0) {
            return this.setPage(page, currentPage);
        }
        return page;
    }

    searchCriteria(items: Array<any>, filter: { [key: string]: any }): Page {

        const params: any = Object.assign({}, filter);
        let filteredRecords = [];
        if (!(items == undefined || items.length < 1)) {
            const filterObject = this.cleanObject(filter);

            filteredRecords = items.filter(item => {
                let notMatchingField = Object.keys(filterObject).find(key =>
                    (typeof item[key] === 'string' && !item[key].toLowerCase().startsWith(filterObject[key].toLowerCase()))
                    || (typeof item[key] === 'number' && item[key] !== filterObject[key])
                    || (typeof item[key] === 'boolean' && ((typeof filterObject[key] === 'string' && (item[key] !== (filterObject[key] === 'true'))) || item[key] != filterObject[key]))
                );
                return !notMatchingField;
            });
        }

        const page = <Page>{
            allRecords: items, filteredRecords: filteredRecords, pagedRecords: [], pager: this.defaultPager(), filterParams: params
        };
        if (filteredRecords.length > 0) {
            return this.setPage(page, 1);
        }
        return page;
    }

    searchCriteriaNoStartWith(items: Array<any>, filter: { [key: string]: any }): Page {

        const params: any = Object.assign({}, filter);
        let filteredRecords = [];
        if (!(items == undefined || items.length < 1)) {
            const filterObject = this.cleanObject(filter);

            filteredRecords = items.filter(item => {
                let notMatchingField = Object.keys(filterObject).find(key =>
                    (typeof item[key] === 'string' && item[key].toLowerCase().indexOf(filterObject[key].toLowerCase()) === -1)
                    || (typeof item[key] === 'number' && item[key] !== filterObject[key])
                    || (typeof item[key] === 'boolean' && ((typeof filterObject[key] === 'string' && (item[key] !== (filterObject[key] === 'true'))) || item[key] != filterObject[key]))
                );
                return !notMatchingField;
            });
        }

        const page = <Page>{
            allRecords: items, filteredRecords: filteredRecords, pagedRecords: [], pager: this.defaultPager(), filterParams: params
        };
        if (filteredRecords.length > 0) {
            return this.setPage(page, 1);
        }
        return page;
    }

    cleanObject(obj: any) {
        for (var propName in obj) {
            if (obj[propName] === null
                || obj[propName] === 'null'
                || obj[propName] === undefined
                || obj[propName] === 'undefined'
                || obj[propName] === ''
                || obj[propName] === -1
                || obj[propName] === '-1'
            ) {
                delete obj[propName];
            }
        }
        return obj;
    }

    // pagination..
    setPage(page: Page, currentPage: number): Page {
        if (!page.pager.isDefaultPager && (currentPage < 1 || currentPage > page.pager.totalPages)) {
            return page;
        }
        page.pager = this.getPager(page.filteredRecords.length, currentPage, this.pageSize);
        page.pagedRecords = page.filteredRecords.slice(page.pager.startIndex, page.pager.endIndex + 1);
        return page;
    }

    getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10): Pager {
        let totalPages = Math.ceil(totalItems / pageSize);
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        let itemsFrom = startIndex + 1;
        let itemsTo = ((itemsFrom + pageSize - 1) > totalItems ? totalItems : (itemsFrom + pageSize - 1));

        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startIndex: startIndex,
            endIndex: endIndex,
            itemsFrom: itemsFrom,
            itemsTo: itemsTo,
            isPrev: (totalItems == 0 || currentPage == 1),
            isNext: (totalItems == 0 || currentPage == totalPages),
            isDefaultPager: false
        };
    }

    defaultPager(): Pager {
        return {
            totalItems: 0,
            currentPage: 1,
            pageSize: 10,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0,
            itemsFrom: 0,
            itemsTo: 0,
            isPrev: true,
            isNext: true,
            isDefaultPager: true
        };
    }

    sort(data: any[], key: string, isAsc: boolean = true) {
        return data.sort((a, b) => (((a[key] === null) ? "" : a[key]) >= ((b[key] === null) ? "" : b[key])) ? (isAsc ? 1 : -1) : (isAsc ? -1 : 1));
    }

}

export class Page {
    allRecords: Array<any> = [];
    filteredRecords: Array<any> = [];
    pagedRecords?: any[];
    pager: Pager = { totalItems: 0, currentPage: 1, pageSize: 10, totalPages: 0, startIndex: 0, endIndex: 0, itemsFrom: 0, itemsTo: 0, isPrev: true, isNext: true, isDefaultPager: true };
    filterParams: any;
}

export interface Pager {
    totalItems: number;
    currentPage: number,
    pageSize: number,
    totalPages: number,
    startIndex: number,
    endIndex: number,
    itemsFrom: number,
    itemsTo: number,
    isPrev: boolean,
    isNext: boolean,
    isDefaultPager: boolean,
    orderBy?: string;
    orderByDir?: string;
}
