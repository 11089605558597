import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { UtilityService } from '../../_services/utility.service';

@Component({
  templateUrl: './support-login.component.html',
  styleUrls: ['./support-login.component.css']
})
export class SupportLoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  returnUrl: string = '';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private utilityService: UtilityService  ) {

    this.loginForm = formBuilder.group({
      title: formBuilder.control('initial value', Validators.required),
    });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }


  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.authService.login(this.f.username.value, this.f.password.value)
      .subscribe(next => {
        if (next.Errors && next.Errors.length > 0) {
          //todo
        }
        else {
          //this.getTestValues();
          this.router.navigate(['/super-admin']);
        }
      },
        error => {
          console.error(error);
        });
  }


  handleInput($event: any, controlName: string): boolean {
    const control = this.loginForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }

    return true;
  }


  //login() {

  //  this.authService.login('superad', '123456').subscribe(next => {
  //    console.log(next);

  //  },
  //    error => {
  //      console.error(error);
  //    });
  //  //this.router.navigate(['/super-admin']);
  //}



}
