import { Injectable } from '@angular/core';
import { AES, enc, mode, pad } from 'crypto-js';
//import * as CryptoJS from 'crypto-js';

//https://www.code-sample.com/2018/12/angular-7-cryptojs-encrypt-decrypt.html
//npm install crypto-js --save
//npm install @types/crypto-js –save


@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {

  constructor() { }

  //The set method is use for encrypt the value.
  set(keys: any, value: any) {
    var key = enc.Utf8.parse(keys);
    var iv = enc.Utf8.parse(keys);
    var encrypted = AES.encrypt(enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: mode.CBC,
        padding: pad.Pkcs7
      });

    console.log("encrypted:" + encrypted.toString());
    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys: any, value: any) {
    var key = enc.Utf8.parse(keys);
    var iv = enc.Utf8.parse(keys);
    var decrypted = AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: mode.CBC,
      padding: pad.Pkcs7
    });

    console.log("decrypted:" + decrypted.toString(enc.Utf8));

    return decrypted.toString(enc.Utf8);
  }

}
