import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Subject, Subscription } from 'rxjs';
import { AppConstants } from '../shared/AppConstants';
import { LoginComponent } from './components/login/login.component';
import { UserLoginResult } from './_models/user';
import { AlertifyService } from './_services/alertify.service';
import { AuthenticationService } from './_services/authentication.service';
import { HomeService } from './_services/home.service';
import { UtilityService } from './_services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @BlockUI() blockUI: NgBlockUI | undefined;

  //idle properties
  timed: boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing: Date | null = null;
  countdown: any;
  displayCountdown: any;

  isIdleTimeout: boolean = false;
  sessionExpired = new Subject();
  IsSessionExpired: boolean = false;

  public onClose: Subject<boolean>;
  isLoggedInUser: boolean = false;
  sessionID: string = '';

  LoginStatus$: Observable<boolean>;
  HomeUrlPath: string = '';

  showHeader: boolean = false;
  showFooter: boolean = false;
  cookieToogle: boolean = true;
  cookieSelectionToogle: boolean = false;
  subscribeGetPortal: Subscription | null = null;
  private subRouteQueryParams: Subscription | null = null;

  constructor(public utilityService: UtilityService,
    public authService: AuthenticationService,
    private homeService: HomeService,
    private titleService: Title,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private alertify: AlertifyService,
    private idle: Idle) {

    this.titleService.setTitle(utilityService.getPortalName());
    this.onClose = new Subject();

    /**
     * on route change to '/account-verify' and /account-resetpassword, set the variable showHeader, showFooter to false
     */
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'].indexOf('/account-verify') <= -1
          && event['url'].indexOf('/account-resetpassword') <= -1
          && event['url'].indexOf('/event-unsubscribe') <= -1
          && event['url'].indexOf('/news-unsubscribe') <= -1) {
          this.showHeader = true;
          this.showFooter = true;
        } else {
          this.showHeader = false;
          this.showFooter = false;
        }

        this.HomeUrlPath = event.url;
        //console.log(this.HomeUrlPath);
      }
    });

    idle.setIdle(AppConstants.SessionIdleTimeout * 60);
    idle.setTimeout(1);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
    });

    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.displayCountdown = this.secondsToTime(countdown);
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      this.isIdleTimeout = true;
      //console.log(countdown)
      if (countdown == 1) {
        this.timed = true;
        //console.log("I am happy");
        if (authService.isUserLoggedIn()) {
          authService.logout();
          authService.sessionExpired();
          this.router.navigate(['/'], { queryParams: { referrer: 'SESSION_EXPIRED' } })
            .then(() => {
              this.utilityService.setSessionExpired();
              window.location.reload();
            });
        }
      }
    });

    this.resetTimeout();
  }

  closeTimeoutPopup() {
    this.IsSessionExpired = false;
    this.resetTimeout();
    this.router.navigate(['/']);
  }

  resetTimeout() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.sessionID = '';
    this.timedOut = false;
    this.isIdleTimeout = false;
    this.IsSessionExpired = false;
  }

  ngOnInit() {
    let loggedIn = this.authService.isUserLoggedIn();
    if (loggedIn) this.authService.check();
    this.authService.setEnv();
    this.utilityService.setEnv();
    this.IsSessionExpired = false;
    this.resetTimeout();
    this.cookieToogle = !this.authService.isCookie();
    if (!this.utilityService.isProfileSelected()) {
      this.getPortal();
    }

    this.LoginStatus$ = this.authService.isLoggedIn;
    this.subRouteQueryParams = this.activateRoute.queryParams.subscribe(
      params => {
        let referrer = params['referrer'];
        this.sessionID = referrer != null && referrer != "" ? referrer : null;
        if (this.sessionID == 'SESSION_EXPIRED') {
          if (!this.authService.isUserLoggedIn()) {
            this.IsSessionExpired = true;
          }
          else {
            this.router.navigate(['/']);
          }
        }
        else {
          this.IsSessionExpired = false;
        }
      }, error => {

        this.alertify.errorBaner("Error occured while navigating to the requested resource. Please check detailed error:\n\n" + error);
      });

    window.addEventListener('storage', (event) => {
      if (event.key == 'logout-event') {
        this.router.navigate(['/'])
          .then(() => {
            window.location.reload();
          });
      }
    }, false);

    window.addEventListener('storage', (event) => {
      if (event.key == 'timeout-event') {
        this.utilityService.setSessionExpired();
        this.router.navigate(['/'], { queryParams: { referrer: 'SESSION_EXPIRED' } })
          .then(() => {
            this.utilityService.setSessionExpired();
            window.location.reload();
          });
      }
    }, false);

    let isUSHCP = localStorage.getItem('isUS_HCP');
    if (isUSHCP) {
      this.utilityService.isUS_HCP.next(JSON.parse(isUSHCP));
      if (!(JSON.parse(isUSHCP))) this.router.navigate(['/request-mi']);
    }
  }

  secondsToTime(d: any) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    let hDisplay = h > 0 ? (h.toString().length == 1 ? '0' + h : h) : "00";
    let mDisplay = m > 0 ? (m.toString().length == 1 ? '0' + m : m) : "00";
    let sDisplay = s > 0 ? (s.toString().length == 1 ? '0' + s : s) : "00";
    return hDisplay + ":" + mDisplay + ":" + sDisplay;
  }

  close() {
    this.onClose.next(false);
    this.modalRef.hide();
  }

  openLoginForm() {
    this.close();
    this.IsSessionExpired = false;
    this.router.navigate(['/']);
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(LoginComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onLogin.subscribe((result: UserLoginResult) => {
      if (result != null) {
        this.authService.loginStatus.next(true);
        this.authService.UserName.next(result.UserFullName ?? "");
        this.authService.hcpDetails.next(this.utilityService.getAppUserInfoObject(result));
        this.isLoggedInUser = this.authService.isUserLoggedIn();
      }
    });
  }
  
    getPortal() {
    this.subscribeGetPortal = this.homeService.getPortal().subscribe(next => {
      this.authService.portal = next;
      AppConstants.EnvironmentId = next.FK_EnvironmentId;
      AppConstants.ReporterTypeId = next.ReporterTypeId;
      this.utilityService.portalTitle = next.PortalTitle;
      this.utilityService.reporterTypeName = next.ReporterTypeName ?? "US Healthcare Professional";
      this.utilityService.nonHcpReporterTypeName = next.NonHcpReporterTypeName ?? "Patient / Caregiver";

      //if (!this.utilityService.isProfileSelected()) {
      //  this.utilityService.isProfileSelection = true;
      //}
      this.utilityService.setEnvironmentStorage(next);
      this.blockUI?.stop();
    },
      error => {
        this.blockUI?.stop();
        console.error(error);
      });
  }

  actionOnIamHCP(isUSHCP: boolean = false) {
    this.utilityService.actionOnSelfQualification(true);
    localStorage.setItem('isUS_HCP', isUSHCP.toString());
    this.utilityService.isUS_HCP.next(isUSHCP);
    if(isUSHCP && this.utilityService.IsNotHcpAction) {
      window.scrollTo(0, 0);
      this.router.navigate(['/']);
    } else if (!isUSHCP) {
      window.scrollTo(0, 0);
      this.router.navigate(['/'])
      .then(() => {
        this.router.navigate(['/request-mi']);
      });
    }
    this.utilityService.IsNotHcpAction = false;
  }

  actionOnIamNonHCP() {
    this.utilityService.isProfileSelection = true;
    this.utilityService.isNonHCP = true;
  }

  onBack() {
    this.utilityService.isNonHCP = false;
    this.utilityService.isProfileSelection = true;
  }

  onNonHCPClose() {
    this.utilityService.IsNotHcpAction = false;
  }


  allowCookies() {
    this.cookieToogle = false;
    this.cookieSelectionToogle = false;

    if (!this.authService.isCookie()) {
      this.authService.storeCookie();
    }
  }

  closeCookies() {
    this.cookieToogle = false;
  }

  cookieSelection() {
    this.cookieSelectionToogle = true;
  }
}



