import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountVerifyComponent } from './externalpage-components/account-verify/account-verify.component';
import { AccountActivationComponent } from './components/account-activation/account-activation.component';
import { HomeComponent } from './components/home/home.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { SupportLoginComponent } from './components/support-login/support-login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { AccountResetpasswordComponent } from './externalpage-components/account-resetpassword/account-resetpassword.component';
import { EventUnsubscribeComponent } from './externalpage-components/event-unsubscribe/event-unsubscribe.component';
import { NewsUnsubscribeComponent } from './externalpage-components/news-unsubscribe/news-unsubscribe.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    //canActivate: [AuthGuard]
  },
  {
    path: 'sitemap',
    component: SitemapComponent
  },
  {
    path: 'slogin',
    component: SupportLoginComponent    
  },
  {
    path: 'account-activate',
    component: AccountActivationComponent
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/super-admin.module').then(m => m.SuperAdminModule)
  },
  { 
    path: 'account-verify', 
    component:  AccountVerifyComponent 
  },
  { 
    path: 'account-resetpassword', 
    component:  AccountResetpasswordComponent 
  },
  { 
    path: 'event-unsubscribe', 
    component:  EventUnsubscribeComponent 
  },
  { 
    path: 'news-unsubscribe', 
    component:  NewsUnsubscribeComponent 
  },
  { 
    path: 'my-profile', 
    loadChildren: () => import('./user-profile/user-profile.module').then(r => r.UserProfileModule)
  },
  { 
    path: 'my-requests', 
    loadChildren: () => import('./user-request/user-request.module').then(r => r.UserRequestModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
