import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppConstants } from '../../../shared/AppConstants';
import { CodeVerificationErrors } from '../../_enums/constant-types.enum';
import { UserParams } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-account-verify',
  templateUrl: './account-verify.component.html',
  styleUrls: ['./account-verify.component.css']
})
export class AccountVerifyComponent implements OnInit {

  userParams: UserParams = {};
  isActivated: boolean = false;
  inValidLink: boolean = false;
  codeExpired: boolean = false;
  alreadyVerified: boolean = false;

  private subscription: Subscription | null = null;
  public datenow = new Date();
  milliSecondsInASecond = 1000;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  public timeDifference: any;
  public secondsToDday: any;
  public minutesToDday: any;
  isDisabled: boolean = false;


  constructor(private authService: AuthenticationService,
    public utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    const pk_UserID = this.route.snapshot.queryParams['userid'];
    const activationCode = this.route.snapshot.queryParams['code'];

    if (pk_UserID == undefined || activationCode == undefined) {
      this.inValidLink = true;
    }
    else {
      // remove token from url to prevent http referer leakage
      this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

      this.userParams.PK_USER_ID = pk_UserID;
      this.userParams.ACTIVATION_CODE = activationCode;

      this.authService.accountActivationUsingLink(this.userParams)
        .subscribe(next => {
          if (next.Success) {
            this.isActivated = true;
          }
          else {
            if (next.Errors.length > 0) {
              next.Errors.forEach(error => {
                switch (error) {
                  case CodeVerificationErrors.AlreadyVerified:
                    this.alreadyVerified = true;
                    break;
                  case CodeVerificationErrors.InValidLink:
                    this.inValidLink = true;
                    break;
                  case CodeVerificationErrors.CodeExpired:
                    this.codeExpired = true;
                    break;
                }
              });
            }
          }
        },
          error => {
            console.error(error);
          });
    }
  }

  get showTimer() {
    return this.isDisabled;
  }

  get enableLink() {
    return !this.isDisabled;
  }

  private getTimeDifference() {
    let countDownDate = new Date().setMilliseconds(-120000);
    this.timeDifference = this.datenow.getTime() - countDownDate;
    if (this.timeDifference <= 0) {
      this.isDisabled = false;
    }
    else {
      this.allocateTimeUnits(this.timeDifference);
    }
  }
  private allocateTimeUnits(timeDifference: any) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
  }

  resendLink() {
    this.isDisabled = true;
    this.datenow = new Date();
    this.subscription = interval(1000).subscribe(((x: any) => { this.getTimeDifference(); }));
    this.authService.resendActivationLink(this.userParams).subscribe(next => {
      //this.isResendActivation = next;
    },
      error => {
        console.error(error);
      });
  }

}
