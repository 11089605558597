import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../shared/AppConstants';
import { AccountActivationResponse } from '../_models/account-activation-response';
import { APIResponse } from '../_models/apiResponse';
import { CookieTrackings } from '../_models/cookie-trackings';
import { PASSWORD_POLICY } from '../_models/password-policy';
import { Portal } from '../_models/portals';
import { RegisterResponse } from '../_models/register-response';
import { AppUserInfo, ChangePasswordRequest, ResetPasswordRequest, User, UserLoginResult, UserParams } from '../_models/user';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  user: UserLoginResult = {};
  interval: any;
  intervalClear: any;
  portal: Portal = {
    PortalComponents: []
  };

  private userAccountSubject: BehaviorSubject<UserLoginResult>;
  public userAccount: Observable<UserLoginResult>;

  public loginStatus = new BehaviorSubject<boolean>(this.checkLoginStatus());
  public UserName = new BehaviorSubject<string>(this.getLoginUserName());
  public hcpDetails = new BehaviorSubject<AppUserInfo>(this.getLoginHCPDetails());

  //private _user= new BehaviorSubject<UserLoginResult>(null);
  //user$: Observable<UserLoginResult> = this._user.asObservable();

  private eventSubject: Subject<boolean> = new Subject<boolean>();
  public readonly statusChanged$: Observable<boolean> = this.eventSubject.asObservable();

  constructor(private http: HttpClient,
    private encrDecrService: EncryptDecryptService,
    private utilityService: UtilityService,
    private titleService: Title,
    private route: Router) {
    let user: UserLoginResult = <UserLoginResult>{};
    this.userAccountSubject = new BehaviorSubject<UserLoginResult>(user);
    this.userAccount = this.userAccountSubject.asObservable();
  }

  // setLoginLocalStorageInterval() {
  //   this.interval = setInterval(() => {
  //     this.clearLocalStorageInterval(); 
  //   }, 1000 * AppConstants.LocalStorageTimeOutInSeconds);
  // }

  // clearLocalStorageInterval()
  // {    
  //   clearInterval(this.interval);
  //   this.clearLocalStorage();
  //   this.route.navigate(['/'])
  //   .then(() => {
  //     window.location.reload();
  //   });
  // }

  public get userAccountValue(): UserLoginResult {
    return this.userAccountSubject.value;
  }

  getPasswordPolicy(): Observable<PASSWORD_POLICY> {
    return this.http.get<PASSWORD_POLICY>(AppConstants.PortalApiBaseUrl + '/api/Auth/GetPassWordPolicy');
  }

  getUserAccount(userParams: UserParams): Observable<User> {
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/GetUserAccount', userParams);
  }

  forgotPassword(userParams: UserParams): Observable<User> {
    userParams.URI = this.getResetPasswordURI();
    userParams.PAGE_TITLE = this.titleService.getTitle();
    userParams.BROWSER_LANGUAGE = navigator.language;
    userParams.USER_AGENT = navigator.userAgent;
    userParams.TIME_STAMP = new Date();
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/ForgotPassword', userParams);
  }

  resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<User> {
    resetPasswordRequest.PAGE_TITLE = this.titleService.getTitle();
    resetPasswordRequest.BROWSER_LANGUAGE = navigator.language;
    resetPasswordRequest.USER_AGENT = navigator.userAgent;
    resetPasswordRequest.TIME_STAMP = new Date();
    resetPasswordRequest.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/ResetPassword', resetPasswordRequest);
  }

  changePassword(changePasswordRequest: ChangePasswordRequest): Observable<User> {
    changePasswordRequest.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/ChangePassword', changePasswordRequest);
  }

  accountDeactivation(userParams: UserParams): Observable<APIResponse> {
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<APIResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/AccountDeactivation', userParams);
  }

  accountActivation(userParams: UserParams): Observable<AccountActivationResponse> {
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<AccountActivationResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/AccountActivation', userParams);
  }

  RegisteredEmailActivation(userParams: UserParams): Observable<AccountActivationResponse> {
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<AccountActivationResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/RegisteredEmailActivation', userParams);
  }

  accountActivationUsingLink(userParams: UserParams): Observable<AccountActivationResponse> {
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<AccountActivationResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/AccountActivationUsingLink', userParams);
  }

  resendActivation(userParams: UserParams): Observable<boolean> {
    userParams.URI = this.getURI();
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<boolean>(AppConstants.PortalApiBaseUrl + '/api/Auth/ResendActivation', userParams);
  }

  resendActivationLink(userParams: UserParams): Observable<boolean> {
    userParams.URI = this.getURI();
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<boolean>(AppConstants.PortalApiBaseUrl + '/api/Auth/ResendActivationLink', userParams);
  }

  resendResetPasswordLink(userParams: UserParams): Observable<boolean> {
    userParams.URI = this.getResetPasswordURI();
    userParams.PAGE_TITLE = this.titleService.getTitle();
    userParams.BROWSER_LANGUAGE = navigator.language;
    userParams.USER_AGENT = navigator.userAgent;
    userParams.TIME_STAMP = new Date();
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<boolean>(AppConstants.PortalApiBaseUrl + '/api/Auth/ResendResetPasswordLink', userParams);
  }

  resendUpdateRegisteredEmailLink(userParams: UserParams): Observable<boolean> {
    userParams.PAGE_TITLE = this.titleService.getTitle();
    userParams.BROWSER_LANGUAGE = navigator.language;
    userParams.USER_AGENT  = navigator.userAgent;
    userParams.TIME_STAMP = new Date();
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<boolean>(AppConstants.PortalApiBaseUrl + '/api/Auth/ResendUpdateRegisteredEmailLink', userParams);
  }

  userRegister(model: User): Observable<RegisterResponse> {
    model.URI = this.getURI(model.USER_ID);
    model.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<RegisterResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/Register', model);
  }

  editProfile(model: User): Observable<UserLoginResult> {
    model.URI = this.getURI(model.USER_ID);
    model.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<UserLoginResult>(AppConstants.PortalApiBaseUrl + '/api/Auth/EditProfile', model);
  }

  updateRegisteredEmail(userParams: UserParams): Observable<User> {
    userParams.URI = this.getURI();
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/UpdateRegisteredEmail', userParams);
  }

  overwriteRegisteredEmail(userParams: UserParams): Observable<User> {
    userParams.URI = this.getURI();
    userParams.PORTAL_CODE = AppConstants.PortalCode;
    return this.http.post<User>(AppConstants.PortalApiBaseUrl + '/api/Auth/OverwriteRegisteredEmail', userParams);
  }

  // updateRegisteredEmail(userParams: UserParams): Observable<AccountActivationResponse> {
  //   return this.http.post<AccountActivationResponse>(AppConstants.PortalApiBaseUrl + '/api/Auth/UpdateRegisteredEmail', userParams);
  // }

  login(username: string, password: string): Observable<UserLoginResult> {
    const userParams: UserParams = {
      USER_ID: username,
      PASSWORD: password,
      PAGE_TITLE: this.titleService.getTitle(),
      BROWSER_LANGUAGE: navigator.language,
      USER_AGENT: navigator.userAgent,
      TIME_STAMP: new Date(),
      PORTAL_CODE: AppConstants.PortalCode
    };

    return this.http.post<UserLoginResult>(AppConstants.PortalApiBaseUrl + '/api/Auth/Login', userParams)
      .pipe(map(x => {
        if (x) {
          if (x.Errors && x.Errors.length > 0) {
            return x;
          }
          this.user = x;
          this.userAccountSubject.next(x);
          if (x.FK_EnvironmentId != undefined && x.FK_EnvironmentId != null)
            AppConstants.EnvironmentId = x.FK_EnvironmentId;
          if (x.FK_PortalId != undefined && x.FK_PortalId != null)
            AppConstants.PortalId = x.FK_PortalId;
          this.setLocalStorage(x);
          //this.setLoginLocalStorageInterval();
        }
        return x;
      }));
  }

  sspLogin(): Observable<UserLoginResult> {
    return this.login(this.utilityService.sspUN, this.utilityService.sspPD);
  }

  cookieTracking(): Observable<any> {
    let browserName = this.detectBrowserName();
    
    let cookieTracking: CookieTrackings = {
      FK_ENVIRONMENT_ID: this.user.FK_EnvironmentId,
      FK_PORTAL_ID: this.user.FK_PortalId,
      PAGE_TITLE: this.titleService.getTitle(),
      BROWSER_LANGUAGE: navigator.language,
      USER_AGENT: browserName,
      TIME_STAMP: new Date(),
      LOGGED_IN_USER_ID: this.user.PK_UserId,
      PORTAL_CODE: AppConstants.PortalCode
    }

    return this.http.post<any>(AppConstants.PortalApiBaseUrl + '/api/Auth/CookieTracking', cookieTracking);
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getValues(): Observable<string[]> {
    return this.http.get<string[]>(AppConstants.PortalApiBaseUrl + '/api/values');
  }

  logout() {
    this.hcpDetails.next({});
    this.clearLocalStorage();
  }

  isUserLoggedIn(): boolean {
    let objUser = localStorage.getItem('cpappuserinfo');
    if (objUser) this.user = JSON.parse(objUser);
    return !!localStorage.getItem('access_token');
  }

  checkLoginStatus(): boolean {
    let objUser = localStorage.getItem('cpappuserinfo');
    if (objUser) this.user = JSON.parse(objUser);
    return !!localStorage.getItem('access_token');
  }

  getLoginUserName(): string {
    let objUser = localStorage.getItem('cpappuserinfo');
    if (objUser) this.user = JSON.parse(objUser);
    return this.user?.UserFullName ?? "";
  }

  getLoginHCPDetails(): AppUserInfo {
    let nulluser: AppUserInfo = {};
    let objUser = localStorage.getItem('cpappuserinfo');
    if (objUser) {
      this.user = JSON.parse(objUser);
    }
    else {
      this.user = nulluser;
    }
    return this.user;
  }

  get isLoggedIn() {
    return this.loginStatus.asObservable();
  }

  get currentUserName() {
    return this.UserName.asObservable();
  }

  get getLoggedInHCPDetails() {
    return this.hcpDetails.asObservable();
  }

  getURI(userid?: string): string {
    return this.getWebBaseUrl() + AppConstants.PortalSuffix + "/account-verify?";
  }

  getResetPasswordURI(userid?: string): string {
    return this.getWebBaseUrl() + AppConstants.PortalSuffix + "/account-resetpassword?";
  }

  getUnsubscribeConfsURI(): string {
    return this.getWebBaseUrl() + AppConstants.PortalSuffix + "/event-unsubscribe?";
  }

  getUnsubscribeNewsURI(): string {
    return this.getWebBaseUrl() + AppConstants.PortalSuffix + "/news-unsubscribe?";
  }

  getWebBaseUrl(): string {
    return location.protocol + "//" + location.hostname + ":" + location.port;
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }

  //
  setEnv() {
    let objCP = localStorage.getItem('cp');
    if (objCP) this.portal = JSON.parse(objCP);
  }

  storeCookie() {
    if (!this.isCookie()) {
      localStorage.setItem('cookie', 'cookie');
    }

    this.cookieTracking().subscribe(x => {
      },
      error => {
        console.error(error);
      });
  }

  isCookie(): boolean {
      return !!localStorage.getItem('cookie')
  }

  initListener() {
    window.addEventListener('reload', () => this.reset());
    window.addEventListener('mousemove',()=> this.reset());
    window.addEventListener('keydown',() => this.reset());
    window.addEventListener('scroll',() => this.reset());
  }

  stopListener() {
    window.removeEventListener('reload', () => this.reset());
    window.removeEventListener('mousemove',()=> this.reset());
    window.removeEventListener('keydown',() => this.reset());
    window.removeEventListener('scroll',() => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  public getLastAction() {
    let dateString: any = localStorage.getItem("lastAction");
    return parseInt(dateString);
  }

  public setLastAction(lastAction: number) {
    localStorage.setItem("lastAction", lastAction.toString());
  }

  initInterval() {
   this.intervalClear = setInterval(() => {
      this.check();
    }, 15000);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + AppConstants.AutoLogoutTimeout * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout)  {
      this.logout();
      this.loginStatus.next(false);
      this.UserName.next("");
      this.route.navigate(['/'])
      .then(() => {
        window.location.reload();
      });
    }
  }

  setLocalStorage(x: UserLoginResult) {
    this.utilityService.resetSessionExpired();
    localStorage.setItem("lastAction", Date.now().toString());
    this.initListener();
    this.initInterval();
    //const secret_key = 'xyz@123';
    //this.encrDecrService.set('secret_key', x);
    let appUserInfo: AppUserInfo = this.utilityService.getAppUserInfoObject(x);

    localStorage.setItem('cpappuserinfo', JSON.stringify(appUserInfo));
    localStorage.setItem('access_token', x.AccessToken ?? '');
    localStorage.setItem('refresh_token', x.RefreshToken ?? '');
    localStorage.setItem('login-event', 'login' + Math.random());
  }

  clearLocalStorage() {
    this.stopListener();
    clearInterval(this.intervalClear);
    localStorage.removeItem('cpappuserinfo');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.setItem('logout-event', 'logout' + Math.random());
  }

  sessionExpired() {    
    localStorage.setItem('timeout-event', 'timeout' + Math.random());
  }


  /**
* This Method checks for route navigating page privilege. If user has privilege then returns true else false.
* @param router
* @param state
*/
  CheckPrivilege(
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let returnValue: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree = false;

    if (!(Object.keys(this.user).length === 0 && this.user.constructor === Object)) {

      if (this.user.Errors && this.user.Errors.length > 0) {
        this.route.navigateByUrl('/unauthorized');
        returnValue = false;
      }
      else {
        if (this.user.IsSuperAdmin) {
          switch (router.routeConfig?.path) {
            case 'super-admin':
            case 'config':
            case 'support':
              returnValue = true;
              //console.log('route:' + router.routeConfig?.path);
              break;
            default:
              returnValue = false;
              this.clearLocalStorage();
              this.route.navigateByUrl('/unauthorized');
              break;
          }
        }
        else if (!this.user.IsSuperAdmin) {
          switch (router.routeConfig?.path) {
            case 'home':
              //console.log('route:' + router.routeConfig?.path);
              returnValue = true;
              break;
            case 'therapuetic-areas':
            case 'product-detail':
            case 'new-request':
            case 'submit-request':
            case 'info':
            case 'msl':
            case 'msldetails':
              //console.log('route:' + router.routeConfig?.path);
              returnValue = true;
              break;

            default:
              returnValue = false;
              this.clearLocalStorage();
              this.route.navigateByUrl('/unauthorized');
              break;
          }
        }

      }
    }
    else if (router.routeConfig?.path == 'home' || router.routeConfig?.path == '' || router.routeConfig?.path == '**') {
      this.clearLocalStorage();
      this.route.navigateByUrl('/home');

    }
    else {
      this.routeToPreviousState(router.routeConfig?.path);
      return true;
    }

    return returnValue;
  }

  routeToPreviousState(path: string | undefined) {

    this.route.navigateByUrl(path ?? '');
    //this.userInfo$.subscribe(next => {
    //  this.user = next;
    //  this.route.navigateByUrl(path);
    //});
  }
}

// Shift + Alt + F
