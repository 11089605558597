import { Component, Input, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
    selector: 'success-banertemplate',
    template: `
   
      <div class="modelHeader">Success Message</div>
      <div class="modal-body">
            <p class="text-success fs-13 pb-2">{{message}}</p>
            <div class="text-center">
              <button type="button" class="btn btn-primary" (click)="messageClose()">Close</button>
            </div>
      </div>
    
        `,
    styles: [
    ]
})
export class SuccessBanertemplate implements OnInit {

    message:any;
    result: Subject<boolean> = new Subject<boolean>();
    public onClose: Subject<boolean>;

    constructor(private _bsModalRef: BsModalRef) {
    }
    ngOnInit(): void {
      this.onClose = new Subject();
    }
    messageClose()
    {
      this.onClose.next(false);
      this._bsModalRef.hide();
    }

}

