import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Subject, Subscription } from 'rxjs';
import { ZipcodeSelectionComponent } from 'src/app/components/Common/zipcode-selection/zipcode-selection.component';
import { FileStoreType } from 'src/app/_enums/constant-types.enum';
import { MessageType } from 'src/app/_enums/message-type.enum';
import { CountryAutoComplete } from 'src/app/_models/countries';
import { CP_VW_LOOKUPS } from 'src/app/_models/cpvw-lookups';
import { FileStoreParams } from 'src/app/_models/file';
import { Occupation, PreferredContact } from 'src/app/_models/msl';
import { ProductsAutoComplete } from 'src/app/_models/products';
import { RequestModel } from 'src/app/_models/request-model';
import { ZipCode } from 'src/app/_models/zipcode';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { FileService } from 'src/app/_services/file.service';
import { OurProductsService } from 'src/app/_services/our-products.service';
import { SubmitRequestService } from 'src/app/_services/submit-request.service';
import {SystemConfigService } from 'src/app/_services/system-config.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppConstants } from 'src/shared/AppConstants';
import { CalendarModule } from 'primeng/calendar';
import * as moment from 'moment';
@Component({
  selector: 'app-view-edit-pc-request',
  templateUrl: './view-edit-pc-request.component.html',
  styleUrls: ['./view-edit-pc-request.component.css']
})
export class ViewEditPcRequestComponent implements OnInit {

  Data: any;
  RequestData: RequestModel;
  IsView: boolean = false;
  @BlockUI()
  blockUI!: NgBlockUI;

  bsConfig: any;
  code: any = null;
  resultCode: any = null;
  ProductsList: any = [];
  ContactsList: PreferredContact[] = [];
  GenderList: any = [];
  CurrentContact = "";
  PreferedContactName = "";
  maxDate: Date = new Date();
  invalidPreferredEmail = false;
  invalidDOB = false;


  isPhoneMandatory = false;
  isEmailMandatory = false;

  SubmitRequestForm: FormGroup;
  allCountries: CountryAutoComplete[] = [];
  preferedContacts: PreferredContact[] = [];
  occupationList: Occupation[] = [];
  specialityList: CP_VW_LOOKUPS[] = [];

  selectedCountry: string | '' = '';
  selectedProduct: string | '' = '';
  allProducts: ProductsAutoComplete[] = [];
  productDataSource$: Observable<ProductsAutoComplete[]>;
  typeaheadNoResults: boolean = false;
  productTypeaheadLoading: boolean;
  isProductInvalid: boolean = false;
  Product_Name: string = "";

  HCP_Next = false;
  PC_Next = false;

  allowFileTyles: string = 'doc,docx,ppt,pptx,xlsx,xls,pdf,msg,eml,png,jpeg,jpg,txt,htm,html,wav,mp3';
  allowFileExtensions: string = '';
  fileExtensions: string[] = [];
  fileToUpload?: File;
  fileStoreParams: FileStoreParams = {};
  isFileUploaded = false;
  filename: string | null = '';
  fileDisplayName: string = '';
  uploadbtnName: string = 'Upload';
  pdfDownloadURL: SafeUrl | null = null;
  public onClose: Subject<boolean>;
  submitted: boolean = false;
  attachmentMaxFileSize: number = 0;
  datevalidationmsg: string = "";

  private subscribeDisplayCountries: Subscription | null = null;
  private subscribeTPAs: Subscription | null = null;
  private subscribeDisplayProducts: Subscription | null = null;
  private subscribeUploadHandle: Subscription | null = null;

  public OnSubmit: EventEmitter<boolean> = new EventEmitter();
  
  constructor(private ourProductsService: OurProductsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private alertyfyService: AlertifyService,
    public utilityService: UtilityService,
    private authService: AuthenticationService,
    public activaterouter: ActivatedRoute,
    public sumitReq: SubmitRequestService,
    private configService: SystemConfigService,
    public modalRef: BsModalRef,
    public zipCodeModal: BsModalRef,
    private modalService: BsModalService,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    public options: ModalOptions) {
      this.attachmentMaxFileSize = AppConstants.ReportAttachmentMaxFileSize;
      this.onClose = new Subject();

      this.fileExtensions = this.allowFileTyles.split(',');
      this.allowFileExtensions = '.' + this.fileExtensions.join(', .');
     }

  ngOnInit(): void {
    this.Data = this.options.initialState as any;
    this.RequestData = this.Data.Request as RequestModel;
    this.IsView = this.Data.IsView;

    this.bsConfig = Object.assign({}, {
      containerClass: "theme-blue",
      dateInputFormat: 'DD-MMM-YYYY',
      showWeekNumbers: false,
    });

    this.fileDisplayName = '';
    this.BindScreenData();
  }

  BindScreenData() {
    this.ContactsList = [];
    this.occupationList = [];
    this.GenderList = [];
    
    this.BindDataModel();
    this.getPreferedContacts();
    this.getProfession();

    this.getProducts();
    this.getAllCountries(this.RequestData.FK_COUNTRY_ID);
    this.preferredContactValidations();
    this.onChanges();
  }

  BindDataModel() {
    this.SubmitRequestForm = new FormGroup({
      PK_REQUEST_ID: new FormControl({value:this.RequestData.PK_REQUEST_ID??"", disabled: this.IsView}),
      REPORT_REQUEST_TYPE: new FormControl("PC"),
      REQUEST_TYPE: new FormControl("PC"),
      FK_PROFESSION_ID: new FormControl({value:this.RequestData.FK_PROFESSION_ID??"", disabled: this.IsView}, Validators.required),
      PROFESSION_NAME: new FormControl({value:this.RequestData.PROFESSION_NAME??"", disabled: this.IsView}),
      FK_REPORTER_TYPE_ID: new FormControl(""),
      REPORTER_TYPE_NAME: new FormControl({value:this.RequestData.ReporterTypeName??"", disabled: this.IsView}),
      LAST_NAME: new FormControl({value:this.RequestData.LAST_NAME??"", disabled: this.IsView}, Validators.required),
      FIRST_NAME: new FormControl({value:this.RequestData.FIRST_NAME??"", disabled: this.IsView}),
      FK_CONTACT_PREFERENCE_ID: new FormControl({value:this.RequestData.FK_CONTACT_PREFERENCE_ID??"", disabled: this.IsView}, Validators.required),
      PREFERRED_CONTACT_NAME: new FormControl({value:this.RequestData.PREFERRED_CONTACT_NAME??"", disabled: this.IsView}),
      EMAIL_ID: new FormControl({value:this.RequestData.EMAIL_ID??"", disabled: this.IsView}, [Validators.email]),
      PHONE: new FormControl({value:this.RequestData.PHONE??"", disabled: this.IsView}),
      PHONE_EXT: new FormControl({value:this.RequestData.PHONE_EXT??"", disabled: this.IsView}),
      POSTAL_CODE: new FormControl({value:this.RequestData.POSTAL_CODE??"", disabled: this.IsView}, Validators.required),
      FK_COUNTRY_ID: new FormControl({value:this.RequestData.FK_COUNTRY_ID??"", disabled: this.IsView}, Validators.required),
      COUNTRY_NAME: new FormControl({value:this.RequestData.COUNTRY_NAME??"", disabled: this.IsView}),
      CITY: new FormControl({value:this.RequestData.CITY??"", disabled: this.IsView}),
      STATE: new FormControl({value:this.RequestData.STATE??"", disabled: this.IsView}),
      PRODUCT_APPROVAL_ID: new FormControl({value:this.RequestData.PK_PRODUCT_MAPPING_ID??"", disabled: this.IsView}, Validators.required),
      PRODUCT_NAME: new FormControl({value:this.RequestData.PRODUCT_NAME??"", disabled: this.IsView}),
      DESCRIPTION: new FormControl({value:this.RequestData.DESCRIPTION??"", disabled: this.IsView}, Validators.required),
      FILE_NAME: new FormControl({value:this.RequestData.FILE_NAME??"", disabled: this.IsView}),
      FK_FILE_STORE_ID: new FormControl({value:this.RequestData.FK_FILE_STORE_ID??"", disabled: this.IsView}),
      PC_BATCH_NUMBER: new FormControl({value:this.RequestData.PC_BATCH_NUMBER??"", disabled: this.IsView}),
      PC_PRODUCT_MANUFACTUR_DATE: new FormControl({value:this.RequestData.PC_PRODUCT_MANUFACTUR_DATE ? new Date(this.RequestData.PC_PRODUCT_MANUFACTUR_DATE) : "", disabled: this.IsView}),
      PC_PRODUCT_EXPIRY_DATE: new FormControl({value:this.RequestData.PC_PRODUCT_EXPIRY_DATE ? new Date(this.RequestData.PC_PRODUCT_EXPIRY_DATE) : "", disabled: this.IsView}),
      STATUS: new FormControl({value:this.RequestData.STATUS??"", disabled: this.IsView}),
      IS_CALLBACK_ON_PRIORITY: new FormControl({value:this.RequestData.IS_CALLBACK_ON_PRIORITY??"", disabled: this.IsView}),
      IS_LOGGED_IN_USER: new FormControl({value:this.RequestData.IS_LOGGED_IN_USER??"", disabled: this.IsView}),
      CREATED_BY_USER_ID: new FormControl(""),
    });

    if (this.RequestData.FILE_NAME && this.RequestData.FILE_EXT) {
      this.fileDisplayName = this.RequestData.FILE_NAME + this.RequestData.FILE_EXT;
      this.fileStoreParams.PK_FileStoreId = this.RequestData.FK_FILE_STORE_ID;
      this.fileStoreParams.FileStoreType = FileStoreType.FILE;
    }
  }

  get form() {
    return this.SubmitRequestForm.controls;
  }

  Ondatechange(value: Date, fcn: string) {
    this.utilityService.resetCalendar(value, fcn, this.SubmitRequestForm)
  }

  CP_Change(productID: any) {
    let _currentCP = this.ContactsList.filter(function (a: any) {
      return (a.Id == productID)
    })[0];
    if (_currentCP != null && _currentCP != undefined) {
      this.CurrentContact = _currentCP.ContactCode ?? '';
      this.PreferedContactName = _currentCP.ContactName ?? '';
    }
    else {
      this.CurrentContact = '';
      this.PreferedContactName = '';
    }

  }

  getPreferedContacts() {
    this.configService.getPreferredContact().subscribe(next => {
      this.ContactsList = next;
      this.preferedContacts = next;
      this.preferredContactValidations();
    },
    error => {
      console.error(error);
    });
  }

  getProfession() {
    this.configService.getOccupations().subscribe(next => {
      this.occupationList = next;
    },
    error => {
      console.error(error);
    });
  }

  getAllCountries(countryId: number | null | undefined) {
    this.subscribeDisplayCountries = this.configService.getCountriesTypeHeadData().subscribe(next => {
      this.allCountries = next;
      if (countryId != null) {
        this.selectedCountry = this.allCountries.find(x => x.PKCountryId == countryId)?.CountryDisplayName ?? '';
      }
    },
    error => {
      console.error(error);
    });
  }

  getProducts() {
    this.subscribeDisplayProducts = this.ourProductsService.getProductsTypeHeadData().subscribe(next => {
      this.allProducts = next;
      this.selectedProduct = this.allProducts.find(p => p.PK_PRODUCT_MAPPING_ID == this.RequestData.PK_PRODUCT_MAPPING_ID)?.PRODUCT_DISPLAY_NAME?? '';
    },
    error => {
      console.error(error);
    });
  }

  formControlValid(controlName: string): boolean
  {
    let isValid = true;
    if((this.SubmitRequestForm.controls[controlName].value != null
    && this.SubmitRequestForm.controls[controlName].value.trim() == "")
    || (!this.SubmitRequestForm.controls[controlName].value))
    {
      isValid = false;
    }
    return isValid;
  }

  get isStep1Valid() {
    var pno = new RegExp("^[^0-9()-]*$");
    let isValid = true;
    this.invalidPreferredEmail = false;

    if (this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value == null
      || this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value == "") {
      isValid = false;
    }
    if(!this.formControlValid("LAST_NAME"))
    {
      isValid = false;
    }
    if (this.SubmitRequestForm.controls["FK_COUNTRY_ID"] == null
      || this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value == ""
      || this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value == undefined) {
      isValid = false;
    }

    if (this.SubmitRequestForm.controls["EMAIL_ID"].value != null && 
    this.SubmitRequestForm.controls["EMAIL_ID"].value != "" && 
    !this.utilityService.validateEmail(this.SubmitRequestForm.controls["EMAIL_ID"].value)) {
      this.invalidPreferredEmail = true;
      isValid = false;
    }

    if (!this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].valid) {
      isValid = false;
    }
    else {
      let selectedValue = this.getPreferredContact(this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].value);
      if (selectedValue != undefined && selectedValue.ContactCode == "EMAIL") {
        if (!this.SubmitRequestForm.controls["EMAIL_ID"].value) {
          isValid = false;
        }

        if (this.SubmitRequestForm.get('EMAIL_ID')?.errors?.email) {
          isValid = false;
        }
      }
      else if (selectedValue != undefined &&
        selectedValue.ContactCode == "PHONE") {
        if (!this.SubmitRequestForm.controls["PHONE"].value) {
          isValid = false;
        }
        if (this.SubmitRequestForm.controls["PHONE"].value && pno.test(this.SubmitRequestForm.controls["PHONE"].value)) {
          isValid = false;
        }
      }
    }

    return isValid;
  }

  get isStep2Valid() {
    let isValid = true;
    if (this.SubmitRequestForm.controls["PRODUCT_APPROVAL_ID"] == null
      || this.SubmitRequestForm.controls["PRODUCT_APPROVAL_ID"].value == ""
      || this.SubmitRequestForm.controls["PRODUCT_APPROVAL_ID"].value == undefined) {
      isValid = false;
    }
    if(!this.formControlValid("DESCRIPTION"))
    {
      isValid = false;
    }

    return isValid;
  }

  get isDraftValid() {
    let isValid = true;

    if (
      (this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value == null || this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value == "")
      && (this.SubmitRequestForm.controls["FK_COUNTRY_ID"] == null 
      || this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value == ""
      || this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value == undefined)
      
      && !this.formControlValid("FIRST_NAME")
      && !this.formControlValid("LAST_NAME")

      && (this.SubmitRequestForm.controls["POSTAL_CODE"].value == "" || this.SubmitRequestForm.controls["POSTAL_CODE"].value == null)
      
      && !this.formControlValid("STATE")
      && !this.formControlValid("CITY")

      && !(this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].valid)
      && (!this.SubmitRequestForm.controls["PHONE"].value ||
      !this.SubmitRequestForm.controls["PHONE"].valid)
      
      && !this.formControlValid("EMAIL_ID")
      && !this.formControlValid("PHONE_EXT")

      && !this.SubmitRequestForm.controls["PRODUCT_APPROVAL_ID"].valid
      
      && !this.formControlValid("DESCRIPTION")
      && !this.formControlValid("PC_BATCH_NUMBER")
      && !this.formControlValid("FK_FILE_STORE_ID")

      && !this.SubmitRequestForm.controls["PC_PRODUCT_MANUFACTUR_DATE"].value
      && !this.SubmitRequestForm.controls["PC_PRODUCT_EXPIRY_DATE"].value
    ) {
      isValid = false;
    }

    return isValid;
  }

  get isSubmitValid() {
    let isValid = true;
    if (!(this.isStep1Valid && this.isStep2Valid)) {
      isValid = false;
    }

    return isValid;
  }

  HCPValidations(IsValid: boolean, errrs: any): boolean {
    this.invalidPreferredEmail = false;
    let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value);
    if (country != undefined) {
      this.selectedCountry = country.CountryDisplayName;
    }

    if (!this.SubmitRequestForm.controls["FK_PROFESSION_ID"].valid) {
      errrs.push("Please select Profession.");
      IsValid = false;
    }
    if(!this.formControlValid("LAST_NAME"))
    {
      errrs.push("Please enter Last Name.");
      IsValid = false;
    }
    if (!this.SubmitRequestForm.controls["FK_COUNTRY_ID"].valid) {
      errrs.push("Please select Country.");
      IsValid = false;
    }

    if (!this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].valid) {
      errrs.push("Please select Prefered Contact Method.");
      IsValid = false;
    }
    else {
      let selectedValue = this.getPreferredContact(this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].value);
      if (selectedValue != undefined &&
        selectedValue.ContactCode == "EMAIL") {
        if (this.SubmitRequestForm.controls["EMAIL_ID"].value == "") {
          errrs.push("Please enter Email.");
          IsValid = false;
        }
        else if (!this.utilityService.validateEmail(this.SubmitRequestForm.controls["EMAIL_ID"].value)) {
          errrs.push("Please enter valid Email.");
          this.invalidPreferredEmail = true;
          IsValid = false;
        }
      }
      else if (selectedValue != undefined &&
        selectedValue.ContactCode == "PHONE") {
        if (this.SubmitRequestForm.controls["PHONE"].value == "" ||
          !this.SubmitRequestForm.controls["PHONE"].valid) {
          errrs.push("Please enter Phone.");
          IsValid = false;
        }
      }
    }

    if (!IsValid) {
      let errMessage = '';
      for (var i = 0; i < errrs.length; i++) {
        errMessage += errrs[i] + ' </br>';
      }

    }

    return IsValid;
  }

  PCValidations(IsValid: boolean, errrs: any): boolean {

    if (!this.SubmitRequestForm.controls["PRODUCT_APPROVAL_ID"].valid) {
      errrs.push("Please select Product.");
      IsValid = false;
    }
    if(!this.formControlValid("DESCRIPTION"))
    {
      errrs.push("Please enter Description.");
      IsValid = false;
    }

    if (this.SubmitRequestForm.controls['PC_PRODUCT_MANUFACTUR_DATE'].value && this.SubmitRequestForm.controls['PC_PRODUCT_EXPIRY_DATE'].value) {
      let manufacture_date = new Date(moment(this.SubmitRequestForm.controls['PC_PRODUCT_MANUFACTUR_DATE'].value).format("DD-MMM-YYYY"));
      let expiry_date = new Date(moment(this.SubmitRequestForm.controls['PC_PRODUCT_EXPIRY_DATE'].value).format("DD-MMM-YYYY"));
      if (manufacture_date > expiry_date) {
        //this.datevalidationmsg = "Manufacturing date should not be greater than expiry  date.";
        errrs.push("Manufacturing date should not be greater than expiry  date.");
        IsValid = false;
      }
    }

    if (!IsValid) {
      let errMessage = '';
      for (var i = 0; i < errrs.length; i++) {
        errMessage += errrs[i] + ' </br>';
      }
    }

    return IsValid;
  }

  validations(next: string): boolean {
    let IsValid = true;
    let errrs: any = [];

    IsValid = this.HCPValidations(IsValid, errrs);
    IsValid = this.PCValidations(IsValid, errrs);

    return IsValid;
  }

  public cleanForm() {
    let count = 0;
    if (this.SubmitRequestForm != null && this.SubmitRequestForm != undefined) {
      Object.keys(this.SubmitRequestForm.controls).forEach(key => {

        let value: any = this.SubmitRequestForm.controls[key].value;
        if (this.SubmitRequestForm.controls[key].value != null && typeof (this.SubmitRequestForm.controls[key].value) === "string"
          && this.SubmitRequestForm.controls[key].value !== "") {
          value = this.SubmitRequestForm.controls[key].value.trim();
        }
        else if (this.SubmitRequestForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE"
          && key != "FK_COUNTRY_ID" && key != "PRODUCT_APPROVAL_ID") {
          this.SubmitRequestForm.controls[key].setValue(value);
        }

      });
    }
  }

  submitValidations(): boolean {
    let IsValid = true;
    let errrs: any = [];
    this.datevalidationmsg = "";

    IsValid = this.HCPValidations(IsValid, errrs);
    IsValid = this.PCValidations(IsValid, errrs);

    if (!IsValid) {
      this.alertyfyService.validationBaner(errrs);
    }

    return IsValid;
  }

  draftValidations(): boolean {
    let IsValid = true;
    let errrs: any = [];
    this.invalidPreferredEmail = false;
    this.datevalidationmsg = "";

    let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value);
    if (country != undefined) {
      this.selectedCountry = country.CountryDisplayName;
    }

    if (this.SubmitRequestForm.controls["EMAIL_ID"].value != null && 
    this.SubmitRequestForm.controls["EMAIL_ID"].value != "" && 
    !this.utilityService.validateEmail(this.SubmitRequestForm.controls["EMAIL_ID"].value)) {
      errrs.push("Please enter valid Email.");
      this.invalidPreferredEmail = true;
      IsValid = false;
    }

    if (this.SubmitRequestForm.controls["EMAIL_ID"].value &&
      this.SubmitRequestForm.controls["EMAIL_ID"].errors?.email) {
      errrs.push("Please enter valid Email.");
      this.invalidPreferredEmail = true;
      IsValid = false;
    }

    if (this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].valid) {
      let selectedValue = this.getPreferredContact(this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].value);
      if (selectedValue != undefined &&
        selectedValue.ContactCode == "EMAIL") {
        if (this.SubmitRequestForm.controls["EMAIL_ID"].value != null && 
        this.SubmitRequestForm.controls["EMAIL_ID"].value != "" && 
        !this.utilityService.validateEmail(this.SubmitRequestForm.controls["EMAIL_ID"].value)) {
          errrs.push("Please enter valid Email.");
          this.invalidPreferredEmail = true;
          IsValid = false;
        }
        
      }
    }
    if (!this.SubmitRequestForm.controls["PC_PRODUCT_MANUFACTUR_DATE"].valid) {
      errrs.push("Please enter valid Product Manufacture Date.");
      IsValid = false;
    }

    if (!this.SubmitRequestForm.controls["PC_PRODUCT_EXPIRY_DATE"].valid) {
      errrs.push("Please enter valid Product Expiry Date.");
      IsValid = false;
    }

    if (this.SubmitRequestForm.controls['PC_PRODUCT_MANUFACTUR_DATE'].value && this.SubmitRequestForm.controls['PC_PRODUCT_EXPIRY_DATE'].value) {
      let manufacture_date = new Date(moment(this.SubmitRequestForm.controls['PC_PRODUCT_MANUFACTUR_DATE'].value).format("DD-MMM-YYYY"));
      let expiry_date = new Date(moment(this.SubmitRequestForm.controls['PC_PRODUCT_EXPIRY_DATE'].value).format("DD-MMM-YYYY"));
      if (manufacture_date > expiry_date) {
        this.datevalidationmsg = "Manufacturing date should not be greater than expiry  date.";
        // errrs.push("Manufacturing date should not be greater than expiry date.");
        IsValid = false;
      }
    }

    if (!IsValid) {
      let errMessage = '';
      for (var i = 0; i < errrs.length; i++) {
        errMessage += errrs[i] + ' </br>';
      }
    }

    return IsValid;
  }

  onChanges() {
    const contactPreferenceControl = <FormControl>this.SubmitRequestForm.get('FK_CONTACT_PREFERENCE_ID');
    const zipCodeControl = <FormControl>this.SubmitRequestForm.get('POSTAL_CODE');

    contactPreferenceControl.valueChanges
      .subscribe(value => {
        this.preferredContactValidations();
      });

    zipCodeControl.valueChanges
      .subscribe(value => {
        let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value);
        if (country?.CountryCode == 'US') {
          if (value.length > 4) {
            this.subscribeDisplayCountries = this.configService.getZipCodes(value).subscribe(zipCodes => {
              if (zipCodes.length == 1) {
                this.setCountryWithZipCode(zipCodes[0]);
              }
              else if (zipCodes.length > 1) {
                this.SubmitRequestForm.controls["CITY"].setValue("");
                this.SubmitRequestForm.controls["STATE"].setValue("");
                this.openMultipleZipCodePopup(zipCodes);
              }
            },
              error => {
                console.error(error);
              });
          }
        }
      });
  }

  preferredContactValidations()
  {
    const preferredEmailControl = <FormControl>this.SubmitRequestForm.get('EMAIL_ID');
    const preferredPhoneControl = <FormControl>this.SubmitRequestForm.get('PHONE');
    this.invalidPreferredEmail = false;
    let selectedValue = this.preferedContacts.find(x => x.Id == this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].value);
    if (selectedValue != undefined && selectedValue.ContactCode == "EMAIL") {
      this.isPhoneMandatory = false;
      this.isEmailMandatory = true;
      this.SubmitRequestForm.get('EMAIL_ID')?.setValidators([Validators.required, Validators.email]);
      //this.SubmitRequestForm.get('PHONE')?.clearValidators();
      this.SubmitRequestForm.get('PHONE')?.setValidators([Validators.pattern(/[^0-9()-]*/g)]);
    }
    else if (selectedValue != undefined && selectedValue.ContactCode == "PHONE") {
      this.isPhoneMandatory = true;
      this.isEmailMandatory = false;
      this.SubmitRequestForm.get('PHONE')?.setValidators([Validators.required, Validators.pattern(/[^0-9()-]*/g)]);
      //this.SubmitRequestForm.get('EMAIL_ID')?.clearValidators();
      this.SubmitRequestForm.get('EMAIL_ID')?.setValidators([Validators.email]);
    }
    else {
      this.isPhoneMandatory = false;
      this.isEmailMandatory = false;
      this.SubmitRequestForm.get('PHONE')?.clearValidators();
      this.SubmitRequestForm.get('EMAIL_ID')?.clearValidators();
    }
    preferredEmailControl.updateValueAndValidity();
    preferredPhoneControl.updateValueAndValidity();
  }

  openMultipleZipCodePopup(zipCodes: ZipCode[]) {
    const config: ModalOptions = {
      initialState: <any>zipCodes,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false,
    };

    this.zipCodeModal = this.modalService.show(ZipcodeSelectionComponent, config);
    this.zipCodeModal.content.closeBtnName = 'Close';
    this.zipCodeModal.content.event.subscribe((res: ZipCode) => {
      this.setCountryWithZipCode(res);
    });
  }

  setCountryWithZipCode(zipcode: ZipCode) {
    this.SubmitRequestForm.controls["CITY"].setValue(zipcode.CITY);
    this.SubmitRequestForm.controls["STATE"].setValue(zipcode.CITYSTATE);
    let country = this.allCountries.find(c => c.CountryCode == zipcode.COUNTRYCODE);
    this.selectedCountry = country?.CountryDisplayName ?? '';
    this.SubmitRequestForm.controls["FK_COUNTRY_ID"].setValue(country?.PKCountryId);
  }


  handleFileInput($event: any) {
    if ($event && $event.target.files) {

      let files = $event.target.files;
      if (files && files.length > 0) {
        this.fileToUpload = files.item(0);

        if (this.validateDocFile() && this.fileToUpload) {
          this.blockUI.start();

          this.subscribeUploadHandle = this.fileService.uploadFile(this.fileToUpload, this.fileStoreParams.PK_FileStoreId).subscribe((result) => {
            this.fileStoreParams.PK_FileStoreId = result.PK_TEMP_FILE_STORE_ID;
            this.fileStoreParams.FileStoreType = FileStoreType.TEMP;
            if (result.FILE_NAME) {
              this.fileDisplayName = result.FILE_NAME + result.FILE_EXT;
            }
            this.SubmitRequestForm.controls["FK_FILE_STORE_ID"].setValue(result.PK_TEMP_FILE_STORE_ID);
            this.uploadbtnName = 'Replace';
            //this.SubmitRequestForm.controls["FILE_NAME"].setValue(this.filename);

            this.isFileUploaded = true;
            this.blockUI.stop();
          }, error => {
            //console.error(error.console);
            //this.alertyfyService.setToastMessage(error, MessageType.Error);
            this.alertyfyService.errorBaner(error);
            this.blockUI.stop();
          },
            () => {
              this.blockUI.stop();
            });
        }
      }
    }
  }

  private validateDocFile() {
    let isValidFile = true;
    let errrs: any = [];
    let fileSize = AppConstants.ReportAttachmentMaxFileSize; //10MB
    let fileType = this.fileToUpload?.name.slice((this.fileToUpload?.name.lastIndexOf(".") - 1 >>> 0) + 2); //this.fileToUpload?.name.substring(this.fileToUpload.name.lastIndexOf('.'), this.fileToUpload.name.length);     
    if (fileType && !(this.fileExtensions.includes(fileType?.toLowerCase()))) {
      isValidFile = false;
      errrs.push('Invalid file, allowed extensions are : ' + this.allowFileExtensions);
    }
    if (this.fileToUpload != null && this.fileToUpload.size > (fileSize * 1048576)) {
      isValidFile = false;
      errrs.push('Document size cannot be more than ' + fileSize + 'MB');
    }
    if (this.fileToUpload != null && this.fileToUpload.name != null && this.fileToUpload.name.length > 250) {
      isValidFile = false;
      errrs.push('File name cannot be more than 250 characters');
    }

    if (!isValidFile) {
      this.alertyfyService.validationBaner(errrs);
    }
    return isValidFile;
  }

  downloadDocument(fileStoreId: string | undefined) {
    if (fileStoreId) {
      this.pdfDownloadURL = this.utilityService.downloadURL(fileStoreId, this.fileStoreParams.FileStoreType??FileStoreType.TEMP);
    }
  }

  deleteAttachment() {
    if (this.fileStoreParams && this.fileStoreParams.PK_FileStoreId !== "") {
      this.alertyfyService.ConfirmationBanner("Are you sure you want to delete the attachment?", this.deleteAttachmentCallback, "Confirm");
    }
  }

  deleteAttachmentCallback = (result: any) => {
    if (result) {
      this.filename = null;
      this.fileDisplayName = '';
      this.SubmitRequestForm.controls["FILE_NAME"].setValue(null);
      this.SubmitRequestForm.controls["FK_FILE_STORE_ID"].setValue(null);
      this.uploadbtnName = 'Upload';
      this.fileStoreParams = {};
    }
  }

  saveAsDraftRequest() {
    //this.submitted = true;
    this.cleanForm();
      if (this.draftValidations()) {
        this.blockUI.start();

        let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value);

        var formData = this.SubmitRequestForm.value;
        formData["PROFESSION_NAME"] = this.getProfessionName(this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value);
        formData["PREFERRED_CONTACT_NAME"] = this.PreferedContactName;
        formData["COUNTRY_NAME"] = this.selectedCountry;
        formData["COUNTRY_CODE"] = country?.CountryCode ?? '';
        formData["PRODUCT_NAME"] = this.selectedProduct;
        formData["STATUS"] = "DRAFT";

        this.sumitReq.saveRequest(formData).subscribe(res => {
          this.alertyfyService.successBaner("Your report " + res.ResponseID + " is saved successfully.");
          this.OnSubmit.emit(true);
          this.blockUI.stop();
          this.close();
        },
          err => {
            console.error(err);
            this.blockUI.stop();
          });
      }
  }

  submitRequest() {
    this.submitted = true;
    this.cleanForm();
    if (this.submitValidations()) {
      this.cleanForm();
      if (this.draftValidations()) {
        this.blockUI.start();

        let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value);

        var formData = this.SubmitRequestForm.value;
        formData["PROFESSION_NAME"] = this.getProfessionName(this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value);
        formData["PREFERRED_CONTACT_NAME"] = this.PreferedContactName;
        formData["COUNTRY_NAME"] = this.selectedCountry;
        formData["COUNTRY_CODE"] = country?.CountryCode ?? '';
        formData["PRODUCT_NAME"] = this.selectedProduct;
        formData["STATUS"] = "SUBMITTED";

        this.sumitReq.saveRequest(formData).subscribe(res => {
          this.alertyfyService.successBaner("Thank you for submitting the report. Please find the related Reference ID - " + res.ResponseID + " which can be used for future purpose.");
          //this.BindScreenData();
          this.OnSubmit.emit(true);
          this.blockUI.stop();
          this.close();
        },
          err => {
            console.error(err);
            this.blockUI.stop();
          });
      }
    }
  }

  cancel() {
    if (this.fileStoreParams && this.fileStoreParams.PK_FileStoreId !== "") {
      this.alertyfyService.ConfirmationBanner("Do you want to cancel the report submission?", this.cancelCallback, "Confirm");
    }
  }

  cancelCallback = (result: any) => {
    if (result) {
      this.close();
    }
  }

  getCountryName(id: any): string {
    let cname: string = '';
    let _currentCN = this.allCountries.filter(function (a: any) {
      return (a.PK_COUNTRY_ID == id)
    })[0];
    if (_currentCN != null && _currentCN != undefined) {
      cname = _currentCN.CountryDisplayName;
    }
    else {
      cname = '';
    }

    return cname;
  }

  getPreferredContact(id: any): any {
    let pc: any = undefined;
    let _currentPC = this.ContactsList.filter(function (a: any) {
      return (a.Id == id)
    })[0];
    if (_currentPC != null && _currentPC != undefined) {
      pc = _currentPC;
    }

    return pc;
  }

  getProfessionName(id: any): string {
    let name: string = '';
    let _currentPC = this.occupationList.filter(function (a: any) {
      return (a.Id == id)
    })[0];
    if (_currentPC != null && _currentPC != undefined) {
      name = _currentPC.Name ?? '';
    }

    return name;
  }

  getPreferredContactName(id: any): string {
    let name: string = '';
    let _currentPC = this.ContactsList.filter(function (a: any) {
      return (a.PK_LOOKUP_ID == id)
    })[0];
    if (_currentPC != null && _currentPC != undefined) {
      name = _currentPC.ContactName ?? '';
    }

    return name;
  }

  getProductName(id: any): string {
    let name: string = '';
    let _currentPR = this.ProductsList.filter(function (a: any) {
      return (a.MiqProductApprovalId == id)
    })[0];
    if (_currentPR != null && _currentPR != undefined) {
      name = _currentPR.ProductDisplayName;
    }
    else {
      name = '';
    }

    return name;
  }

  getConfirmation() {
    if (this.SubmitRequestForm.touched) {
      this.alertyfyService.ConfirmationBanner("Are you sure you want to abort the Request?", this.ConfimationCalBack, "Confirm");
    }
  }

  ConfimationCalBack = (result: any) => {
    if (result) {
      this.route.navigate(['/home']);
    }
  }

  close()
  {
    this.modalRef.hide();
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SubmitRequestForm.controls[controlName];
    if (controlName != "DESCRIPTION") {
      $event.value = this.utilityService.replaceEmojis($event.value);
      if(controlName != "POSTAL_CODE")
      {
        control.setValue($event.value);
      }
      else
      {
        control.setValue($event.value,{emitEvent:false});
      }
    }
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      if(controlName != "POSTAL_CODE")
      {
        control.setValue($event.value);
      }
      else
      {
        control.setValue($event.value,{emitEvent:false});
      }
      return false;
    }
    return true;
  }

  keyPress(event: KeyboardEvent, fcn: string, controlid: string, mmtype: string, dateval?: Date | undefined) {
    this.utilityService.OnkeyupCalendar(event, fcn, this.SubmitRequestForm, controlid, mmtype, dateval);
  }
  
   OnDateSelect(event: any, fcn: string, MinMaxFlag?: string, MinMaxDateVal?: Date) {
    let IsValid = true;
    this.datevalidationmsg = "";
    if (this.SubmitRequestForm.controls['PC_PRODUCT_MANUFACTUR_DATE'].value && this.SubmitRequestForm.controls['PC_PRODUCT_EXPIRY_DATE'].value) {
      let manufacture_date = new Date(moment(this.SubmitRequestForm.controls['PC_PRODUCT_MANUFACTUR_DATE'].value).format("DD-MMM-YYYY"));
      let expiry_date = new Date(moment(this.SubmitRequestForm.controls['PC_PRODUCT_EXPIRY_DATE'].value).format("DD-MMM-YYYY"));
      if (manufacture_date > expiry_date) {
        this.datevalidationmsg = "Manufacturing date should not be greater than expiry  date.";
        IsValid = false;
      }
    }
    else {
      this.datevalidationmsg = "";
    }
    this.utilityService.ValidateDate(event.target.value, fcn, MinMaxFlag, MinMaxDateVal, this.SubmitRequestForm);
  }

}
