<div class="popopslide-inner popover-90 p0">  

  <div class="modal-header">
    <h5 class="modal-title" id="">
      Preview
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" title="Close" (click)="close();">
        <span aria-hidden="true">×</span>
      </button>
    </h5>
  </div>

  <div class="popup-body" style="border-top: 1px solid #e0e6eb; overflow:hidden;">

    <div class="pdf-img text-center iframe-loader" *ngIf="previewURL" style="height: calc(100vh - 65px);">
      <!--<embed type="application/pdf" width="700" height="600" [src]="pdfPreviewURL" name="PreviewDocument" title="Preview Document" />-->
      <iframe width="98%" [src]="previewURL" name="PreviewDocument" title="Preview Document" style="height:100%"></iframe>
    </div>
  </div>
</div>
