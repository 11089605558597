import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../../shared/AppConstants';
import { APIResponse } from '../_models/apiResponse';
import { EventConfSubsModel, EventsConferenceData, EventsConferencesModel, EventsConfParams, EventsConfsResponse } from '../_models/eventsConference';

@Injectable({
  providedIn: 'root'
})
export class EventsConferencesService {

  constructor(private http: HttpClient) { }

  uploadBannerFiles(formData: FormData): Observable<EventsConferenceData> {
    return this.http.post<EventsConferenceData>(AppConstants.PortalApiBaseUrl + '/api/File/EventsBannerBulkUpload', formData);
  }

  uploadAttachments(formData: FormData) {
    return this.http.post<EventsConferenceData>(AppConstants.PortalApiBaseUrl + '/api/File/EventsAttachmentsBulkUpload', formData);
  }

  createUpdateEventsConferences(eventsConfParams: EventsConfParams): Observable<APIResponse> {
    eventsConfParams.PortalCode = AppConstants.PortalCode;
    eventsConfParams.FK_EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<APIResponse>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/CreateUpdateEventsConferences', eventsConfParams);
  }

  // getEventsAndConfs(): Observable<ConferenceModel[]> {
  //   return this.http.get<ConferenceModel[]>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/GetEventsConfs');
  // }
  getEventsAndConfs(userID: string | undefined): Observable<EventsConferencesModel> {
    var PortalCode = AppConstants.PortalCode;
    return this.http.get<EventsConferencesModel>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/GetEventsConfs/' + userID + "/" + PortalCode);
  }

  getEventsAndConfSubscriptionStatus(userID: string | undefined): Observable<EventsConferencesModel> {
    var PortalCode = AppConstants.PortalCode;
    return this.http.get<EventsConferencesModel>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/GetEventsAndConferenceSubscriptionStatus/' + userID + "/" + PortalCode);
  }

  postEventConfSubs(SubscribeForm: EventConfSubsModel): Observable<EventsConfsResponse> {
    SubscribeForm.PortalCode = AppConstants.PortalCode;
    SubscribeForm.FK_EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<EventsConfsResponse>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/AddSubscribers', SubscribeForm);
  }

  postEventConfEnrolls(enrollForm: EventConfSubsModel): Observable<EventsConfsResponse> {
    enrollForm.PortalCode = AppConstants.PortalCode;
    enrollForm.FK_EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<EventsConfsResponse>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/Enroll', enrollForm);
  }

  unsubscribe(sid: string, eid: string): Observable<EventsConfsResponse> {
    const unsubscribeParams: any = {
      EmailID: eid,
      FK_EnvironmentId: AppConstants.EnvironmentId,
      PK_EVENT_SUBSCRIBER_ID: sid
    };
    return this.http.post<EventsConfsResponse>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/Unsubscribe/', unsubscribeParams);
  }

  searchEventsConfs(searchConfsForm: any, userID: string | undefined): Observable<any> {
    searchConfsForm.PortalCode = AppConstants.PortalCode;
    searchConfsForm.PK_UserID = userID;
    return this.http.post<EventsConferencesModel>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/SearchEventsConfs', searchConfsForm);
  }

  // getHybridProductMappings(): Observable<ConferenceModel[]> {
  //   return this.http.get<ConferenceModel[]>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/GetEventsConfs');
  // }

}
