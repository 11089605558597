<!--========= header start ==========-->
<header>
  <div class="topof-Header">
    <div class="container-fluid">
      <a [routerLink]="isUS_HCP ? ['/'] : ['/request-mi']" id="logo"><img src="./assets/images/logo.png" alt=""> </a>
      <div *ngIf="isUS_HCP" class="dropdown useraccount mbl-useraccount" dropdown>
        <button *ngIf="!(LoginStatus$ | async) as LoginStatus" (click)="login()" class="btn btn-secondary fs-14"
          type="button" id="dropdownMenuButton">
          <i *ngIf="LoginStatus" class="fas fa-user" title="Login"></i>&nbsp;Login
        </button>
        <span *ngIf="!(LoginStatus$ | async) as LoginStatus" class="text-white">&nbsp;|&nbsp;</span>
        <button (click)="register()" *ngIf="!(LoginStatus$ | async) as LoginStatus" class="btn btn-secondary fs-14"
          type="button" id="dropdownMenuButton">
          <i class="" *ngIf="LoginStatus" title="Register"></i>Register
        </button>
        <button *ngIf="(LoginStatus$ | async) as LoginStatus" class="btn btn-secondary dropdown-toggle fs-14"
          type="button" id="dropdownMenuButton" dropdownToggle>
          <i *ngIf="(UserName$ | async) as UserName" class="fas fa-user"></i>&nbsp;{{UserName$ | async}}
        </button>
        <div class="dropdown-menu dropdown-menu-ovrd dropdown-menu-right" aria-labelledby="dropdownMenuButton"
          *dropdownMenu>
          <a class="dropdown-item" href="javascript:void(0)" routerLink="my-profile">My Profile</a>
          <a class="dropdown-item" href="javascript:void(0)" routerLink="my-requests">My Requests</a>
          <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
        </div>
      </div>

      <ul>
        <li><a *ngIf="enabledMenuItems.isResources" routerLink="resources">RESOURCES</a></li>
        <li><a href="javascript:void(0)" (click)="openContactModal(template)">CONTACT US</a></li>
      </ul>
    </div>
  </div>
  <div class="mainNav">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <nav class="navbar navbar-expand-lg ">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
          </button>
          <a [routerLink]="isUS_HCP ? ['/'] : ['/request-mi']" id="logo-mbl"><img src="./assets/images/mbl-logo.png"
              alt=""> </a>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item" *ngIf="isUS_HCP" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <a class="nav-link" routerLink=""><i class="fas fa-home"></i></a></li>
              <li class="nav-item" *ngIf="isUS_HCP && enabledMenuItems.isSMI" routerLinkActive="active"><a
                  class="nav-link" routerLink="find-medical-info">{{SMI_NAME}}</a></li>
              <li class="nav-item" *ngIf="enabledMenuItems.isRMI" routerLinkActive="active"><a class="nav-link"
                  routerLink="request-mi">{{RMI_NAME}}</a></li>
              <li class="nav-item" *ngIf="enabledMenuItems.isMSL" routerLinkActive="active"><a class="nav-link"
                  routerLink="find-msl">Find My MSL</a></li>
              <li class="nav-item" *ngIf="enabledMenuItems.isAE" routerLinkActive="active"><a class="nav-link"
                  routerLink="report-ae">Report an Adverse Event</a></li>
              <li class="nav-item" *ngIf="enabledMenuItems.isPC" routerLinkActive="active"><a class="nav-link"
                  routerLink="report-pc">Report a Product Complaint</a></li>
              <li class="nav-item" *ngIf="enabledMenuItems.isNews" routerLinkActive="active"><a class="nav-link"
                  routerLink="congress-presentation">{{CONG_NAME}}</a></li>
              <li class="nav-item" *ngIf="enabledMenuItems.isConferences" routerLinkActive="active"><a class="nav-link"
                  routerLink="events-conferences">Events and Conferences</a></li>
              <li class="nav-item" *ngIf="enabledMenuItems.isProducts" routerLinkActive="active"><a class="nav-link"
                  routerLink="our-products">Our Products</a></li>
            </ul>
          </div>
        </nav>

        <div *ngIf="isUS_HCP" class="dropdown useraccount" dropdown>
          <button *ngIf="!(LoginStatus$ | async) as LoginStatus" (click)="login()" class="btn btn-secondary fs-14"
            type="button" id="dropdownMenuButton">
            <i *ngIf="LoginStatus" class="fas fa-user" title="Login"></i>&nbsp;Login
          </button>
          <span *ngIf="!(LoginStatus$ | async) as LoginStatus" class="text-white">&nbsp;|&nbsp;</span>
          <button (click)="register()" *ngIf="!(LoginStatus$ | async) as LoginStatus" class="btn btn-secondary fs-14"
            type="button" id="dropdownMenuButton">
            <i class="" *ngIf="LoginStatus" title="Register"></i>Register
          </button>
          <button *ngIf="(LoginStatus$ | async) as LoginStatus" style="line-height: 1px;"
            class="btn btn-secondary dropdown-toggle fs-14 text-truncate" type="button" id="dropdownMenuButton"
            dropdownToggle>
            <span class="username-width text-truncate">
              <i *ngIf="(UserName$ | async) as UserName" class="fas fa-user pr-2"></i> {{UserName$ | async}}
            </span>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-ovrd">
            <a class="dropdown-item" href="javascript:void(0)" routerLink="my-profile">My Profile</a>
            <a class="dropdown-item" href="javascript:void(0)" routerLink="my-requests">My Requests</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
          </div>
        </div>

      </div>

    </div>
  </div>
</header>
<!--========= header end ==========-->

<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block">Contact Us
      <span class="mt-1 float-right fs-13 contact-us-span" data-dismiss="modal" (click)="modalRef?.hide()">
        <i class="fas fa-times"></i></span>
    </h5>
  </div>
  <div class="modal-body px-2 py-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 col-md-12">
          <ul class="contactUs">
            <li>
              <div class="row">
                <div class="col-md-12">
                  <a href="mailto:medinfo@springworkstx.com?subject=Medical%20Information%20Request"
                    class="d-inline-block"><span><i class="fas fa-envelope"></i></span> medinfo@springworkstx.com</a>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>