<!-- page titile start -->
<div class="container-fluid">
  <div class="row">
    <div class="page-title">
      <i class="fas fa-exclamation-triangle text-danger pr-1"></i> Unauthorized Access

    </div>
  </div>
</div>
<!-- page titile start -->

<div class="unathorizedtxt">
  <h6 class="text-danger">You do not have access privileges to the requested feature. Contact your administrator.</h6>
</div>
