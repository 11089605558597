import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { RegistrationErrors } from 'src/app/_enums/constant-types.enum';
import { VerificationPopupInput } from 'src/app/_models/register-response';
import { UserLoginResult } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AccountVerificationComponent } from '../account-verification/account-verification.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public onClose: Subject<boolean>;
  SubmitLoginForm: FormGroup;
  submitted = false;
  invalidCredentials = false;
  userNotVerified = false;
  userDeactivated = false;
  passwordExpired = false;
  noAccountFound = false;
  deactivatedDate?: Date;
  userLoginResult: UserLoginResult = {};

  public onLogin: EventEmitter<UserLoginResult> = new EventEmitter();

  constructor(private authService: AuthenticationService,
    public utilityService: UtilityService,
    private alertifyService:AlertifyService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService) {
      this.onClose = new Subject();
     }

  ngOnInit(): void {
    this.setFormGroup();
  }

  get form() {
    return this.SubmitLoginForm.controls;
  }

  get enableLogin()
  {
    let isValid = true;
    if((this.SubmitLoginForm.controls["USER_ID"].value != null
    && this.SubmitLoginForm.controls["USER_ID"].value.trim() == "")
    || (!this.SubmitLoginForm.controls["USER_ID"].value))
    {
      isValid = false;
    }
    if((this.SubmitLoginForm.controls["PASSWORD"].value != null
    && this.SubmitLoginForm.controls["PASSWORD"].value.trim() == "")
    || (!this.SubmitLoginForm.controls["PASSWORD"].value))
    {
      isValid = false;
    }
    return isValid;
  }

  setFormGroup() {
    this.SubmitLoginForm = new FormGroup({
      USER_ID: new FormControl("", Validators.required),
      PASSWORD: new FormControl("", Validators.required),
    });
  }

  login()
  {
    this.cleanForm();
    this.invalidCredentials = false;
    this.userNotVerified = false;
    this.userDeactivated = false;
    this.passwordExpired = false;
    this.noAccountFound = false;
    this.submitted = true;
    this.userLoginResult = {};
    let IsValid = true;
    let errrs: any = [];
    if (!this.SubmitLoginForm.controls["USER_ID"].valid) {
      errrs.push("Please enter User ID/Email Address");
      IsValid = false;
    }
    if (!this.SubmitLoginForm.controls["PASSWORD"].valid) {
      errrs.push("Please enter Password.");
      IsValid = false;
    }

    if (!IsValid) {
      let errMessage = '';
      for (const element of errrs) {
        errMessage += element + ' </br>';
      }
    }
    else
    {
      this.authService.login(this.form.USER_ID.value, this.form.PASSWORD.value)
      .subscribe(next => {
        this.userLoginResult = next;
        if (next.Errors && next.Errors.length > 0) {
          next.Errors.forEach(error => {
            switch (error) {
              case RegistrationErrors.InvalidCredentials:
                this.invalidCredentials = true;
                this.clearFormData();
                break;
              case RegistrationErrors.RegisteredNotVerified:
                this.userNotVerified = true;
                break;
              case RegistrationErrors.RegisteredDeactivated:
                this.deactivatedDate = next.DeactivatedDate;
                this.userDeactivated = true;
                break;
              case RegistrationErrors.PasswordExpired:
                this.passwordExpired = true;
                break;
              case RegistrationErrors.NoAccountFound:
                var errors:any=[];
                errors.push("No account exists with this Email Address. Please enter a valid registered Email Address or click Register to continue login.");
                this.alertifyService.errorBaner(errors);  
                this.noAccountFound = true;
                break;
            }
          });
        }
        else {
          this.onLogin.emit(next);
          this.close();
        }
        if(this.userNotVerified || this.userDeactivated)
        {
          this.openVerificationPopup(next);
        }
      },
        error => {
          console.error(error);
          var errors:any=[];
          errors.push("Oops! Please try after sometime.");
          this.alertifyService.errorBaner(errors);
        });
    }
  }

  openRegisterForm()
  {
    this.close();
    const initialState: ModalOptions = {
      backdrop : 'static',
      class: 'modal-xl modal-dialog-centered',  
      keyboard: false, 
    };
   this.bsModalRef = this.modalService.show(RegisterComponent,initialState);
   this.bsModalRef.content.closeBtnName = 'Close';
  }

  openForgotPassword()
  {
    this.close();
    const initialState: ModalOptions = {
      backdrop : 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
   this.bsModalRef = this.modalService.show(ForgotPasswordComponent,initialState);
   this.bsModalRef.content.closeBtnName = 'Close';
  }

  openVerificationPopup(loginResult: UserLoginResult)
  {
    this.close();
    let verificationInput = <VerificationPopupInput>{
      EmailID : loginResult.EmailID,
      RegisteredAlreadyVerified : false,
      RegisteredNotVerified : loginResult.RegisteredNotVerified,
      RegisteredDeactivated : loginResult.RegisteredDeactivated,
      DeactivatedDate : loginResult.DeactivatedDate,
    }
    const config: ModalOptions = {
      initialState : <any>verificationInput,
      backdrop : 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(AccountVerificationComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  clearFormData()
  {
    this.submitted = false;
    this.SubmitLoginForm.controls["USER_ID"].setValue("");
    this.SubmitLoginForm.controls["PASSWORD"].setValue("");
  }

  public cleanForm() {
    let count = 0;
    if (this.SubmitLoginForm != null && this.SubmitLoginForm != undefined) {
      Object.keys(this.SubmitLoginForm.controls).forEach(key => {

        let value: any = this.SubmitLoginForm.controls[key].value;
        if (this.SubmitLoginForm.controls[key].value != null && typeof (this.SubmitLoginForm.controls[key].value) === "string"
          && this.SubmitLoginForm.controls[key].value !== "") {
          value = this.SubmitLoginForm.controls[key].value.trim();
        }
        else if (this.SubmitLoginForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        value = this.utilityService.replaceEmojis(value);

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.SubmitLoginForm.controls[key].setValue(value);
        }

      });
    }
  }

  close()
  {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SubmitLoginForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }

    return true;
  }

}
