import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppConstants } from '../../shared/AppConstants';
import { ResourcesComponent } from '../components/resources/resources.component';
import { AuthenticationService } from './authentication.service';
import { HomeService } from './home.service';
import { UtilityService } from './utility.service';

//https://stackoverflow.com/questions/42110817/load-config-json-file-in-angular-2
//
//

@Injectable({
  providedIn: 'root'
})
export class AppPreSetupService {

  private _config: any;
  private _appSettings: string = '';

  constructor(private router: Router, private _http: HttpClient, private authService: AuthenticationService,
    public utilityService: UtilityService, private homeService: HomeService) { }

  load() {
    return new Promise((resolve, reject) => {
      this._appSettings = 'appSettings';
      if (environment.production)
        this._appSettings = 'appSettings';

      this._http.get('./assets/' + this._appSettings + '.json').subscribe(
        (data: any) => {
          this._config = data;
          AppConstants.PortalApiBaseUrl = data.PortalApiBaseUrl;
          AppConstants.PortalCode = data.PortalCode;
          AppConstants.ReportAttachmentMaxFileSize = data.ReportAttachmentMaxFileSize;
          AppConstants.LocalStorageTimeOutInSeconds = data.LocalStorageTimeOutInSeconds;
          AppConstants.PortalSuffix = data.PortalSuffix;
          AppConstants.SessionIdleTimeout = data.SessionIdleTimeout;
          AppConstants.AutoLogoutTimeout = data.AutoLogoutTimeout;
          AppConstants.SessionInactiveTime = data.SessionInactiveTime;
          AppConstants.ResetPasswordIdleTimeout = data.ResetPasswordIdleTimeout;
          //AppConstants.EncryptSecretKey = data.EncryptSecretKey;

          this.homeService.getPortal().subscribe((next) => {
            this.authService.portal = next;
            AppConstants.PortalCode = next.Code ?? "";
            AppConstants.EnvironmentId = next.FK_EnvironmentId;
            AppConstants.ReporterTypeId = next.ReporterTypeId;
            this.utilityService.portalTitle = next.PortalTitle;
            this.utilityService.reporterTypeName = next.ReporterTypeName ?? "US Healthcare Professional";
            this.utilityService.nonHcpReporterTypeName = next.NonHcpReporterTypeName ?? "Patient / Caregiver";
            this.utilityService.nonUSReporterTypeName = next.NonUsHcpReporterTypeName ?? "Non US Healthcare Professional";

            if (next.PortalComponents) {
              let r:Array<Route> = new Array<Route>();
              for (const element of next.PortalComponents) {
                if (element.Code == 'REQUEST_MI' && element.Status) {
                  r.push({
                    path: 'request-mi',
                    loadChildren: () => import('../request-mi/request-mi.module').then(r => r.RequestMIModule)
                  })
                } 
                else if (element.Code == 'MSL_REQUEST' && element.Status) {
                  r.push({
                    path: 'find-msl',
                    loadChildren: () => import('../msl/msl.module').then(m => m.MslModule),
                  })
                } 
                else if (element.Code == 'CONTENT_INFORMATION' && element.Status) {
                  r.push({
                    path: 'find-medical-info',
                    loadChildren: () => import('../find-medical/find-medical.module').then(m => m.FindMedicalModule)
                  })
                } 
                else if (element.Code == 'NEWS_AND_ANNOUNCEMENTS' && element.Status) {
                  r.push({
                    path: 'congress-presentation',
                    loadChildren: () => import('../congress-presentation/congress-presentaion.module').then(n => n.CongressPresentaionModule)
                    // loadChildren: () => import('../news-announcements/news-announcements.module').then(n => n.NewsAnnouncementsModule)
                  })
                } 
                else if (element.Code == 'REPORT_AE' && element.Status) {
                  r.push({
                    path: 'report-ae',
                    loadChildren: () => import('../report-ae/report-ae.module').then(r => r.ReportAEModule)
                  })
                } 
                else if (element.Code == 'REPORT_PC' && element.Status) {
                  r.push({
                    path: 'report-pc',
                    loadChildren: () => import('../report-pc/report-pc.module').then(r => r.ReportPCModule)
                  })
                } 
                else if (element.Code == 'EVENTS_AND_CONFERENCES' && element.Status) {
                  r.push({
                    path: 'events-conferences',
                    loadChildren: () => import('../events-conferences/events-conferences.module').then(e => e.EventsConferencesModule)
                  })
                } 
                else if (element.Code == 'OUR_PRODUCTS' && element.Status) {
                  r.push({
                    path: 'our-products',
                    loadChildren: () => import('../our-products/our-products.module').then(o => o.OurProductsModule)
                  })
                }
                else if (element.Code == 'RESOURCES' && element.Status) {
                  r.push({
                    path: 'resources',
                    component: ResourcesComponent
                  })
                }
              }
              this.router.resetConfig([...r, ...this.router.config]);
            }

            this.utilityService.setEnvironmentStorage(next);
            resolve(true);
          },
            (error) => {
              console.error(error);
              resolve(false);
            })
        },
        (error: any) => {
          console.error(error);
          resolve(false);
          return Observable.throw(error.json().error || 'Server error');
        });
    });
  }

  // Is app in the development mode?
  isDevmode() {
    return this._appSettings === 'development';
  }

  // Gets API route based on the provided key
  getApi(key: string): string {
    return this._config["PortalApiBaseUrl"][key];
  }

  // Gets a value of specified property in the configuration file
  get(key: any) {
    return this._config[key];
  }

}


export function AppPreSetupFactory(config: AppPreSetupService) {
  return () => config.load();
}

export function init() {
  return {
    provide: APP_INITIALIZER,
    useFactory: AppPreSetupFactory,
    deps: [AppPreSetupService],
    multi: true
  }
}

const AppPreSetupModule = {
  init: init
}

export { AppPreSetupModule };
