import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { RegistrationErrors } from 'src/app/_enums/constant-types.enum';
import { VerificationPopupInput } from 'src/app/_models/register-response';
import { User, UserParams } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AccountVerificationComponent } from '../account-verification/account-verification.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  public onClose: Subject<boolean>;
  forgotPasswordForm: FormGroup;
  submitted = false;
  userParams: UserParams = {};
  userDoesnotExists: boolean = false;
  userNotVerified = false;
  userDeactivated = false;
  noAccountFound = false;
  deactivatedDate?: Date;
  userResult: User | null;
  invalidEmail = false;

  constructor(private authService: AuthenticationService,
    public utilityService: UtilityService,
    private alertifyService:AlertifyService,
    public modalRef: BsModalRef,
    private modalService: BsModalService) {
      this.onClose = new Subject();
     }

  ngOnInit(): void {
    this.setFormGroup();
  }

  get form() {
    return this.forgotPasswordForm.controls;
  }

  get enableContinue()
  {
    let isValid = true;
    this.invalidEmail = false;
    if((this.forgotPasswordForm.controls["EmailID"].value != null
    && this.forgotPasswordForm.controls["EmailID"].value.trim() == "")
    || (!this.forgotPasswordForm.controls["EmailID"].value))
    {
      isValid = false;
    }
    if (this.forgotPasswordForm.controls["EmailID"].value != null && 
    this.forgotPasswordForm.controls["EmailID"].value != "" && 
    !this.utilityService.validateEmail(this.forgotPasswordForm.controls["EmailID"].value)) {
      this.invalidEmail = true;
      isValid = false;
    }
    return isValid;
  }

  setFormGroup() {
    this.forgotPasswordForm = new FormGroup({
      EmailID: new FormControl("", [Validators.required, Validators.email]),
    });
  }

  requestPassword()
  {
    this.cleanForm();
    this.userNotVerified = false;
    this.userDeactivated = false;
    this.noAccountFound = false;
    this.submitted = true;
    this.userResult = null;
    let IsValid = true;
    let errrs: any = [];
    if (!this.forgotPasswordForm.controls["EmailID"].valid) {
      errrs.push("Please enter registered Email Address");
      IsValid = false;
    }
    if (this.forgotPasswordForm.controls["EmailID"].value &&
    !this.utilityService.validateEmail(this.forgotPasswordForm.controls["EmailID"].value)) {
      errrs.push("Please enter valid Email Address.");
      this.invalidEmail = true;
      IsValid = false;
    }

    if (!IsValid) {
      let errMessage = '';
      for (var i = 0; i < errrs.length; i++) {
        errMessage += errrs[i] + ' </br>';
      }
    }
    else
    {
      this.userParams.EMAIL_ID = this.forgotPasswordForm.controls["EmailID"].value;
      this.authService.forgotPassword(this.userParams).subscribe(next => {
        this.userResult = next;
        if (next.Errors && next.Errors.length > 0) {
          next.Errors.forEach(error => {
            switch (error) {
              case RegistrationErrors.RegisteredNotVerified:
                this.userNotVerified = true;
                break;
              case RegistrationErrors.RegisteredDeactivated:
                this.deactivatedDate = next.DeactivatedDate;
                this.userDeactivated = true;
                break;
              case RegistrationErrors.NoAccountFound:
                var errors:any=[];
                errors.push("No account exists with this Email Address. Please enter a valid registered Email Address or click Register to continue login.");
                this.alertifyService.errorBaner(errors);  
                this.forgotPasswordForm.controls["EmailID"].setValue("");
                this.noAccountFound = true;
                break;
            }
          });
        }

        if (!this.noAccountFound) {

        //if (next != null) {
          this.close();
          this.openVerificationPopup(next);
        // }
        // else {
        //   this.userDoesnotExists = true;
        // }    
        } 
      },
      error => {
        console.error(error);
        var errors:any=[];
        errors.push("Oops! Please try after sometime.");
        this.alertifyService.errorBaner(errors);  
      });
    }
  }

  openRegisterForm()
  {
    this.close();
    const initialState: ModalOptions = {
      backdrop : 'static',
      class: 'modal-xl modal-dialog-centered',
      keyboard: false,
    };
    this.modalRef = this.modalService.show(RegisterComponent,initialState);
    this.modalRef.content.closeBtnName = 'Close';
  }

  openVerificationPopup(user: User | null)
  {
    if(user != null)
    {
      let verificationInput = <VerificationPopupInput>{
        PK_User_ID: user.PK_USER_ID,
        EmailID :  this.forgotPasswordForm.controls["EmailID"].value,
        RegisteredAlreadyVerified: false,
        RegisteredNotVerified : user.RegisteredNotVerified,
        RegisteredDeactivated : user.RegisteredDeactivated,
        DeactivatedDate : user.DeactivatedDate,
        Referrer: user.RegisteredNotVerified || user.RegisteredDeactivated ? "RegisteredNotVerified" : "ForgotPassword"
      }
      const config: ModalOptions = {
        initialState : <any>verificationInput,
        backdrop : 'static',
        class: 'modal-dialog-centered',
        keyboard: false
      };
      this.modalRef = this.modalService.show(AccountVerificationComponent,config);
      this.modalRef.content.closeBtnName = 'Close';
    }
  }

  public cleanForm() {
    let count = 0;
    if (this.forgotPasswordForm != null && this.forgotPasswordForm != undefined) {
      Object.keys(this.forgotPasswordForm.controls).forEach(key => {

        //console.log(this.SubmitRequestForm.controls[key].value + ": count:" + count++);
        //console.log(typeof (this.SubmitRequestForm.controls[key].value) + ": count:" + count++);

        let value: any = this.forgotPasswordForm.controls[key].value;
        if (this.forgotPasswordForm.controls[key].value != null && typeof (this.forgotPasswordForm.controls[key].value) === "string"
          && this.forgotPasswordForm.controls[key].value !== "") {
          value = this.forgotPasswordForm.controls[key].value.trim();
        }
        else if (this.forgotPasswordForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        value = this.utilityService.replaceEmojis(value);

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.forgotPasswordForm.controls[key].setValue(value);
        }

      });
    }
  }

  close()
  {
    this.modalRef.hide();
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.forgotPasswordForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }
    return true;
  }
}
