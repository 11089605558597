<div *ngIf="toast.messages.length > 0">
  <div class="actionmodels download-pop" id="successppr" style="display:block;z-index:1999;">
    <div class="modal-dialog mw-100" *ngFor="let message of toast.messages">
      <div class="modal-content">
        <div class="alertPopover text-center py-3 px-3" [ngClass]="{
           'successMessage session-success': (message.style == 'info'),
           'session-exp': (message.style == 'error')
           }">
          <button type="button" class="close" (click)="dismissMessage(message.id)">
            <span aria-hidden="true">×</span>
          </button>
          <p>
            <i class="pr-1" [ngClass]="{
              'far fa-check-circle': (message.style == 'info'),
              'fas fa-ban': (message.style == 'error')
              }"></i>{{message.content}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
