import { Component, Input, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
    selector: 'validation-banertemplate',
    template: `
        <div class="modelHeader"> {{MessageHeader}} </div>
        <div class="modal-body">
        <p class="changpwdList fs-12 pt-0" *ngIf="messageList.length==1">{{messageList[0]}}</p>
        <ul class="changpwdList fs-12 pt-0" *ngIf="messageList.length>1">
          <li *ngFor="let messge of messageList">{{messge}}</li>
        </ul>
              <div class="text-center">
                <button type="button" class="btn btn-primary sss" id="abc"  (click)="messageClose()">Ok</button>
              </div>
        </div>
     
        `,
    styles: [
       
    ]
})
export class ValidationsBanertemplate implements OnInit {

   // @Input() public Data:any;
    MessageHeader: string = ''
    messageList:any=[];
    result: Subject<boolean> = new Subject<boolean>();
    public onClose: Subject<boolean>;

    constructor(private _bsModalRef: BsModalRef) {
       
    }
    ngOnInit(): void {
        //this.messageList=this.Data.messageList;
        this.onClose = new Subject();
    }
    messageClose()
    {
      this.onClose.next(false);
      this._bsModalRef.hide();
    }

}

