export class Helper {
  static emailPattern = /^([0-9a-zA-Z]+[-._+&amp;])*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$/;


  public static Deserialize(data: string): any {
    return JSON.parse(data, Helper.ReviveDateTime);
  }

  public static ReviveDateTime(key: any, value: any): any {
    if (typeof value === 'string') {
      let a = /\/Date\((\d*)\)\//.exec(value);
      if (a) {
        return new Date(+a[1]);
      }
    }

    return value;
  }

  public static ISODateReviver(value: any): any {
    if (typeof value === 'string') {
      var a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:([\+-])(\d{2})\:(\d{2}))?Z?$/.exec(value);
      if (a) {
        var utcMilliseconds = Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]);
        return new Date(utcMilliseconds);
      }
    }
    return value;
  }

  public static ISODateOnlyReviver(value: any): any {
    if (typeof value === 'string') {
      var a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:([\+-])(\d{2})\:(\d{2}))?Z?$/.exec(value);
      if (a) {
        var utcMilliseconds = Date.UTC(+a[1], +a[2] - 1, +a[3]);
        return new Date(utcMilliseconds);
      }
    }
    return value;
  }

  public static ISODateToLocalDateOnlyReviver(value: any): any {
    if (typeof value === 'string') {
      var a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:([\+-])(\d{2})\:(\d{2}))?Z?$/.exec(value);
      if (a) {
        var localMilliseconds = new Date(+a[1], +a[2] - 1, +a[3]);
        return new Date(localMilliseconds);
      }
    }
    return value;
  }

  public static localDateToUtc(value: Date): any {
    if (value && value.constructor === Date) {
      if (value) {
        var utcMilliseconds = Date.UTC(value.getFullYear(), value.getMonth(), value.getDate());
        return new Date(utcMilliseconds);
      }
    }
    return value;
  }

  public static ConvertLocalDateToUTCIgnoringTimezone(date: Date): Date {
    if (date) {
      if (date.constructor !== Date) {
        date = new Date(date);
      }

      const timestamp = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
      );

      return new Date(timestamp);
    }
    return date;
  }

  public static isInvalidDate(date: Date) {
    return (date && ((typeof date == "string" && (date == "Invalid date" || new Date(date).toString() == "Invalid Date"))
      || (date && (typeof date == "object" && new Date(date).toString() == "Invalid Date"))));
  }

  public static isInvalidEmail(email: string) {
    if (!email) {
      return true;
    }

    return !this.emailPattern.test(email)
  }

  /**
   * Unique Key generator with Max length of 20
   */
  public static GetUniqueKey(): string {
    return "xxxxxxxxxxxxxxxxxxxx".replace(/[x]/g, function (c) {
      var r = (Math.random() * 16) | 0;
      return r.toString(16);
    });
  }

  /**
   * Merge defaults with user options
   * @param defaults Default settings
   * @param options User options
   * @returns Merged values of defaults and user options
   */
  public static extend(defaults: any, options: any): any {
    let extended: any = {};

    if (defaults) {

      for (var key in defaults) {
        if (defaults.hasOwnProperty(key)) {
          if (typeof defaults[key] === 'object')
            extended[key] = Helper.extend(extended[key], defaults[key]);
          else
            extended[key] = defaults[key];
        }
      }
    }

    if (options) {

      for (var key in options) {
        if (options.hasOwnProperty(key)) {
          if (typeof options[key] === 'object')
            extended[key] = Helper.extend(extended[key], options[key]);
          else
            extended[key] = options[key];
        }
      }
    }
    return extended;
  }

  public static appURI: string = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/";

}
