import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-bookmarked-content',
  templateUrl: './bookmarked-content.component.html',
  styleUrls: ['./bookmarked-content.component.css']
})
export class BookmarkedContentComponent implements OnInit {

  @ViewChild('staticModal', { static: false }) bsModalChild!: ModalDirective;

  constructor() { }

  ngOnInit(): void {
  }

  openModal() {
    this.bsModalChild.show();
  }

}
