import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { interval, Subscription } from 'rxjs';
import { CodeVerificationErrors } from 'src/app/_enums/constant-types.enum';
import { VerificationPopupInput } from 'src/app/_models/register-response';
import { AppUserInfo, User, UserParams } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from '../../../_services/utility.service';

@Component({
  selector: 'app-update-email-verification',
  templateUrl: './update-email-verification.component.html',
  styleUrls: ['./update-email-verification.component.css']
})
export class UpdateEmailVerificationComponent implements OnInit {
  public userData: VerificationPopupInput;
  SubmitVerificationForm: FormGroup;
  isActivated: boolean = false;
  isResendActivation: boolean = false;
  submitted = false;
  userParams: UserParams = {};
  inValidCode: boolean = false;
  codeExpired: boolean = false;

  private subscription: Subscription | null = null;
  public datenow = new Date();
  milliSecondsInASecond = 1000;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  public timeDifference: any;
  public secondsToDday: any;
  public minutesToDday: any;
  isDisabled: boolean = false;
  resulttag: boolean = false;
  public OnSubmit: EventEmitter<boolean> = new EventEmitter();

  constructor(private authService: AuthenticationService,
    private alertifyService:AlertifyService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    public options: ModalOptions,
    private utilityService: UtilityService,  ) { }

  private getTimeDifference() {
    let countDownDate = new Date().setMilliseconds(-120000);
    this.timeDifference = this.datenow.getTime() - countDownDate;
    if (this.timeDifference <= 0) {
      this.isDisabled = false;
    }
    else {
      this.allocateTimeUnits(this.timeDifference);
    }
  }
  private allocateTimeUnits(timeDifference: any) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
  }

  ngOnInit(): void {
    this.userData = this.options.initialState as any as VerificationPopupInput;
    this.userParams.PK_USER_ID = this.userData.PK_User_ID ?? "";
    this.userParams.EMAIL_ID = this.userData.EmailID ?? "";
    this.setFormGroup();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  get form() {
    return this.SubmitVerificationForm.controls;
  }
  
  get enableVerify()
  {
    let isValid = true;
    if (this.SubmitVerificationForm.controls["Code"].value == "") {
      isValid = false;
    }
    return isValid;
  }

  get showTimer() {
    return this.isDisabled;
  }

  get enableLink() {
    return !this.isDisabled;
  }

  setFormGroup() {
    this.SubmitVerificationForm = new FormGroup({
      Code: new FormControl("", [Validators.required, Validators.pattern('[0-9]*')]),
    });
  }

  verify()
  {
    this.cleanForm();
    this.submitted = true;
    let isValid = true;
    this.inValidCode = false;
    this.codeExpired = false;
    let errrs: any = [];
    if (!this.SubmitVerificationForm.controls["Code"].valid) {
      errrs.push("Please enter Code");
      isValid = false;
    }
    if (!isValid) {
      let errMessage = '';
      for (const element of errrs) {
        errMessage += element + ' </br>';
      }
    }
    else
    {
      this.userParams.VERIFICATION_CODE = this.SubmitVerificationForm.controls["Code"].value;
      this.authService.RegisteredEmailActivation(this.userParams)
      .subscribe(next => {             
        if (next.Success) {
          let objUser = localStorage.getItem('cpappuserinfo');
          if (objUser)
          {
            let appUserInfo:AppUserInfo = JSON.parse(objUser);
            appUserInfo.EmailID = this.userParams.EMAIL_ID;
            localStorage.setItem('cpappuserinfo', JSON.stringify(appUserInfo));
            this.authService.hcpDetails.next(appUserInfo);
          }

          this.isActivated = true;
          this.alertifyService.successBaner("Your registered email address is updated.");
          this.resulttag = true;
          this.close();
          //window.location.reload();
        }
        else
        {
          if (next.Errors.length > 0) {
            next.Errors.forEach(error => {
              switch (error) {
                case CodeVerificationErrors.InValidCode:
                  this.inValidCode = true;
                  break;
                case CodeVerificationErrors.CodeExpired:
                  var errors:any=[];
                  errors.push("Your verification code has expired. Please click on Resend Code.");
                  this.alertifyService.errorBaner(errors);  
                  this.SubmitVerificationForm.controls["Code"].setValue("");
                  //this.codeExpired = true;
                  break;
                case CodeVerificationErrors.EmailExists:
                  var errors: any = [];
                  errors.push("Email Address you have entered is already linked to another account. Please use different Email Address.");
                  this.alertifyService.errorBaner(errors);
                  this.close();
                  break;
              }
            });
          }
        }
      },
      error => {
        console.error(error);
        var errors:any=[];
        errors.push("Oops! Please try after sometime.");
        this.alertifyService.errorBaner(errors);
      });
    }
  }

  resendCode() {
    this.isDisabled = true;
    this.datenow = new Date();
    this.subscription = interval(1000).subscribe(((x: any) => { this.getTimeDifference(); }));
    this.authService.resendUpdateRegisteredEmailLink(this.userParams).subscribe(next => {
      this.isResendActivation = next;
    },
    error => {
      console.error(error);
    });
  }

  activateVerifyPopup()
  {
    this.authService.resendUpdateRegisteredEmailLink(this.userParams).subscribe(next => {
      this.isActivated = false;
    },
    error => {
      console.error(error);
    });
  }

  public cleanForm() {
    let count = 0;
    if (this.SubmitVerificationForm != null && this.SubmitVerificationForm != undefined) {
      Object.keys(this.SubmitVerificationForm.controls).forEach(key => {

        let value: any = this.SubmitVerificationForm.controls[key].value;
        if (this.SubmitVerificationForm.controls[key].value != null && typeof (this.SubmitVerificationForm.controls[key].value) === "string"
          && this.SubmitVerificationForm.controls[key].value !== "") {
          value = this.SubmitVerificationForm.controls[key].value.trim();
        }
        else if (this.SubmitVerificationForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.SubmitVerificationForm.controls[key].setValue(value);
        }

      });
    }
  }

  close()
  {
    this.modalRef.hide();
    this.OnSubmit.emit(this.resulttag);
  }


  handleInput($event: any, controlName: string): boolean {
    const control = this.SubmitVerificationForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }

    return true;
  }

}
