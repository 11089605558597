import { Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { CountryAutoComplete } from 'src/app/_models/countries';
import { CP_VW_LOOKUPS } from 'src/app/_models/cpvw-lookups';
import { Occupation, PreferredContact } from 'src/app/_models/msl';
import { PASSWORD_POLICY } from 'src/app/_models/password-policy';
import { AppUserInfo, User } from 'src/app/_models/user';
import { ZipCode } from 'src/app/_models/zipcode';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SystemConfigService } from 'src/app/_services/system-config.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ZipcodeSelectionComponent } from '../../../components/Common/zipcode-selection/zipcode-selection.component';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  public userData: AppUserInfo;
  public onClose: Subject<boolean>;
  config: any = '';
  bsConfig: any;
  EditProfileForm: FormGroup;
  //allCountries: COUNTRIES[] = [];
  allCountries: CountryAutoComplete[] = [];
  preferedContacts: PreferredContact[] = [];
  occupationList: Occupation[] = [];
  specialityList: CP_VW_LOOKUPS[] = [];
  passwordPolicy: PASSWORD_POLICY = {};
  private subscribeDisplayCountries: Subscription | null = null;
  selectedCountry: string | '' = '';
  invalidPreferredEmail = false;
  emailIdAlreadyExists = false;
  duplicateUserId = false;
  duplicateLicense = false;
  registeredAlreadyVerified = false;
  registeredNotVerified = false;
  registeredDeactivated = false;
  minDate: Date = new Date();
  pattern = this.utilityService.getPasswordPolicyPattern();

  isPhoneMandatory = false;
  isEmailMandatory = false;

  public onSuccess: EventEmitter<boolean> = new EventEmitter();

  constructor(private configService: SystemConfigService,
    private authService: AuthenticationService,
    public utilityService: UtilityService,
    private alertfyService: AlertifyService,
    private formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    public passwordPolicyModal: BsModalRef,
    private modalService: BsModalService,
    public zipCodeModal: BsModalRef,
    public options: ModalOptions,
    private titleService: Title
  ) {
    this.titleService.setTitle('My Profile');
    this.minDate.setFullYear(this.minDate.getFullYear() - 16);
  }

  ngOnInit(): void {
    this.userData = this.options.initialState as any as AppUserInfo;

    this.setFormGroup();
    this.getAllCountries();
    this.loadLookUps();

    if (this.authService.isCookie()) {
      this.authService.cookieTracking().subscribe();
    }
  }

  get form() {
    return this.EditProfileForm.controls;
  }

  //get minDate() {
  //  let date: Date = new Date();
  //  date.setFullYear(date.getFullYear() - 16);
  //  return date;
  //}

  Ondatechange(value: Date, fcn: string) {
    this.utilityService.resetCalendar(value, fcn, this.EditProfileForm)
  }

  setFormGroup() {
    this.bsConfig = Object.assign({}, {
      containerClass: "theme-blue",
      dateInputFormat: 'DD-MMM-YYYY',
      showWeekNumbers: false,
      maxDate: this.minDate
    });
    this.config = {
      type: 1,
      length: 6,
      cssClass: 'custom',
      back: {
        stroke: "#2F9688",
        solid: "#f2efd2"
      },
      font: {
        color: "#000000",
        size: "35px"
      }
    };

    this.EditProfileForm = this.formBuilder.group({
      FIRST_NAME: new FormControl(this.userData.FIRST_NAME, Validators.required),
      LAST_NAME: new FormControl(this.userData.LAST_NAME, Validators.required),
      // DATE_OF_BIRTH: new FormControl(new Date(this.userData.DATE_OF_BIRTH ?? ""), [Validators.required, this.AgeValidator]),
      // ZIP_CODE: new FormControl(this.userData.ZIP_CODE, Validators.required),
      FK_COUNTRY_ID: new FormControl(this.userData.FK_COUNTRY_ID, Validators.required),
      // CITY: new FormControl(this.userData.CITY),
      // STATE_PROVINCE: new FormControl(this.userData.STATE_PROVINCE),
      ADDRESS: new FormControl(this.userData.ADDRESS),
      PHONE: new FormControl(this.userData.PHONE, [Validators.required, Validators.min(10), Validators.pattern('[+()-_0-9]*')]),
      PHONE_EXT: new FormControl(this.userData.PHONE_EXT, [Validators.pattern('[0-9]*')]),
      FK_PREFERRED_CONTACT_ID: new FormControl(this.userData.FK_PREFERRED_CONTACT_ID?.toString(), Validators.required),
      PREFERED_EMAIL: new FormControl(this.userData.PREFERED_EMAIL),
      PREFERED_PHONE: new FormControl(this.userData.PREFERED_PHONE, [Validators.min(10), Validators.pattern(/[^0-9()-]*/g)]),
      PREFERED_PHONE_EXT: new FormControl(this.userData.PREFERED_PHONE_EXT),
      HAS_RECEIVE_EMAILS: new FormControl({value: this.userData.HAS_RECEIVE_EMAILS  != null ? this.userData.HAS_RECEIVE_EMAILS : false, disabled: this.userData.PREFERED_EMAIL ? false: true}),
      HAS_ALLOW_CALLS: new FormControl({value: this.userData.HAS_ALLOW_CALLS != null ? this.userData.HAS_ALLOW_CALLS : false, disabled: this.userData.PREFERED_PHONE ? false: true}),
      FK_PROFESSION_ID: new FormControl(this.userData.FK_PROFESSION_ID, Validators.required),
      FK_SPECAILITY_ID: new FormControl(this.userData.FK_SPECAILITY_ID),
      // LICENCE_NUMBER: new FormControl({ value: this.userData.LICENCE_NUMBER, disabled: true }, Validators.required),
      // PRIMARY_PRACTICE: new FormControl(this.userData.PRIMARY_PRACTICE)
    });

    if(this.userData.FK_SPECAILITY_ID == null) {
      this.EditProfileForm.controls["FK_SPECAILITY_ID"].setValue("");
    }
    this.onChanges();
  }

  onChanges() {
    const contactPreferenceControl = <FormControl>this.EditProfileForm.get('FK_PREFERRED_CONTACT_ID');
    const preferredEmailControl = <FormControl>this.EditProfileForm.get('PREFERED_EMAIL');
    const preferredPhoneControl = <FormControl>this.EditProfileForm.get('PREFERED_PHONE');
    const otherPhoneControl = <FormControl>this.EditProfileForm.get('PHONE');
    // const zipCodeControl = <FormControl>this.EditProfileForm.get('ZIP_CODE');

    preferredEmailControl.valueChanges
    .subscribe(value => {
      if(value == undefined || value == null || value == "")
      {
        this.EditProfileForm.controls["HAS_RECEIVE_EMAILS"]?.disable();
        this.EditProfileForm.controls["HAS_RECEIVE_EMAILS"].setValue(false);
      }
      else
      {
        this.EditProfileForm.controls["HAS_RECEIVE_EMAILS"]?.enable();
      }
    });

    preferredPhoneControl.valueChanges
    .subscribe(value => {
      if((value == undefined || value == null || value == "")
      && (otherPhoneControl.value  == undefined || otherPhoneControl.value == null || otherPhoneControl.value == ""))
      {
        this.EditProfileForm.controls["HAS_ALLOW_CALLS"]?.disable();
        this.EditProfileForm.controls["HAS_ALLOW_CALLS"].setValue(false);
      }
      else
      {
        this.EditProfileForm.controls["HAS_ALLOW_CALLS"]?.enable();
      }
    });

    otherPhoneControl.valueChanges
    .subscribe(value => {
      if((value == undefined || value == null || value == "")
      && (preferredPhoneControl.value  == undefined || preferredPhoneControl.value == null || preferredPhoneControl.value == ""))
      {
        this.EditProfileForm.controls["HAS_ALLOW_CALLS"]?.disable();
        this.EditProfileForm.controls["HAS_ALLOW_CALLS"].setValue(false);
      }
      else
      {
        this.EditProfileForm.controls["HAS_ALLOW_CALLS"]?.enable();
      }
    });

    contactPreferenceControl.valueChanges
      .subscribe(value => {
        this.invalidPreferredEmail = false;
        let selectedValue = this.preferedContacts.find(x => x.Id == this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].value);
        if (selectedValue != undefined && selectedValue.ContactCode == "EMAIL") {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = true;
          this.EditProfileForm.get('PREFERED_EMAIL')?.setValidators([Validators.required, Validators.email]);
          //this.EditProfileForm.get('PREFERED_PHONE')?.clearValidators();
          this.EditProfileForm.get('PREFERED_PHONE')?.setValidators([Validators.pattern(/[^0-9()-]*/g)]);
          // this.EditProfileForm.controls["HAS_RECEIVE_EMAILS"]?.enable();
          // this.EditProfileForm.controls["HAS_ALLOW_CALLS"]?.disable();
        }
        else if (selectedValue != undefined && selectedValue.ContactCode == "PHONE") {
          this.isPhoneMandatory = true;
          this.isEmailMandatory = false;
          this.EditProfileForm.get('PREFERED_PHONE')?.setValidators([Validators.required, Validators.pattern(/[^0-9()-]*/g)]);
          //this.EditProfileForm.get('PREFERED_EMAIL')?.clearValidators();
          this.EditProfileForm.get('PREFERED_EMAIL')?.setValidators([Validators.email]);
        }
        else {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = false;
        }
        preferredEmailControl.updateValueAndValidity();
        preferredPhoneControl.updateValueAndValidity();
      });

    // zipCodeControl.valueChanges
    //   .subscribe(value => {
    //     let country = this.allCountries.find(c => c.PKCountryId == this.EditProfileForm.controls["FK_COUNTRY_ID"].value);
    //     if (country?.CountryCode == 'US') {
    //       if (value.length > 4) {
    //         this.subscribeDisplayCountries = this.configService.getZipCodes(value).subscribe(zipCodes => {
    //           if (zipCodes.length == 1) {
    //             this.setCountryWithZipCode(zipCodes[0]);
    //           }
    //           else if (zipCodes.length > 1) {
    //             this.EditProfileForm.controls["CITY"].setValue("");
    //             this.EditProfileForm.controls["STATE_PROVINCE"].setValue("");
    //             this.openMultipleZipCodePopup(zipCodes);
    //           }
    //         },
    //           error => {
    //             console.error(error);
    //           });
    //       }
    //     }
    //   });
  }

  // openMultipleZipCodePopup(zipCodes: ZipCode[]) {
  //   const config: ModalOptions = {
  //     initialState: <any>zipCodes,
  //     backdrop: 'static',
  //     class: 'modal-dialog-centered',
  //     keyboard: false,
  //   };

  //   this.zipCodeModal = this.modalService.show(ZipcodeSelectionComponent, config);
  //   this.zipCodeModal.content.closeBtnName = 'Close';
  //   this.zipCodeModal.content.event.subscribe((res: ZipCode) => {
  //     this.setCountryWithZipCode(res);
  //   });
  // }

  // setCountryWithZipCode(zipcode: ZipCode) {
  //   this.EditProfileForm.controls["CITY"].setValue(zipcode.CITY);
  //   this.EditProfileForm.controls["STATE_PROVINCE"].setValue(zipcode.CITYSTATE);
  //   let country = this.allCountries.find(c => c.CountryCode == zipcode.COUNTRYCODE);
  //   this.selectedCountry = country?.CountryDisplayName ?? '';
  //   this.EditProfileForm.controls["FK_COUNTRY_ID"].setValue(country?.PKCountryId);
  // }

  loadLookUps() {
    this.getPreferedContacts();
    this.getOccupations();
    this.getSpeciality();
  }

  getAllCountries() {
    this.subscribeDisplayCountries = this.configService.getCountriesTypeHeadData().subscribe(next => {
      this.allCountries = next;
      let user = this.options.initialState as any as AppUserInfo;
      let country = this.allCountries.find(c => c.PKCountryId == user.FK_COUNTRY_ID);
      if (country != undefined) {
        this.selectedCountry = country.CountryDisplayName;
      }
    },
      error => {
        console.error(error);
      });
  }

  getPreferedContacts() {
    this.configService.getPreferredContact().subscribe(next => {
      this.preferedContacts = next;
      this.CP_Change(this.EditProfileForm.get('FK_PREFERRED_CONTACT_ID')?.value);
    },
    error => {
      console.error(error);
    });
  }

  getOccupations() {
    this.configService.getOccupations().subscribe(next => {
      this.occupationList = next;
    },
      error => {
        console.error(error);
      });
  }

  getSpeciality() {
    this.configService.getLookUps('SPECIALITY').subscribe(next => {
      this.specialityList = next;
    },
      error => {
        console.error(error);
      });
  }

  AgeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value != "Invalid Date") {
      let timeDiff = Date.now() - control.value.getTime();
      let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
      if (age < 16) {
        return { 'age': true };
      }
    }
    return null;
  }

  Submit() {
    this.cleanForm();
    this.emailIdAlreadyExists = false;
    this.duplicateUserId = false;
    this.duplicateLicense = false;
    this.registeredNotVerified = false;
    this.registeredDeactivated = false;
    if (this.validateForm() && this.isModified()) {
      let user: User = <User>{
        PK_USER_ID: this.userData.PK_UserId,
        REGISTERED_EMAIL_ID: this.userData.EmailID,
        FIRST_NAME: this.EditProfileForm.controls["FIRST_NAME"].value,
        LAST_NAME: this.EditProfileForm.controls["LAST_NAME"].value,
        // DATE_OF_BIRTH: this.EditProfileForm.controls["DATE_OF_BIRTH"].value,
        FK_COUNTRY_ID: this.EditProfileForm.controls["FK_COUNTRY_ID"].value,
        // ZIP_CODE: this.EditProfileForm.controls["ZIP_CODE"].value,
        // STATE_PROVINCE: this.EditProfileForm.controls["STATE_PROVINCE"].value,
        // CITY: this.EditProfileForm.controls["CITY"].value,
        ADDRESS: this.EditProfileForm.controls["ADDRESS"].value,

        FK_PREFERRED_CONTACT_ID: this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].value,
        PREFERED_EMAIL: this.EditProfileForm.controls["PREFERED_EMAIL"].value,
        PREFERED_PHONE: this.EditProfileForm.controls["PREFERED_PHONE"].value,
        PREFERED_PHONE_EXT: this.EditProfileForm.controls["PREFERED_PHONE_EXT"].value,
        PHONE: this.EditProfileForm.controls["PHONE"].value,
        PHONE_EXT: this.EditProfileForm.controls["PHONE_EXT"].value,
        HAS_RECEIVE_EMAILS: this.EditProfileForm.controls["HAS_RECEIVE_EMAILS"].value?? false,
        HAS_ALLOW_CALLS: this.EditProfileForm.controls["HAS_ALLOW_CALLS"].value?? false,

        FK_PROFESSION_ID: this.EditProfileForm.controls["FK_PROFESSION_ID"].value,
        FK_SPECAILITY_ID: this.EditProfileForm.controls["FK_SPECAILITY_ID"].value,
        // LICENCE_NUMBER: this.EditProfileForm.controls["LICENCE_NUMBER"].value,
        // PRIMARY_PRACTICE: this.EditProfileForm.controls["PRIMARY_PRACTICE"].value
      };
      this.authService.editProfile(user).subscribe(next => {
        if (next.Errors != null && next.Errors.length <= 0) {
          this.alertfyService.successBaner("Your profile details are saved.");
          let appUserInfo: AppUserInfo = this.utilityService.getAppUserInfoObject(next);
          localStorage.removeItem('cpappuserinfo');
          localStorage.setItem('cpappuserinfo', JSON.stringify(appUserInfo));
          this.authService.hcpDetails.next(appUserInfo);
          this.authService.UserName.next(appUserInfo.UserFullName ?? "");
          this.onSuccess.emit(true);
          this.close();
        }
        else {
          if (next.Errors != null && next.Errors.length > 0) {
            next.Errors.forEach(error => {

            });
          }
        }
      },
        error => {
          console.error(error);
        });
    }
  }

  formControlValid(controlName: string): boolean
  {
    let isValid = true;
    if((this.EditProfileForm.controls[controlName].value != null
    && this.EditProfileForm.controls[controlName].value.trim() == "")
    || (!this.EditProfileForm.controls[controlName].value))
    {
      isValid = false;
    }
    return isValid;
  }

  isModified(): boolean {
    let isModified: boolean = false;

    this.userData.HAS_ALLOW_CALLS = this.userData.HAS_ALLOW_CALLS != null ? this.userData.HAS_ALLOW_CALLS : false;
    this.userData.HAS_RECEIVE_EMAILS = this.userData.HAS_RECEIVE_EMAILS  != null ? this.userData.HAS_RECEIVE_EMAILS : false;
    let hasAllowCallls = this.EditProfileForm.controls["HAS_ALLOW_CALLS"].value?? false;
    let hasRecieveMails = this.EditProfileForm.controls["HAS_RECEIVE_EMAILS"].value?? false;

    if(this.userData.FIRST_NAME != this.EditProfileForm.controls["FIRST_NAME"].value
      || this.userData.LAST_NAME != this.EditProfileForm.controls["LAST_NAME"].value
      || this.userData.FK_COUNTRY_ID != this.EditProfileForm.controls["FK_COUNTRY_ID"].value
      || this.userData.ADDRESS != this.EditProfileForm.controls["ADDRESS"].value
      || this.userData.FK_PREFERRED_CONTACT_ID != this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].value
      || this.userData.PREFERED_EMAIL != this.EditProfileForm.controls["PREFERED_EMAIL"].value
      || this.userData.PHONE != this.EditProfileForm.controls["PHONE"].value
      || this.userData.PHONE_EXT != this.EditProfileForm.controls["PHONE_EXT"].value
      || this.userData.PREFERED_PHONE != this.EditProfileForm.controls["PREFERED_PHONE"].value
      || this.userData.PREFERED_PHONE_EXT != this.EditProfileForm.controls["PREFERED_PHONE_EXT"].value
      || this.userData.HAS_ALLOW_CALLS != hasAllowCallls
      || this.userData.HAS_RECEIVE_EMAILS != hasRecieveMails
      || this.userData.FK_PROFESSION_ID != this.EditProfileForm.controls["FK_PROFESSION_ID"].value
      || this.userData.FK_SPECAILITY_ID != this.EditProfileForm.controls["FK_SPECAILITY_ID"].value) {
      isModified = true;
    }

    if(!isModified) {
      let msgs: string[] = [];
      msgs.push("No changes were performed.");
      this.alertfyService.validationBaner(msgs, "Validation(s)");
    }
    return isModified;
  }

  validateForm() {
    let IsValid = true;

    if(!this.formControlValid("FIRST_NAME"))
    {
      IsValid = false;
    }
    if(!this.formControlValid("LAST_NAME"))
    {
      IsValid = false;
    }
    // if ((this.EditProfileForm.controls["DATE_OF_BIRTH"].value == null ||
    //   this.EditProfileForm.controls["DATE_OF_BIRTH"].value == "") ||
    //   (!this.EditProfileForm.controls["DATE_OF_BIRTH"].valid)) {
    //   IsValid = false;
    // }
    if ((this.EditProfileForm.controls["FK_COUNTRY_ID"] == null ||
      this.EditProfileForm.controls["FK_COUNTRY_ID"].value == "") ||
      (!this.EditProfileForm.controls["FK_COUNTRY_ID"].valid)) {
      IsValid = false;
    }


    if (this.EditProfileForm.controls["PREFERED_EMAIL"].value && !this.utilityService.validateEmail(this.EditProfileForm.controls["PREFERED_EMAIL"].value)) {
      this.invalidPreferredEmail = true;
      IsValid = false;
    }
    if (this.EditProfileForm.controls["PREFERED_EMAIL"].value && this.EditProfileForm.controls["PREFERED_EMAIL"].errors?.email) {
      this.invalidPreferredEmail = true;
      IsValid = false;
    }

    if ((this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"] == null ||
      this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].value == "") ||
      (!this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].valid)) {
      IsValid = false;
    }
    else {
      let selectedValue = this.preferedContacts.find(x => x.Id == this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].value);
      if (selectedValue != undefined &&
        selectedValue.ContactCode == "EMAIL") {
        if (!this.EditProfileForm.controls["PREFERED_EMAIL"].value) {
          IsValid = false;
        }
        else if (this.EditProfileForm.controls["PREFERED_EMAIL"].value
        && !this.utilityService.validateEmail(this.EditProfileForm.controls["PREFERED_EMAIL"].value)) {
          this.invalidPreferredEmail = true;
          IsValid = false;
        }
      }
      else if (selectedValue != undefined &&
        selectedValue.ContactCode == "PHONE") {
        if (!this.EditProfileForm.controls["PREFERED_PHONE"].value) {
          IsValid = false;
        }
        if (this.EditProfileForm.controls["PREFERED_EMAIL"].value 
        && !this.utilityService.validateEmail(this.EditProfileForm.controls["PREFERED_EMAIL"].value)) {
          this.invalidPreferredEmail = true;
          IsValid = false;
        }
      }
    }

    if(this.EditProfileForm.controls["PREFERED_PHONE"].value && this.EditProfileForm.controls["PREFERED_PHONE"].value.length < 10) {
      IsValid = false;
    }
    if(this.EditProfileForm.controls["PHONE"].value && this.EditProfileForm.controls["PHONE"].value.length < 10) {
      IsValid = false;
    }
    if ((this.EditProfileForm.controls["FK_PROFESSION_ID"] == null ||
      this.EditProfileForm.controls["FK_PROFESSION_ID"].value == "") ||
      (!this.EditProfileForm.controls["FK_PROFESSION_ID"].valid)) {
      IsValid = false;
    }
    // if ((this.EditProfileForm.controls["FK_SPECAILITY_ID"] == null ||
    //   this.EditProfileForm.controls["FK_SPECAILITY_ID"].value == "") ||
    //   (!this.EditProfileForm.controls["FK_SPECAILITY_ID"].valid)) {
    //   IsValid = false;
    // }

    return IsValid;
  }

  public cleanForm() {
    if (this.EditProfileForm != null && this.EditProfileForm != undefined) {
      Object.keys(this.EditProfileForm.controls).forEach(key => {

        let value: any = this.EditProfileForm.controls[key].value;
        if (this.EditProfileForm.controls[key].value != null && typeof (this.EditProfileForm.controls[key].value) === "string"
          && this.EditProfileForm.controls[key].value !== "") {
          value = this.EditProfileForm.controls[key].value.trim();
        }
        else if (this.EditProfileForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.EditProfileForm.controls[key].setValue(value);
        }

      });
    }
  }

  close() {
    this.modalRef?.hide();
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.EditProfileForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    if(controlName != "ZIP_CODE")
    {
      control.setValue($event.value);
    }
    else
    {
      control.setValue($event.value,{emitEvent:false});
    }
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      if(controlName != "ZIP_CODE")
      {
        control.setValue($event.value);
      }
      else
      {
        control.setValue($event.value,{emitEvent:false});
      }
      return false;
    }

    return true;
  }

  keyPress(event: KeyboardEvent, fcn: string, controlid: string, mmtype: string, dateval?: Date | undefined) {
    this.utilityService.OnkeyupCalendar(event, fcn, this.EditProfileForm, controlid, mmtype,dateval);
  }
  
ValidateEmail() {
    let IsValid = true;
    this.invalidPreferredEmail = false;
    if (this.EditProfileForm.controls["PREFERED_EMAIL"].value && !this.utilityService.validateEmail(this.EditProfileForm.controls["PREFERED_EMAIL"].value)) {
      this.invalidPreferredEmail = true;
      IsValid = false;
    }
    if (this.EditProfileForm.controls["PREFERED_EMAIL"].value && this.EditProfileForm.controls["PREFERED_EMAIL"].errors?.email) {
      this.invalidPreferredEmail = true;
      IsValid = false;
    }
  }

  MinMaxDateValidation(event: any, fcn: string, MinMaxFlag?: string, MinMaxDateVal?: Date) {
    this.utilityService.ValidateDate(event.target.value, fcn, MinMaxFlag, MinMaxDateVal, this.EditProfileForm);
  }

  CP_Change(productID: string = '') {
    let _currentCP = this.preferedContacts.filter((a: PreferredContact) => a.Id == productID)[0];
    if (_currentCP != null && _currentCP != undefined) {
      this.isEmailMandatory = _currentCP.ContactName == "Email" ? true : false;
      this.isPhoneMandatory = _currentCP.ContactName == "phone" ? true : false;
    }
  }
}
