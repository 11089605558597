import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { RequestService } from 'src/app/_services/request.service';
import { RequestAckModel, RequestModel } from '../../../_models/request-model';

@Component({
  selector: 'app-view-ack',
  templateUrl: './view-ack.component.html',
  styleUrls: ['./view-ack.component.css']
})
export class ViewAckComponent implements OnInit {
  Data: any;
  RequestData: any;
  RequestAckData: RequestAckModel;
  @BlockUI()
  blockUI!: NgBlockUI;


  constructor(private requestService: RequestService,
    public modalRef: BsModalRef,
    public options: ModalOptions  ) { }

  ngOnInit(): void {
    this.Data = this.options.initialState as any;
    this.RequestData = this.Data.Request as RequestModel;
    if(this.RequestData)
    {
      this.requestService.GetRequestAckDetails(this.RequestData.PkRequestID).subscribe(next => {
        if (next != undefined && next != null) {
          this.RequestAckData = next;
        }
        else {
          this.RequestAckData = <RequestAckModel>{};
        }
      },
        err => {
          this.RequestAckData = <RequestAckModel>{};
        }
      );
    }
  }

  close() {
    this.RequestData = [];
    this.modalRef.hide();
  }

}
