import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadDirective, TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CountryControlService } from 'src/app/_services/country-control.service';
import { UtilityService } from '../../_services/utility.service';
import { escape } from 'lodash';
import { CountryAutoComplete } from 'src/app/_models/countries';

@Component({
  selector: 'app-country-control',
  templateUrl: './country-control.component.html',
  styleUrls: ['./country-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryControlComponent),
      multi: true,
    },
  ],
})
export class CountryControlComponent implements OnInit, ControlValueAccessor {
  @Input("inputAllCountries") inputAllCountries: CountryAutoComplete[] = [];
  @Input("inputSelectedCountry") inputSelectedCountry: string | '' = '';
  @ViewChild('typeahead') typeahead: TypeaheadDirective;

  private _value: any;
  public disabled: boolean;
  onChange: any = () => { }
  onTouch: any = () => { }

  isCountryInvalid: boolean = false;
  typeaheadNoResults: boolean = false;
  countryTypeaheadLoading: boolean;
  typeaheadHideResultsOnBlur: boolean = true;
  countryDataSource$: Observable<CountryAutoComplete[]>;
  typeaheadMinLength = 0;

  constructor(private countryControlService: CountryControlService,
    public utilityService: UtilityService,) { }

  get value(): any { return this._value; };

  set value(val: any) {
    this._value = val;
    this.onChange(val);
  }

  writeValue(value: string) {
    if (value == "") {
      this.inputSelectedCountry = '';
    }
    else {
      this.value = value;
      let country = this.inputAllCountries.find(c => c.PKCountryId != null && c.PKCountryId == this.value);
      this.inputSelectedCountry = country?.CountryDisplayName??"";
    }
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.filterCountries();
  }

  onCountryChange($event: any) {
    if ($event == "") {
      this.onChange(undefined);
    }
  }

  filterCountries() {
    this.countryDataSource$ = Observable.create((observer: any) => {  // Runs on every search
      observer.next(this.inputSelectedCountry);
    }).pipe(
      switchMap((searchKey: string) =>
        this.countryControlService.searchCountries(this.inputAllCountries, searchKey.trim())
      ));
  }

  fixedHighlight(match: TypeaheadMatch, query: string[]): any {
    // Avoid calling
    query = query.filter((value) => value.trim().length > 0);
    match = new TypeaheadMatch(match.item, escape(match.value), match.isHeader());

    let res: any;

    if (this.typeahead && this.typeahead._container) {
      res = this.typeahead._container?.highlight(match, query);
    }
    return res;
  }

  onCountrySelect($event: TypeaheadMatch): void {
    if ($event.item && typeof $event.item === 'object' && $event.item !== null && $event.item?.PKCountryId != null && $event.item?.PKCountryId != '') {
      this.typeaheadNoResults = false;
      if($event.item != null && $event.item != undefined)
        this.inputSelectedCountry = $event.item.CountryDisplayName;

    } else {
      this.inputSelectedCountry = '';
    }
    if($event.item != null && $event.item != undefined)
      this.onChange($event.item.PKCountryId);
  }

  countryTypeaheadOnBlur($event: any): void {
    let filteredCountries = this.inputAllCountries.filter(p => p.CountryDisplayName.toLowerCase().indexOf(($event.srcElement.value.toLowerCase())) > -1);
    if (($event.item && typeof $event.item === 'object' && $event.item !== null && $event.item?.PKCountryId != null && $event.item?.PKCountryId != '') || filteredCountries && filteredCountries.length > 0) {
      this.typeaheadNoResults = false;
      if($event.item != null && $event.item != undefined)
        this.inputSelectedCountry = $event.item.CountryDisplayName;
    } else {
      this.typeaheadNoResults = true;
      if ($event.srcElement) $event.srcElement.value = '';
      this.inputSelectedCountry = '';
      this.onChange(undefined);
    }
  }

  countryTypeaheadNoResults($event: any) {
    if ($event && typeof $event.item === 'object' && $event.item !== null && $event.item?.PKCountryId != null && $event.item?.PKCountryId != '') {
      this.typeaheadNoResults = true;
    } else {
      if (this.inputSelectedCountry == '') {
        this.typeaheadNoResults = false;
      }
    }
  }

  changeCountryTypeaheadLoading(e: any): void {
    this.countryTypeaheadLoading = e;
  }

  selectDropdown(val: any) {
    //display current value in typeahead
    if (val != undefined && val != '') {
      if (this.inputAllCountries) {
        this.inputSelectedCountry = this.inputAllCountries.filter(x => x.PKCountryId == val)[0] ? this.inputAllCountries.filter(x => x.PKCountryId == val)[0].CountryDisplayName : '';
      }
      else {
        this.inputSelectedCountry = val;
      }
    }
    else {
      this.inputSelectedCountry = '';
    }
  }

  handleInput($event: any): boolean {
    const control = document.getElementById('countryautocomplete');
    $event.value = this.utilityService.replaceEmojis($event.value);
    control?.setAttribute("value", $event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control?.setAttribute("value", $event.value);
      return false;
    }
    return true;
  }

}
