import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  constructor(private alertifyService: AlertifyService,
    public utilityService: UtilityService,
    public authService: AuthenticationService,
    private titleService: Title) {
    this.titleService.setTitle('Resources');
  }

  ngOnInit(): void {
    window.scrollTo(0,0);
    if (this.authService.isCookie()) {
      this.authService.cookieTracking().subscribe();
    }
  }

  openConfirmationPopup(redirectURL: string)
  {
    let title = "You are about to leave "+this.utilityService.getPortalName()+" Med-info Portal";
    let message = "By clicking Continue, you will be taken to a website that is independent from "+this.utilityService.getPortalName()+". The site you are linking to is not controlled or endorsed by "+this.utilityService.getPortalName()+" and is not responsible for its content.";
    this.alertifyService.ExternalLinkConfirmationBanner(title, message, redirectURL, this.ConfimationCallBack, "Confirm"); 
  }

  ConfimationCallBack = (result: any, url: any) => {
    if (result) {
      window.open(url, "_blank");
    }
  }

}
