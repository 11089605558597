<section class="main-body">
    <div class="userpfl-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4 col-sm-6 dnone-sm"></div>
                <div class="col-md-8 col-sm-12">

                    <div class="row">
                        <div class="col-md-6 col-sm-6 col">
                            <div class="userpfl-name edit-uflName text-center">
                                <div class="page-header text-white">Bookmarks</div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col">
                            <ul class="uflButtons mb-0">
                                <li></li>
                                <li>
                                    <a href="index.html" type="button" class="btn btn-default float-right">Cancel</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <div class="container serachcreteriaBG h-100 pb-5 clearBoth">
        <div class="heading-mid py-2 pt-2">Total Bookmarks:<span>4</span></div>
        <div class="table bookmarkcnt-tbl">
            <div class="theader">
                <div class="table_header"></div>
                <div class="table_header">Content Name</div>
                <div class="table_header">Version</div>
                <div class="table_header"></div>
            </div>
            <div class="table-body-wrap">
                <div class="table-body tbodyHeight55 smoothSrcollbar">
                    <div class="table_row">
                        <div class="table_small">
                            <div class="table_cell"></div>
                            <div class="table_cell" (click)="openModal()"><i class="fas fa-star text-warning cursor-pointer"
                                    data-target=".confirmMsg" data-toggle="modal"></i></div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell">Content Name</div>
                            <div class="table_cell"><a
                                    href="images/Wonder Drug Administration with Food and on an Empty Stomach.pdf"
                                    target="_blank">Wonder Drug Administration with Food and on an Empty Stomach</a>
                            </div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell">Version</div>
                            <div class="table_cell"><strong>3.0</strong></div>
                        </div>

                        <div class="table_small">
                            <div class="table_cell"></div>
                            <div class="table_cell">
                                <a type="button" class="" data-toggle="tooltip" data-html="true" data-placement="right"
                                    title="<div>Bookmarked Version: 2.0</div>">
                                    <i class="fas fa-info-circle"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="table_row">
                        <div class="table_small">
                            <div class="table_cell"></div>
                            <div class="table_cell" (click)="openModal()"><i class="fas fa-star text-warning cursor-pointer" data-target=".confirmMsg"
                                    data-toggle="modal"></i></div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell">Content Name</div>
                            <div class="table_cell"><a
                                    href="images/Wonder Drug affects in patients having acute and chronic alcoholism.pdf"
                                    target="_blank">Wonder Drug affects in patients having acute and chronic
                                    alcoholism</a></div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell">Version</div>
                            <div class="table_cell">2.0</div>
                        </div>

                        <div class="table_small">
                            <div class="table_cell"></div>
                            <div class="table_cell">
                                <a type="button" class="" data-toggle="tooltip" data-html="true" data-placement="right"
                                    title="<div>Bookmarked Version: 2.0</div>">
                                    <i class="fas fa-info-circle"></i>
                                </a>

                            </div>
                        </div>
                    </div>
                    <div class="table_row">
                        <div class="table_small">
                            <div class="table_cell"></div>
                            <div class="table_cell" (click)="openModal()"><i class="fas fa-star text-warning cursor-pointer" data-target=".confirmMsg"
                                    data-toggle="modal"></i></div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell">Content Name</div>
                            <div class="table_cell"><a href="javascript:void(0);" target="_blank">Super Drug Product Information</a>
                            </div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell">Version</div>
                            <div class="table_cell">1.1</div>
                        </div>

                        <div class="table_small">
                            <div class="table_cell"></div>
                            <div class="table_cell">
                                <a type="button" class="" data-toggle="tooltip" data-html="true" data-placement="right"
                                    title="<div>Bookmarked Version: 2.0</div>">
                                    <i class="fas fa-info-circle"></i>
                                </a>

                            </div>
                        </div>
                    </div>
                    <div class="table_row">
                        <div class="table_small">
                            <div class="table_cell"></div>
                            <div class="table_cell" (click)="openModal()"><i class="fas fa-star text-warning cursor-pointer" data-target=".confirmMsg"
                                    data-toggle="modal"></i></div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell">Content Name</div>
                            <div class="table_cell"><a href="javascript:void(0);" target="_blank">Wonder Drug Product Information</a>
                            </div>
                        </div>
                        <div class="table_small">
                            <div class="table_cell">Version</div>
                            <div class="table_cell">3.0</div>
                        </div>

                        <div class="table_small">
                            <div class="table_cell"></div>
                            <div class="table_cell">
                                <a type="button" class="" data-toggle="tooltip" data-html="true" data-placement="right"
                                    title="<div>Bookmarked Version: 2.0</div>">
                                    <i class="fas fa-info-circle"></i>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <!-- Confirmation Message -->
    <div class="modal fade confirmMsg show" aria-modal="true">
        <div class="modal-dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header p-2 border-bottom">
                    <div class="pl-3 mb-0 fs-16 text-white">Lorem ipsum</div>
                    <button type="button" class="close pr-4 text-white" data-dismiss="modal">×</button>
                </div>
                <!-- Modal body -->
                <div class="modal-body pb-3 text-center">
                    <p class="text-center">Loren ipsum dolor site Loren ipsum dolor site Loren ipsum dolor site
                        Loren ipsum dolor site</p>
                    <div class="text-center pt-3">
                        <a href="javascript:void(0);" class="btn btn-default mr-2">Yes</a>
                        <a href="javascript:void(0);" class="btn btn-default">No</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="modal request-facilityppr fade " id="viewackdtls" aria-modal="true">
        <div class="modal-dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header p-2 border-bottom">
                    <h4 class="pl-3 mb-0">View Acknowledgement</h4>
                    <button type="button" class="close pr-4 text-white" data-dismiss="modal">×</button>
                </div>
                <!-- Modal body -->
                <div class="modal-body pb-2">
                    <div class="container-fluid ">

                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label for="" class="fieldlabel">Reference ID</label>
                                    <input type="" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label for="" class="fieldlabel">Acknowledged on</label>
                                    <input type="date" class="form-control" placeholder="">
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label for="" class="fieldlabel">Comments</label>
                                    <textarea class="form-control" rows="4"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-3 offset-5">
                                <ul class="d-flex m-auto pb-4">
                                    <li class="mr-2">
                                        <button type="button" class="btn btn-default py-2">Submit</button>
                                    </li>
                                    <li>
                                        <button type="button" class="btn btn-default btn-primary py-2"
                                            data-dismiss="modal">Cancel</button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="modal request-facilityppr fade " id="viewackdtls-edit" aria-modal="true">
        <div class="modal-dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header p-2 border-bottom">
                    <h4 class="pl-3 mb-0">View Acknowledgement</h4>
                    <button type="button" class="close pr-4 text-white" data-dismiss="modal">×</button>
                </div>
                <!-- Modal body -->
                <div class="modal-body pb-2">
                    <div class="container-fluid ">

                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label for="" class="fieldlabel">Reference ID</label>
                                    <input type="" class="form-control" placeholder="">
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label for="" class="fieldlabel">Acknowledged on</label>
                                    <input type="text" class="form-control" placeholder="">
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label for="" class="fieldlabel">Comments</label>
                                    <textarea class="form-control" rows="4"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-sm-3 offset-5">
                                <ul class="d-flex m-auto pb-4">
                                    <li class="mr-2">
                                        <button type="button" class="btn btn-default py-2">Submit</button>
                                    </li>
                                    <li>
                                        <button type="button" class="btn btn-default btn-primary py-2"
                                            data-dismiss="modal">Cancel</button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal helpModal fade show" id="" aria-modal="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header p-2 border-bottom">
                    <h4 class="pl-3 mb-0">Help/Guide</h4>
                    <div class="float-right pr-2">
                        <a href="javascript:void(0);"><i class="fas fa-times fs-14 text-white" data-dismiss="modal"></i></a>
                    </div>
                </div>
                <!-- Modal body -->
                <div class="modal-body pb-3 pt-0">
                    <div class="container-fluid">
                        <div class="tabs-block mt-3">
                            <nav>
                                <div class="nav default-tabs" id="nav-tab" role="tablist">
                                    <a class="nav-item nav-link active" id="Generalhelp-tab" data-toggle="tab"
                                        href="#Generalhelp" role="tab" aria-controls="nav-home" aria-selected="false"><i
                                            class="far fa-check-circle"></i>&nbsp;General
                                        Help</a>
                                    <a class="nav-item nav-link" id="customhelp-tab" data-toggle="tab"
                                        href="#customhelp" role="tab" aria-controls="nav-profile"
                                        aria-selected="false"><i class="far fa-check-circle"></i>&nbsp;Custom
                                        Help</a>
                                </div>
                            </nav>

                            <div class="tab-content" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="Generalhelp" role="tabpanel"
                                    aria-labelledby="Generalhelp-tab">
                                    <div class="accordion px-3 py-2" id="acordion1">
                                        <div class="card">
                                            <div class="card-header py-1 px-0 border-bottom-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button type="button"
                                                        class="btn btn-link align-items-center d-flex collapsed"
                                                        data-toggle="collapse" data-target="#collapseOne"
                                                        aria-expanded="false">
                                                        <i class="far fa-plus-square"></i><span
                                                            class="pl-1 align-items-center d-flex">Loren ipsum dolor
                                                            site ?</span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                                data-parent="#acordion1">
                                                <div class="card-body py-1">
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion px-3 py-2" id="acordion2">
                                        <div class="card">
                                            <div class="card-header py-1 px-0 border-bottom-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button type="button"
                                                        class="btn btn-link align-items-center d-flex collapsed"
                                                        data-toggle="collapse" data-target="#collapse2"
                                                        aria-expanded="false">
                                                        <i class="far fa-plus-square"></i><span
                                                            class="pl-1 align-items-center d-flex">Loren ipsum dolor
                                                            site ?</span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapse2" class="collapse" aria-labelledby="headingOne"
                                                data-parent="#acordion2">
                                                <div class="card-body py-1">
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion px-3 py-2" id="acordion3">
                                        <div class="card">
                                            <div class="card-header py-1 px-0 border-bottom-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button type="button"
                                                        class="btn btn-link align-items-center d-flex collapsed"
                                                        data-toggle="collapse" data-target="#collapse3"
                                                        aria-expanded="false">
                                                        <i class="far fa-plus-square"></i><span
                                                            class="pl-1 align-items-center d-flex">Loren ipsum dolor
                                                            site ?</span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapse3" class="collapse" aria-labelledby="headingOne"
                                                data-parent="#acordion3">
                                                <div class="card-body py-1">
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="customhelp" role="tabpanel"
                                    aria-labelledby="customhelp-tab">
                                    <div class="accordion px-3 py-2" id="acordion4">
                                        <div class="card">
                                            <div class="card-header py-1 px-0 border-bottom-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button type="button"
                                                        class="btn btn-link align-items-center d-flex collapsed"
                                                        data-toggle="collapse" data-target="#collapse4"
                                                        aria-expanded="false">
                                                        <i class="far fa-plus-square"></i><span
                                                            class="pl-1 align-items-center d-flex">Loren ipsum dolor
                                                            site ?</span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapse4" class="collapse" aria-labelledby="headingOne"
                                                data-parent="#acordion4">
                                                <div class="card-body py-1">
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion px-3 py-2" id="acordion5">
                                        <div class="card">
                                            <div class="card-header py-1 px-0 border-bottom-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button type="button"
                                                        class="btn btn-link align-items-center d-flex collapsed"
                                                        data-toggle="collapse" data-target="#collapse5"
                                                        aria-expanded="false">
                                                        <i class="far fa-plus-square"></i><span
                                                            class="pl-1 align-items-center d-flex">Loren ipsum dolor
                                                            site ?</span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapse5" class="collapse" aria-labelledby="headingOne"
                                                data-parent="#acordion5">
                                                <div class="card-body py-1">
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion px-3 py-2" id="acordion6">
                                        <div class="card">
                                            <div class="card-header py-1 px-0 border-bottom-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button type="button"
                                                        class="btn btn-link align-items-center d-flex collapsed"
                                                        data-toggle="collapse" data-target="#collapse6"
                                                        aria-expanded="false">
                                                        <i class="far fa-plus-square"></i><span
                                                            class="pl-1 align-items-center d-flex">Loren ipsum dolor
                                                            site ?</span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapse6" class="collapse" aria-labelledby="headingOne"
                                                data-parent="#acordion6">
                                                <div class="card-body py-1">
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                    <p>Loren ipsum dolor site Loren ipsum dolor site Loren ipsum
                                                        dolor site Loren ipsum dolor site</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left">Static modal</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        This is static modal, backdrop click will not close it.        
      </div>
      <div class="modal-footer">
          <button class="btn btn-outline-primary btn-sm" (click)="staticModal.hide()">Yes</button>
          <button class="btn btn-outline-primary btn-sm" (click)="staticModal.hide()">No</button>
      </div>
    </div>
  </div>
</div>
