import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { AppConstants } from '../../shared/AppConstants';
import { MedicalInfoTrakings } from '../_models/medical-info-tracking';
import { Portal } from '../_models/portals';
import { UserLoginResult } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  user: UserLoginResult = {};
  interval: any;
  intervalClear: any;
  portal: Portal = {
    PortalComponents: []
  };

  constructor(private http: HttpClient,
    private titleService: Title) {
      let user: UserLoginResult = <UserLoginResult>{};
     }

  getPortal(): Observable<Portal> {
    return this.http.get<Portal>(AppConstants.PortalApiBaseUrl + '/api/Home/GetPortal/' + AppConstants.PortalCode);
  }

  getMedInfoTracking(product: string, indication: string, keyword: string, document_store_ID: string) : Observable<any> {
    let document_file_store_ID;
    if(!document_store_ID || document_store_ID === null){
      document_file_store_ID: null;
    }
    else{
      document_file_store_ID: document_store_ID;
    }
    let medInfoTracking: MedicalInfoTrakings = {
      FK_ENVIRONMENT_ID: this.user.FK_EnvironmentId,
      FK_PORTAL_ID: this.user.FK_PortalId,
      PRODUCT_NAME: product,
      INDICATION: indication,
      KEYWORD: keyword,
      FK_DOCUMENT_STORE_ID: document_file_store_ID,
      PAGE_TITLE: this.titleService.getTitle(),
      BROWSER_LANGUAGE: navigator.language,
      USER_AGENT: navigator.userAgent,
      TIME_STAMP: new Date(),
      LOGGED_IN_USER_ID: this.user.PK_UserId,
      PORTAL_CODE: AppConstants.PortalCode
    }

    return this.http.post<any>(AppConstants.PortalApiBaseUrl + '/api/Auth/MedicalInfoTracking', medInfoTracking);
  }
}
