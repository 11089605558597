<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block">
      Edit Profile
      <a (click)="this.close()" class="fs-12 text-underline float-right pt-1 pl-2 text-white fs-14">
        <i class="fas fa-times"></i>
      </a>
    </h5>
  </div>

  <div class="modal-body">
    <div class="tabsMain modalbody-ht">
      <div class="container-fluid">
        <p class="fs-12">You can edit your profile details here. </p>
        <form [formGroup]="EditProfileForm">
          <div class="row border-bottom py-2">
            <div class="col-6 col-md-6">
              <div class="title-md">User Details</div>
            </div>
            <div class="col-6 col-md-6 text-right">
              <label class="fieldLabel mandatory fs-12 text-secondary">Required Fields</label>
            </div>

            <div class="col-md-6 col-sm-12">
              <div class="d-flex pt-2">
                <div class="viewhead">User ID:</div>
                <div class="viewdtls pl-1">{{this.userData.UserID}}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="d-flex pt-3">
                <div class="viewhead">Registered Email Address:</div>
                <div class="viewdtls pl-1">{{this.userData.EmailID}}</div>
              </div>
            </div>

            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="fieldLabel mandatory">First Name</label>
                <input type="text" class="form-control" formControlName="FIRST_NAME" noNumbers
                       (blur)="handleInput($event.target,'FIRST_NAME');" autocomplete="off" required=""
                       maxlength="35" [ngClass]="{ 'is-invalid': form.FIRST_NAME.errors }">
                <div *ngIf="form.FIRST_NAME.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FIRST_NAME.errors?.['required']">
                    First Name is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="fieldLabel mandatory">Last Name</label>
                <input type="text" class="form-control" formControlName="LAST_NAME" noNumbers
                       (blur)="handleInput($event.target,'LAST_NAME');" autocomplete="off" required=""
                       maxlength="50" [ngClass]="{ 'is-invalid': form.LAST_NAME.errors }">
                <div *ngIf="form.LAST_NAME.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.LAST_NAME.errors?.['required']">
                    Last Name is required.
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="fieldLabel mandatory">Date of Birth</label>
                <div>
                  <p-calendar inputId="ep-dbt"
                              placeholder="DD-MMM-YYYY"
                              formControlName="DATE_OF_BIRTH"
                              dateFormat="dd-M-yy"
                              [showIcon]="true"
                              icon="far fa-calendar-alt"
                              [maxDate]="minDate"
                              styleClass="{ 'is-invalid': form.DATE_OF_BIRTH.errors }"></p-calendar>

                </div>
                <div *ngIf="form.DATE_OF_BIRTH.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.DATE_OF_BIRTH.errors?.['required']">
                    Date of Birth is required.
                  </div>
                </div>
                <div *ngIf="form.DATE_OF_BIRTH.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.DATE_OF_BIRTH.errors?.age">
                    Age should not be less than 16 years
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="fieldLabel mandatory">Country</label>
                <app-country-control formControlName="FK_COUNTRY_ID" [inputAllCountries]="allCountries"
                                     [inputSelectedCountry]="selectedCountry"></app-country-control>
                <span class="fltlabel-highlight"></span>
                <div *ngIf="form.FK_COUNTRY_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FK_COUNTRY_ID.errors?.['required']">
                    Country is required.
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="fieldLabel">Zip Code</label>
                <input type="text" class="form-control" formControlName="ZIP_CODE" (blur)="handleInput($event.target,'ZIP_CODE');"
                       autocomplete="off" maxlength="15" noSpecailChars noSpaces />
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="fieldLabel">State</label>
                <input type="text" class="form-control" formControlName="STATE_PROVINCE"
                       (blur)="handleInput($event.target,'STATE_PROVINCE');" autocomplete="off" maxlength="50" />
              </div>
            </div> 
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="fieldLabel">City</label>
                <input type="text" class="form-control" formControlName="CITY"
                       (blur)="handleInput($event.target,'CITY');" autocomplete="off" maxlength="50" />
              </div>
            </div> -->
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="fieldLabel">Practice Address</label>
                <input type="text" class="form-control" formControlName="ADDRESS"
                       (blur)="handleInput($event.target,'ADDRESS');" autocomplete="off" maxlength="200" />
              </div>
            </div>
          </div>
          <div class="row border-bottom pt-2 pb-2">
            <div class="col-md-12">
              <div class="title-md">Contact Preference</div>
            </div>

            <div class="col-lg-6 col-md-12 pb-2">
              <div class="row">
                <div class="col-md-12"><label class="fieldLabel pb-2 mandatory">Preferred Contact Method</label></div>
                <div *ngFor="let preferredContact of preferedContacts" class="col-6 col-md-6 col-sm-6">
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" value="{{preferredContact.Id}}"
                           formControlName="FK_PREFERRED_CONTACT_ID" id="{{preferredContact.Id}}" (click)='CP_Change(preferredContact.Id)'>
                    <label class="custom-control-label"
                           for="{{preferredContact.Id}}">{{preferredContact.ContactName}}</label>
                  </div>
                </div>
                <div *ngIf="form.FK_PREFERRED_CONTACT_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FK_PREFERRED_CONTACT_ID.errors?.['required']">
                    Preferred Contact Method is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="fieldLabel" [ngClass]="{ 'mandatory' : isEmailMandatory }" for="eml">Email Address</label>
                <input type="text" id="eml" class="form-control" formControlName="PREFERED_EMAIL"
                       (blur)="handleInput($event.target,'PREFERED_EMAIL');" autocomplete="off" maxlength="100" />
                <div *ngIf="form.PREFERED_EMAIL.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.PREFERED_EMAIL.errors?.['required']">
                    Email Address is required.
                  </div>
                </div>
                <div *ngIf="form.PREFERED_EMAIL.dirty || form.PREFERED_EMAIL.touched || invalidPreferredEmail" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="invalidPreferredEmail || form.PREFERED_EMAIL.errors?.['email']">
                    Please enter valid Email Address.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-9 col-md-9 col-sm-9">
                  <div class="form-group">
                    <label class="fieldLabel" [ngClass]="{ 'mandatory' : isPhoneMandatory }" for="pnr1">Primary Phone Number</label>
                    <input phoneNumber type="text" id="pnr1" class="form-control"
                           autocomplete="off" formControlName="PREFERED_PHONE" minlength="10" maxlength="25" (blur)="handleInput($event.target,'PREFERED_PHONE');" />
                    <div *ngIf="form.PREFERED_PHONE.invalid"
                         class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.PREFERED_PHONE.errors?.['required']">
                        Primary Phone Number is required.
                      </div>
                    </div>
                    <div *ngIf="form.PREFERED_PHONE.value && (form.PREFERED_PHONE.dirty || form.PREFERED_PHONE.touched) && (form.PREFERED_PHONE.value).length < 10" class="invalid-form-control text-danger fs-12">
                      Please enter a valid Phone Number.
                    </div>
                  </div>
                </div>
                <div class="col-3 col-md-3 col-sm-3">
                  <div class="form-group">
                    <label class="fieldLabel">Ext</label>
                    <input type="text" id="ext1" class="form-control"
                           (blur)="handleInput($event.target,'PREFERED_PHONE_EXT');" phoneNumber
                           autocomplete="off" formControlName="PREFERED_PHONE_EXT" maxlength="10" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-9 col-md-9 col-sm-9">
                  <div class="form-group">
                    <label class="fieldLabel">Other Phone Number</label>
                    <input phoneNumber type="text" id="pnr2" class="form-control" (blur)="handleInput($event.target,'PHONE');"
                           autocomplete="off" formControlName="PHONE" minlength="10" maxlength="25" />
                    <div *ngIf="form.PHONE.value && (form.PHONE.dirty || form.PHONE.touched) && (form.PHONE.value).length < 10" class="invalid-form-control text-danger fs-12">
                      Please enter a valid Phone Number.
                  </div>
                  </div>
                </div>
                <div class="col-3 col-md-3 col-sm-3">
                  <div class="form-group">
                    <label class="fieldLabel">Ext</label>
                    <input type="text" id="ext2" class="form-control"
                           (blur)="handleInput($event.target,'PHONE_EXT');" phoneNumber
                           autocomplete="off" formControlName="PHONE_EXT" maxlength="10" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt-2 pb-2">
            <div class="col-md-12">
              <div class="title-md">Practice Details</div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Profession</label>
                <select [ngClass]="{ 'is-invalid': form.FK_PROFESSION_ID.errors }"
                        class="floating-select form-control" required="" formControlName="FK_PROFESSION_ID">
                    <option *ngFor="let occ of occupationList" value="{{occ.Id}}">{{occ.Name}}</option>
                </select>
                <span class="fltlabel-highlight"></span>
                <div *ngIf="form.FK_PROFESSION_ID.invalid"
                     class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FK_PROFESSION_ID.errors?.['required']">
                    Profession is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">Specialty</label>
                <select class="floating-select form-control" formControlName="FK_SPECAILITY_ID">
                  <option value="">-Select-</option>
                  <option *ngFor="let spec of specialityList" value="{{spec.PK_LOOKUP_ID}}"> {{spec.NAME}} </option>
                </select>
                <!-- <span class="fltlabel-highlight"></span>
                <div *ngIf="form.FK_SPECAILITY_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FK_SPECAILITY_ID.errors?.['required']">
                    Speciality is required.
                  </div>
                </div> -->
              </div>
            </div>

            <!-- <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">License Number</label>
                <input type="text" id="ln" class="form-control" required=""
                       formControlName="LICENCE_NUMBER" autocomplete="off" maxlength="10"
                       (blur)="handleInput($event.target,'LICENCE_NUMBER');"
                       [ngClass]="{ 'is-invalid': form.LICENCE_NUMBER.errors }" />
                <div *ngIf="form.LICENCE_NUMBER.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.LICENCE_NUMBER.errors?.['required']">
                    License Number is required.
                  </div>
                </div>
                <div *ngIf="this.duplicateLicense" class="invalid-form-control text-danger fs-12">
                  License Number already exists
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <label class="fieldLabel">Primary Practice</label>
              <div class="form-group">
                <input type="text" id="pp" class="form-control" formControlName="PRIMARY_PRACTICE"
                       (blur)="handleInput($event.target,'PRIMARY_PRACTICE');" autocomplete="off" maxlength="200" />
              </div>
            </div> -->

          </div>
          <div class="row">
            <div class="col-md-12 mbl-100 text-right" ngPreserveWhitespaces>
              <a href="javascript:void(0);" class="btn btn-primary" (click)="close()">Cancel</a>
              <a href="javascript:void(0);" class="btn btn-primary" (click)="Submit()">Submit</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
