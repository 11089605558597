<!-- View Acknowledgement -->
  <div id="viewackdtls" aria-modal="true">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header p-2 border-bottom">
        <div class="pl-3 mb-0 heading-small text-white">View Acknowledgement</div>
        <button type="button" class="close pr-4 text-white" data-dismiss="modal" (click)="close();">×</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="row pb-1">
                <div class="col-md-4 col-sm-6"><div class="viewhead">Ack ID:</div></div>
                <div class="col-md-8 col-sm-6">
                  <div class="viewdtls pl-1" *ngIf="!RequestAckData.AckID">{{'N/A'}}</div>
                  <div class="viewdtls pl-1" *ngIf="RequestAckData.AckID">{{RequestAckData.AckID}}</div>
                </div>
              </div>
              <div class="row pb-1">
                <div class="col-md-4 col-sm-6"><div class="viewhead">Received On:</div></div>
                <div class="col-md-8 col-sm-6">
                  <div class="viewdtls pl-1" *ngIf="!RequestAckData.AckReceivedDate">{{'N/A'}}</div>
                  <div class="viewdtls pl-1" *ngIf="RequestAckData.AckReceivedDate">{{RequestAckData.AckReceivedDate|date:'dd-MMM-yyyy hh:mm a'}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-sm-6"><div class="viewhead">Comments:</div></div>
                <div class="col-md-8 col-sm-6">
                  <div class="viewdtls pl-1" *ngIf="!RequestAckData.AckComments">{{'N/A'}}</div>
                  <div class="viewdtls pl-1" *ngIf="RequestAckData.AckComments">{{RequestAckData.AckComments}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
