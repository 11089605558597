import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  LoginStatus$ : Observable<boolean>;
  isUS_HCP: boolean = true;
  copyrightCurrentYear: number = new Date().getFullYear();
  
  constructor(public authService: AuthenticationService,
    public utilityService: UtilityService,
    public modalRef: BsModalRef,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.LoginStatus$ = this.authService.isLoggedIn;
    this.utilityService.isUS_HCP_Data.subscribe((res) => {
      this.isUS_HCP = res;
    })
  }

  onActionNonHCP() {
    this.utilityService.isProfileSelected();
  }

  openContactModal(template:  TemplateRef<any>)
  {
    const initialState: ModalOptions = {
      backdrop : 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(template, initialState);
  }

}
