<div class="container-fluid p-0">
  <div class="tabs-block">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-11 col-sm-11">
          <div class="loginHeading text-center">Account Activation</div>
          <div *ngIf="isActivated">Your Account has been successfully Activated.</div>
        </div>

        <div class="col-md-11 col-sm-11">
        </div>
      </div>
      <div class="row">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-11 col-sm-11">
          <div>

            <div *ngIf="!isAlreadyActivated">
              <div *ngIf="!isActivated && !isResendActivation">
                Your Activation Code Expired. Please Resend Activation, We will send you account activation link to the email address specified.
                <button type="button" class="btn btn-default py-2" (click)="resendActivation();">Resend Activation</button>
              </div>
              <div *ngIf="!isActivated && isResendActivation">
                Resend Activation submitted successfully.
              </div>
            </div>

            <div *ngIf="isAlreadyActivated && !isResendActivation">
              Your Account have already activated. If you forgot the password <a href="#">click here</a>
            </div>

            <br /> <br /> <br /> <br /> <br />
            <div class="col-md-12 col-sm-12">

              <div class="row">
                <div class="col-md-8 col-sm-8"></div>
                <div class="col-md-4 col-sm-4">
                  <ul class="d-flex float-right mt-4">
                    <li class="mr-2">
                    </li>
                    <li>
                    <li class="mr-2">
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

</div>



