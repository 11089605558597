export class Message {
  dismissed = false;
  id: string = Guid.MakeNew().ToString();

  content: string;
  style: 'info' | 'error';

  constructor(content: string, style?: 'info' | 'error') {
    this.content = content;
    this.style = style || 'info';
  }
}

export class Guid {
  private _guid: string;

  constructor(public guid: string) {
    this._guid = guid;
  }

  // Static member
  static MakeNew(): Guid {
    let result: string;
    let i: string;
    let j: number;

    result = '';
    for (j = 0; j < 32; j++) {
      if (j === 8 || j === 12 || j === 16 || j === 20) {
        result = result + '-';
      }
      i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
      result = result + i;
    }
    return new Guid(result);
  }

  public ToString(): string {
    return this._guid;
  }
}
