import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PortalComponents } from 'src/app/_models/portals';
import { UtilityService } from 'src/app/_services/utility.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {

  isSMI: boolean = true;
  isRMI: boolean = true;
  isCongress: boolean = true;

  constructor(
    public authService: AuthenticationService,
    public utilityService: UtilityService,
    private titleService: Title) {
    this.titleService.setTitle('Site Map');
  }

  ngOnInit(): void {
    window.scrollTo(0,0);
    if (this.authService.isCookie()) {
      this.authService.cookieTracking().subscribe();
    }

    if (this.authService.portal && this.authService.portal.PortalComponents) {
      for (const component of this.authService.portal.PortalComponents) {
        switch (component.Code) {
          case 'CONTENT_INFORMATION':
            this.isSMI = component.Status ?? false;
            break;
          case 'REQUEST_MI':
            this.isRMI = component.Status ?? false;
            break;
          case 'NEWS_AND_ANNOUNCEMENTS':
            this.isCongress = component.Status ?? false;
            break;
        }
      }
    }
  }

}
