<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title w-100 d-block">Change Password
        <span (click)="this.close()" class="fs-12 text-underline float-right pt-1"><i class="fas fa-times"></i></span></h5>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <form [formGroup]="ChangePasswordForm">
            <div class="row">
              <div class="col-md-12 text-right">
                <label class="fieldLabel mandatory fs-12 text-secondary" >Required Fields</label>
              </div>
                <!-- <div class="col-md-12">
                  <div class="form-group">
                        <label class="fieldLabel mandatory" >Registered Email Address</label>                       
                        <input type="email" class="form-control" formControlName="REGISTERED_EMAIL_ID"
                        maxlength="100" [ngClass]="{ 'is-invalid': form.REGISTERED_EMAIL_ID.errors }" />
                        <div *ngIf="submitted && form.REGISTERED_EMAIL_ID.invalid" class="invalid-form-control text-danger fs-12">
                            <div *ngIf="form.REGISTERED_EMAIL_ID.errors?.['required']">
                                Email Address is required.
                            </div>
                            <div *ngIf="form.REGISTERED_EMAIL_ID.errors?.['email']">
                                Please enter valid Email Address.
                            </div>
                        </div>
                  </div>
                </div> -->
                <div class="col-md-12">
                    <div class="form-group">
                          <label class="fieldLabel mandatory">Current Password</label>                       
                          <input type="password" class="form-control" required="" noSpaces PWDSpecailChars
                          formControlName="PASSWORD" autocomplete="off" maxlength="100" (blur)="handleInput($event.target,'PASSWORD');"
                          [ngClass]="{ 'is-invalid': (form.PASSWORD.dirty || form.PASSWORD.touched || submitted) && form.PASSWORD.errors }" />
                          <div *ngIf="(form.PASSWORD.dirty || form.PASSWORD.touched || submitted) && form.PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                              <div *ngIf="form.PASSWORD.errors?.['required']">
                                  Password is required.
                              </div>
                              <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['number']">
                                    Password should contain at least 1 number
                                </div>
                                <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['upper']">
                                    Password should contain at least 1 uppercase letter 
                                </div>
                                <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['lower']">
                                    Password should contain at least 1 lowercase letter 
                                </div>
                                <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['special']">
                                    Password should contain at least 1 special character 
                                </div>
                                <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['minlength']">
                                    Password should contain minimum of 8 characters
                                </div>
                              <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['pattern']">
                                  Password doesn't meet the policy requirement. Please check password policy.
                              </div>
                          </div>
                    </div>
                  </div>
                <div class="col-md-12">
                  <div class="form-group">
                        <label class="fieldLabel mandatory">New Password</label>                       
                        <input type="password" class="form-control" required="" noSpaces PWDSpecailChars
                        formControlName="NEW_PASSWORD" autocomplete="off" maxlength="100" (blur)="handleInput($event.target,'NEW_PASSWORD');"
                        [ngClass]="{ 'is-invalid': (form.NEW_PASSWORD.dirty || form.NEW_PASSWORD.touched || submitted) && form.NEW_PASSWORD.errors }" />
                        <div *ngIf="(form.NEW_PASSWORD.dirty || form.NEW_PASSWORD.touched || submitted) && form.NEW_PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                            <div *ngIf="form.NEW_PASSWORD.errors?.['required']">
                                New Password is required.
                            </div>
                            <div *ngIf="!form.NEW_PASSWORD.errors?.['required'] && form.NEW_PASSWORD.errors?.['number']">
                                New Password should contain at least 1 number
                              </div>
                              <div *ngIf="!form.NEW_PASSWORD.errors?.['required'] && form.NEW_PASSWORD.errors?.['upper']">
                                New Password should contain at least 1 uppercase letter 
                              </div>
                              <div *ngIf="!form.NEW_PASSWORD.errors?.['required'] && form.NEW_PASSWORD.errors?.['lower']">
                                New Password should contain at least 1 lowercase letter 
                              </div>
                              <div *ngIf="!form.NEW_PASSWORD.errors?.['required'] && form.NEW_PASSWORD.errors?.['special']">
                                New Password should contain at least 1 special character 
                              </div>
                              <div *ngIf="!form.NEW_PASSWORD.errors?.['required'] && form.NEW_PASSWORD.errors?.['minlength']">
                                New Password should contain minimum of 8 characters
                              </div>
                            <div *ngIf="!form.NEW_PASSWORD.errors?.['required'] && form.NEW_PASSWORD.errors?.['pattern']">
                                Password doesn't meet the policy requirement. Please check password policy.
                            </div>
                        </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                        <label class="fieldLabel mandatory">Confirm Password</label>                       
                        <input type="password" class="form-control" required="" noSpaces PWDSpecailChars
                        formControlName="CONFIRM_PASSWORD" autocomplete="off" maxlength="100" (blur)="handleInput($event.target,'CONFIRM_PASSWORD');"
                        [ngClass]="{ 'is-invalid': (form.CONFIRM_PASSWORD.dirty || form.CONFIRM_PASSWORD.touched || submitted) && form.CONFIRM_PASSWORD.errors }" />
                        <div *ngIf="(form.CONFIRM_PASSWORD.dirty || form.CONFIRM_PASSWORD.touched || submitted) && form.CONFIRM_PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                            <div *ngIf="form.CONFIRM_PASSWORD.errors?.['required']">
                                Confirm Password is required.
                            </div>
                            <!-- <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.['number']">
                                Confirm Password should contain at least 1 number
                              </div>
                              <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.['upper']">
                                Confirm Password should contain at least 1 uppercase letter 
                              </div>
                              <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.['lower']">
                                Confirm Password should contain at least 1 lowercase letter 
                              </div>
                              <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.['special']">
                                Confirm Password should contain at least 1 special character 
                              </div>
                              <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.['minlength']">
                                Confirm Password should contain minimum of 8 characters
                              </div>
                            <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.['pattern']">
                                Confirm Password doesn't meet the policy requirement. Please check password policy.
                            </div> -->
                            <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.mustMatch">
                                New Password and Confirm Password should match
                            </div>
                        </div>
                  </div>
                </div>
                <div class="col-md-12 pt-2">
                  <button type="button" [disabled]="!this.enableChangePassword" 
                  class="btn btn-primary btn-block fs-14" (click)="changePassword()">Confirm</button>
                  <p class="fs-13 pt-1">Please note that your password should meet our  
                    <a href="javascript:void(0)" (click)="this.openPasswordPolicy()">Password Policy.&nbsp;<i class="fas fa-info-circle fs-14"></i></a></p>
                </div>
            </div>
            </form>
        </div>
        
    </div>

  </div>
