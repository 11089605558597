import { Injectable } from '@angular/core';
import { Message } from '../_models/message'
import { MessageType } from '../_enums/message-type.enum'
import { interval } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ValidationsBanertemplate } from '../components/Common/validations-banertemplate.component';
import { ConfirmationBanertemplate } from '../components/Common/confirmation-bannertemplate.component';
import { ErrorBanertemplate } from '../components/Common/error-banertemplate.component';
import { SuccessBanertemplate } from '../components/Common/success-banertemplate.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ExternalRedirectionConfirmationBannertemplate } from '../components/Common/external-redirection-confirmation-bannertemplate.component';
import { ChangePasswordSuccessBanertemplate } from '../components/Common/changepassword-success-banertemplate.component';

declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {
  messages: Array<Message> = new Array<Message>();
  onScreenSeconds: number = 0;

  constructor(private http: HttpClient,private modalService:BsModalService ,public modalRef: BsModalRef) {
    ////this.http.get<number>(AppConstants.PortalApiBaseUrl +'/api/Auth/ToastExpireSeconds').subscribe(
    ////  result => {
    ////    if (result != null && result != undefined)
    ////      this.onScreenSeconds = result;
    ////  }
    ////)
  }

  /**
   * This is internal method
   * This method returns the 'messages' array object.
   */
  getToastMessages(): Message[] {
    return this.messages;
  }

  /**
   * To display a Toast message at Top-middle of the screen.
   * @param content String content to be displayed as message.
   * @param type Indicates the type of message styles to be applied. It is MessageType
   * Success: Green style is applied
   * Error: Red style is applied.
   */
  setToastMessage(content: any, type: MessageType) {
    this.dismissAllToastMessages();

    let mgsStyle: 'info' | 'error' = 'info';
    if (type === MessageType.Error) {
      mgsStyle = 'error';
    }

    const message = new Message(content, mgsStyle);
    this.messages.push(message);

    if (this.onScreenSeconds > 0) {
      interval(this.onScreenSeconds * 1000).subscribe(() => { this.dismissToastMessage(message.id); });
    }
  }


  /**
   * This is internal function
   * Dismissess the Toast Message with the given message ID.
   * @param messageId: ID of the message object present in the Toast messages array.
   */
  dismissToastMessage(messageId: string) {
    const index = this.messages.findIndex(x => x.id === messageId);
    if (index !== -1) {
      this.messages.splice(index, 1);
    }
  }

  /**
   * This method clears the 'messages' array list (All messages are cleared on UI).
   */
  dismissAllToastMessages(): void {
    this.messages.splice(0, this.messages.length);
  }

  /**
   * This dismisses all the alerts at once.
   */
  dismissAllAlerts(): void {
    alertify.dismissAll();
  }

  /**
   * This function shows the error toast
   * @param message the string to be displayed in the toast
   * @param onScreenSeconds optional - positive number of seconds to display on the screen
   * (default is 3 - which will auto dismiss after 3 seconds).
   */
  error(message: string, onScreenSeconds: number = 3): void {
    alertify.error(message, onScreenSeconds);
  }

  /**
   * This function shows the error toast with a callback to user's response.
   * @param message the string to be displayed in the toast
   * @param onScreenSeconds optional - positive number of seconds to display on the screen
   * (default is 0 - which will not dismiss until user's interaction to close the toast).
   * @param callback a callback function to handle events by user on the toast. 
   */
  errorWithCallback(message: string, onScreenSeconds: number = 0, callback: () => any) {
    alertify.error(message, onScreenSeconds, (e: Event) => {
      if (e) {
        callback();
      } else { }
    });
  }

  /**
   * This function shows the message toast
   * @param message the string to be displayed in the toast.
   * @param onScreenSeconds optional - positive number of seconds to display on the screen
   * (default is 3 - which will auto dismiss after 3 seconds).
   */
  message(message: string, onScreenSeconds: number = 3) {
    alertify.message(message, onScreenSeconds);
  }

  /**
   * This function shows the message toast with a callback to user's response.
   * @param message the string to be displayed in the toast
   * @param onScreenSeconds optional - positive number of seconds to display on the screen
   * (default is 0 - which will not dismiss until user's interaction to close the toast).
   * @param callback a callback function to handle events by user on the toast.
   */
  messageWithCallback(message: string, onScreenSeconds: number = 0, callback: () => any) {
    alertify.message(message, onScreenSeconds, (e: Event) => {
      if (e) {
        callback();
      } else { }
    });
  }

  /**
   * This function shows the notification toast
   * @param message the string to be displayed in the toast
   * @param customCSSClass
   * @param onScreenSeconds optional - positive number of seconds to display on the screen (default is 3 - which will auto dismiss after 3 seconds).
   */
  notify(message: string, customCSSClass: string, onScreenSeconds: number = 3) {
    alertify.notify(message, customCSSClass, onScreenSeconds);
  }

  /**
   * This function shows the notification toast
   * @param message the string to be displayed in the toast
   * @param customCSSClass
   * @param onScreenSeconds optional - positive number of seconds to display on the screen (default is 0 - which will not dismiss until user's interaction to close the toast).
   * @param callback a callback function to handle events by user on the toast.
   */
  notifyWithCallback(message: string, customCSSClass: string, onScreenSeconds: number = 0, callback: () => any) {
    alertify.notify(message, customCSSClass, onScreenSeconds, (e: Event) => {
      if (e) {
        callback();
      } else { }
    });
  }

  /**
   * This function shows the success toast
   * @param message the string to be displayed in the toast
   * @param onScreenSeconds optional - positive number of seconds to display on the screen (default is 3 - which will auto dismiss after 3 seconds).
   */
  success(message: string, onScreenSeconds: number = 3) {
    alertify.success(message, onScreenSeconds);
  }

  /**
   * This function shows the success toast
   * @param message the string to be displayed in the toast
   * @param onScreenSeconds optional - positive number of seconds to display on the screen (default is 0 - which will not dismiss until user's interaction to close the toast).
   * @param callback a callback function to handle events by user on the toast.
   */
  successWithCallback(message: string, onScreenSeconds: number = 0, callback: () => any) {
    alertify.success(message, onScreenSeconds, (e: Event) => {
      if (e) {
        callback();
      } else { }
    });
  }

  /**
   * This function shows the warning toast
   * @param message the string to be displayed in the toast
   * @param onScreenSeconds optional - positive number of seconds to display on the screen (default is 3 - which will auto dismiss after 3 seconds).
   */
  warning(message: string, onScreenSeconds: number = 3) {
    alertify.warning(message, onScreenSeconds);
  }

  /**
   * This function shows the warning toast
   * @param message the string to be displayed in the toast
   * @param onScreenSeconds optional - positive number of seconds to display on the screen (default is 0 - which will not dismiss until user's interaction to close the toast).
   * @param callback a callback function to handle events by user on the toast.
   */
  warningWithCallback(message: string, onScreenSeconds: number = 0, callback: () => any) {
    alertify.warning(message, onScreenSeconds, (e: Event) => {
      if (e) {
        callback();
      } else { }
    });
  }

  /**
   * a modal alert with user interaction (with 'Ok' button).
   * @param message the string to be displayed in the alert
   * @param okCallback a callback handler when user on 'Ok' button
   * @param title a title to be displayed for this alert
   * @param okLabel a custom label for button default is 'Ok'
   */
  alert(message: string,
    okCallback: () => any,
    title: string = 'Alert',
    okLabel: string = 'Ok'): void {
    alertify.alert(title, message, (e: Event) => {
      if (e) {
        okCallback();
      } else { }
    }).set({ closableByDimmer: false, label: okLabel });
  }

  /**
   * a modal alert with user interaction (with 'Ok' and 'Cancel' buttons).
   * @param message the string to be displayed in the alert
   * @param okCallback a callback handler when user on 'Ok' button
   * @param calcelCallback a callback handler when user on 'Cancel' button
   * @param title a title to be displayed for this alert
   * @param okLabel a custom label for button default is 'Ok'
   * @param cancelLabel a custom label for button default is 'Cancel'
   */
  confirm(message: string,
    okCallback: () => any,
    cancelCallback: () => any,
    title: string = 'Confirm',
    okLabel: string = 'Ok',
    cancelLabel: string = 'Cancel') {

    alertify.confirm(title, message, (e: Event) => {
      if (e) {
        okCallback();
      } else { }
    }, (e: Event) => {
      if (e) {
        cancelCallback();
      } else { }
    }).set({ closableByDimmer: false, labels: { ok: okLabel, cancel: cancelLabel } });

  }


  successBaner(message?: string) {
    if (message === undefined) {
      message = 'Saved successfully.';
    }

    let options:any = { 
      message: message, 
    };
    const initialState: ModalOptions = {
      initialState: options,
      backdrop: 'static',   
      class: 'modal-dialog-centered',   
   };

    this.modalRef = this.modalService.show(SuccessBanertemplate, initialState);
  }

  errorBaner(message: string, title: string = "Error Message") {
    if (message === undefined) {
      message = 'Error Occured while doing this activity';
    }

    let options:any = { 
      message: message, 
      title: title
    };
    const initialState: ModalOptions = {
      initialState: options,
      backdrop: 'static',
      class: 'modal-dialog-centered',        
   };
    this.modalRef = this.modalService.show(ErrorBanertemplate, initialState);
  }

  validationBaner(messageList: string[], Header: string = "Error Message") {
   let options:any = { 
       messageList: messageList, 
       MessageHeader: Header
     };
     const initialState: ModalOptions = {
       initialState: options,
       backdrop: 'static',
       class: 'modal-dialog-centered',        
    };
    this.modalRef = this.modalService.show(ValidationsBanertemplate,initialState);
     this.modalRef.content.closeBtnName ='Close';
  }


  ConfirmationBanner(message: any, CallBack: any, ButtonText: string = "Delete") {
    let options :any=  { message: message, ButtonText: ButtonText };
    const initialState: ModalOptions = {
      initialState:options,
      backdrop: 'static' ,
      class: 'modal-dialog-centered',      
    };
    this.modalRef = this.modalService.show(ConfirmationBanertemplate, initialState);
    this.modalRef.content.onClose.subscribe((result:any) => {
      CallBack(result);
      // if(result)
      // {
      //   CallBack(result);
      // }
     });
  
  }

  ExternalLinkConfirmationBanner(title: any,message: any, url: any, CallBack: any, ButtonText: string = "Delete") {
    let options :any=  { title: title, message: message, ButtonText: ButtonText };
    const initialState: ModalOptions = {
      initialState:options,
      backdrop: 'static' ,
      class: 'modal-dialog-centered',      
    };
    this.modalRef = this.modalService.show(ExternalRedirectionConfirmationBannertemplate, initialState);
    this.modalRef.content.onClose.subscribe((result:any) => {
      CallBack(result, url);
     });
  
  }

  changePasswordSuccessBanner(message?: string) {
    if (message === undefined) {
      message = 'Saved successfully.';
    }

    let options:any = { 
      message: message, 
    };
    const initialState: ModalOptions = {
      initialState: options,
      backdrop: 'static',   
      class: 'modal-dialog-centered',   
   };

    this.modalRef = this.modalService.show(ChangePasswordSuccessBanertemplate, initialState);
  }


}
