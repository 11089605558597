import { Component, Input, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
    selector: 'confirmation-banertemplate',
    template: `
  <div class="modelHeader">Confirmation Message</div>
      <div class="modal-body" ngPreserveWhitespaces>
            <p class="fs-13 pb-2">{{message}}</p>
            <div class="text-center" ngPreserveWhitespaces>
            
			  <button type="button" class="btn btn-primary" (click)="Confirm()" id="smi-confirm-btn">{{ButtonText}}</button>
              <button type="button" class="btn btn-primary" (click)="messageClose()">Cancel</button>
            </div>
      </div>
  
        `,
    styles: [
       
    ]
})
export class ConfirmationBanertemplate implements OnInit {

  message:any;
  ButtonText: any;
  result: Subject<boolean> = new Subject<boolean>();
  onClose: Subject<boolean>;
    constructor(private _bsModalRef: BsModalRef) {
    }
    ngOnInit(): void {
      this.onClose = new Subject();
    }
    messageClose()
    {
      this.onClose.next(false);
      this._bsModalRef.hide();
    }
    Confirm()
    {
    this.onClose.next(true);
      this._bsModalRef.hide();
    }
}

